/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { exportTournamentResults } from '../fn/export/export-tournament-results';
import { ExportTournamentResults$Params } from '../fn/export/export-tournament-results';
import { exportTournamentResults$Plain } from '../fn/export/export-tournament-results-plain';
import { ExportTournamentResults$Plain$Params } from '../fn/export/export-tournament-results-plain';
import { ResultsExportDto } from '../models/results-export-dto';

@Injectable({ providedIn: 'root' })
export class ExportServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `exportTournamentResults()` */
  static readonly ExportTournamentResultsPath = '/export/results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportTournamentResults$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportTournamentResults$Plain$Response(params?: ExportTournamentResults$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResultsExportDto>> {
    return exportTournamentResults$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportTournamentResults$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportTournamentResults$Plain(params?: ExportTournamentResults$Plain$Params, context?: HttpContext): Observable<ResultsExportDto> {
    return this.exportTournamentResults$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResultsExportDto>): ResultsExportDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportTournamentResults()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportTournamentResults$Response(params?: ExportTournamentResults$Params, context?: HttpContext): Observable<StrictHttpResponse<ResultsExportDto>> {
    return exportTournamentResults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportTournamentResults$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportTournamentResults(params?: ExportTournamentResults$Params, context?: HttpContext): Observable<ResultsExportDto> {
    return this.exportTournamentResults$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResultsExportDto>): ResultsExportDto => r.body)
    );
  }

}
