/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addTeamMatchPairing } from '../fn/pairing/add-team-match-pairing';
import { AddTeamMatchPairing$Params } from '../fn/pairing/add-team-match-pairing';
import { calculateTeamPairingResult } from '../fn/pairing/calculate-team-pairing-result';
import { CalculateTeamPairingResult$Params } from '../fn/pairing/calculate-team-pairing-result';
import { deletePairing } from '../fn/pairing/delete-pairing';
import { DeletePairing$Params } from '../fn/pairing/delete-pairing';
import { getIndividualMatchups } from '../fn/pairing/get-individual-matchups';
import { GetIndividualMatchups$Params } from '../fn/pairing/get-individual-matchups';
import { getIndividualMatchups$Plain } from '../fn/pairing/get-individual-matchups-plain';
import { GetIndividualMatchups$Plain$Params } from '../fn/pairing/get-individual-matchups-plain';
import { resetTeamMatchups } from '../fn/pairing/reset-team-matchups';
import { ResetTeamMatchups$Params } from '../fn/pairing/reset-team-matchups';
import { swapPairingPlayers } from '../fn/pairing/swap-pairing-players';
import { SwapPairingPlayers$Params } from '../fn/pairing/swap-pairing-players';
import { TeamMatchupDto } from '../models/team-matchup-dto';

@Injectable({ providedIn: 'root' })
export class PairingServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `swapPairingPlayers()` */
  static readonly SwapPairingPlayersPath = '/tournament/pairing/{id}/swap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `swapPairingPlayers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  swapPairingPlayers$Response(params: SwapPairingPlayers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return swapPairingPlayers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `swapPairingPlayers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  swapPairingPlayers(params: SwapPairingPlayers$Params, context?: HttpContext): Observable<void> {
    return this.swapPairingPlayers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deletePairing()` */
  static readonly DeletePairingPath = '/tournament/pairing/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePairing()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePairing$Response(params: DeletePairing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePairing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePairing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePairing(params: DeletePairing$Params, context?: HttpContext): Observable<void> {
    return this.deletePairing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addTeamMatchPairing()` */
  static readonly AddTeamMatchPairingPath = '/tournament/pairing/{id}/add-team-matchup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTeamMatchPairing()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTeamMatchPairing$Response(params: AddTeamMatchPairing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addTeamMatchPairing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTeamMatchPairing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTeamMatchPairing(params: AddTeamMatchPairing$Params, context?: HttpContext): Observable<void> {
    return this.addTeamMatchPairing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resetTeamMatchups()` */
  static readonly ResetTeamMatchupsPath = '/tournament/pairing/{id}/reset-team-matchups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetTeamMatchups()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetTeamMatchups$Response(params: ResetTeamMatchups$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetTeamMatchups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetTeamMatchups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetTeamMatchups(params: ResetTeamMatchups$Params, context?: HttpContext): Observable<void> {
    return this.resetTeamMatchups$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getIndividualMatchups()` */
  static readonly GetIndividualMatchupsPath = '/tournament/pairing/{id}/get-team-matchups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIndividualMatchups$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndividualMatchups$Plain$Response(params: GetIndividualMatchups$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TeamMatchupDto>>> {
    return getIndividualMatchups$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIndividualMatchups$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndividualMatchups$Plain(params: GetIndividualMatchups$Plain$Params, context?: HttpContext): Observable<Array<TeamMatchupDto>> {
    return this.getIndividualMatchups$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TeamMatchupDto>>): Array<TeamMatchupDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIndividualMatchups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndividualMatchups$Response(params: GetIndividualMatchups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TeamMatchupDto>>> {
    return getIndividualMatchups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIndividualMatchups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndividualMatchups(params: GetIndividualMatchups$Params, context?: HttpContext): Observable<Array<TeamMatchupDto>> {
    return this.getIndividualMatchups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TeamMatchupDto>>): Array<TeamMatchupDto> => r.body)
    );
  }

  /** Path part for operation `calculateTeamPairingResult()` */
  static readonly CalculateTeamPairingResultPath = '/tournament/pairing/{id}/calculate-team-result';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateTeamPairingResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateTeamPairingResult$Response(params: CalculateTeamPairingResult$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return calculateTeamPairingResult(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculateTeamPairingResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateTeamPairingResult(params: CalculateTeamPairingResult$Params, context?: HttpContext): Observable<void> {
    return this.calculateTeamPairingResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
