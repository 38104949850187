import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, finalize, Observable, tap } from 'rxjs';
import { AccountResponseDto, CreateAccountDto, UserAccountDto, UpdateAccountRequestDto, TournamentDto, UserRole } from 'src/app/api/models';
import { environment } from 'src/environments/environment';

const baseUrl = `${environment.apiUrl}/accounts`;

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    private accountSubject$: BehaviorSubject<UserAccountDto | null>;
    public account$: Observable<UserAccountDto | null>;
    private refreshTokenTimeout: any;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.accountSubject$ = new BehaviorSubject<UserAccountDto | null>(null);
        this.account$ = this.accountSubject$.asObservable();
    }

    public get accountValue(): UserAccountDto | null {
        if (!this.accountSubject$) {
            return null;
        } else {
            return this.accountSubject$.value;
        }
    }
    public get accountValueAsAccountResponse(): AccountResponseDto | null {
        if (!this.accountSubject$) {
            return null;
        } else {
            return this.accountSubject$.value;
        }
    }

    login(email: string, password: string) {
        return this.http.post<UserAccountDto>(`${baseUrl}/authenticate`, { email, password }, { withCredentials: true, observe: 'response' as 'response' })
            .pipe(tap(account => {
                this.accountSubject$.next(account.body);
                this.startRefreshTokenTimer();
            }));
    }

    logout() {
        this.http.post<any>(`${baseUrl}/logout`, {}, { withCredentials: true }).subscribe(() => {
            this.stopRefreshTokenTimer();
            this.accountSubject$.next(null);
            this.router.navigate(['/account/login']);
        });
    }

    refreshToken() {
        return this.http.post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
            .pipe(tap((account) => {
                this.accountSubject$.next(account);
                this.startRefreshTokenTimer();
            }));
    }

    register(account: CreateAccountDto) {
        return this.http.post(`${baseUrl}/register`, account);
    }

    verifyEmail(token: string) {
        return this.http.post(`${baseUrl}/verify-email/${token}`, { token });
    }

    forgotPassword(email: string) {
        return this.http.post(`${baseUrl}/forgot-password`, { email });
    }

    validateResetToken(token: string) {
        return this.http.post(`${baseUrl}/validate-reset-token`, { token });
    }

    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
    }

    getAll() {
        return this.http.get<AccountResponseDto[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<AccountResponseDto>(`${baseUrl}/${id}`);
    }

    create(params: CreateAccountDto) {
        return this.http.post(baseUrl, params);
    }

    update(id: string, params: UpdateAccountRequestDto) {
        return this.http.put<AccountResponseDto>(`${baseUrl}/${id}`, params)
            .pipe(tap((account: AccountResponseDto) => {
                // update the current account if it was updated
                if (account.accountId === (this.accountValue && this.accountValue.accountId)) {
                    // publish updated account to subscribers
                    const updated: UserAccountDto = { ...this.accountValue, ...account } as UserAccountDto;
                    this.accountSubject$.next(updated);
                }
                return account;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
                // auto logout if the logged in account was deleted
                if (id === (this.accountValue && this.accountValue.accountId)) {
                    this.logout();
                }
            }));
    }

    isTO(tournamentValue: TournamentDto): boolean {
        if (this.accountValue) {
            return tournamentValue.tournamentOrganiserAccountIds.indexOf(this.accountValue.accountId) >= 0;
        }
        return false;
    }

    isAdministrator(): boolean {
        if (this.accountValue) {
            return this.accountValue.role === UserRole.Administrator;
        }
        return false;
    }

    // helper methods


    private startRefreshTokenTimer() {
        if (this.accountValue) {
            // parse json object from base64 encoded jwt token
            const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));

            // set a timeout to refresh the token a minute before it expires
            const expires = new Date(jwtToken.exp * 1000);
            const timeout = expires.getTime() - Date.now() - (60 * 1000);
            this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
        }
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
