import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';

import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertPosition, AlertService } from '../services/alert.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private alertService: AlertService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof Error) {
                    // A client-side or network error occurred. Handle it accordingly.
                    console.error('An error occurred:', error.error.message);
                    this.alertService.error('Error', error.error.message, AlertPosition.Banner);
                    return EMPTY;
                } else {
                    // The backend returned an unsuccessful response code.
                    // The response body may contain clues as to what went wrong,
                    console.error('Backend response:', error);
                    const appException = this.handleBackendError(error);
                    return throwError(() => appException);
                }

                // If you want to return a new response:
                //return of(new HttpResponse({body: [{name: "Default value..."}]}));

                // If you want to return the error on the upper level:
                //return throwError(error);

                // or just return nothing:
            })
        );
    }

    private handleBackendError(err: any): ApplicationException {
        // Backend returned error, format it here
        var parsed = this.tryParse(err.error ? err.error : err);
        return {
            message: parsed.message ? parsed.message : parsed,
            inner: parsed.inner ? parsed.inner : '',
            stack: parsed.stack ? parsed.stack : ''
        };
    }

    private tryParse(json: string): any {
        try {
            const asObj = JSON.parse(json);
            return asObj;
        } catch (error) {
            return json;
        }
    }
}

export interface ApplicationException {
    message: string;
    inner: string;
    stack: string;
}