/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ArmyDto } from '../../models/army-dto';

export interface GetAllArmies$Params {
  gameTypeId?: string;
}

export function getAllArmies(http: HttpClient, rootUrl: string, params?: GetAllArmies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArmyDto>>> {
  const rb = new RequestBuilder(rootUrl, getAllArmies.PATH, 'get');
  if (params) {
    rb.query('gameTypeId', params.gameTypeId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ArmyDto>>;
    })
  );
}

getAllArmies.PATH = '/army';
