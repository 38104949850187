/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLeagueTournaments } from '../fn/league-tournament/get-league-tournaments';
import { GetLeagueTournaments$Params } from '../fn/league-tournament/get-league-tournaments';
import { getLeagueTournaments$Plain } from '../fn/league-tournament/get-league-tournaments-plain';
import { GetLeagueTournaments$Plain$Params } from '../fn/league-tournament/get-league-tournaments-plain';
import { getLeagueTournamentsSummary } from '../fn/league-tournament/get-league-tournaments-summary';
import { GetLeagueTournamentsSummary$Params } from '../fn/league-tournament/get-league-tournaments-summary';
import { getLeagueTournamentsSummary$Plain } from '../fn/league-tournament/get-league-tournaments-summary-plain';
import { GetLeagueTournamentsSummary$Plain$Params } from '../fn/league-tournament/get-league-tournaments-summary-plain';
import { getTournamentStats } from '../fn/league-tournament/get-tournament-stats';
import { GetTournamentStats$Params } from '../fn/league-tournament/get-tournament-stats';
import { getTournamentStats$Plain } from '../fn/league-tournament/get-tournament-stats-plain';
import { GetTournamentStats$Plain$Params } from '../fn/league-tournament/get-tournament-stats-plain';
import { LeagueTournamentDto } from '../models/league-tournament-dto';
import { LeagueTournamentStatsDto } from '../models/league-tournament-stats-dto';
import { TournamentSummaryDto } from '../models/tournament-summary-dto';
import { updateLeagueTournament } from '../fn/league-tournament/update-league-tournament';
import { UpdateLeagueTournament$Params } from '../fn/league-tournament/update-league-tournament';

@Injectable({ providedIn: 'root' })
export class LeagueTournamentServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getLeagueTournaments()` */
  static readonly GetLeagueTournamentsPath = '/league/{leagueId}/tournaments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeagueTournaments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueTournaments$Plain$Response(params: GetLeagueTournaments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LeagueTournamentDto>>> {
    return getLeagueTournaments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeagueTournaments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueTournaments$Plain(params: GetLeagueTournaments$Plain$Params, context?: HttpContext): Observable<Array<LeagueTournamentDto>> {
    return this.getLeagueTournaments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LeagueTournamentDto>>): Array<LeagueTournamentDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeagueTournaments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueTournaments$Response(params: GetLeagueTournaments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LeagueTournamentDto>>> {
    return getLeagueTournaments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeagueTournaments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueTournaments(params: GetLeagueTournaments$Params, context?: HttpContext): Observable<Array<LeagueTournamentDto>> {
    return this.getLeagueTournaments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LeagueTournamentDto>>): Array<LeagueTournamentDto> => r.body)
    );
  }

  /** Path part for operation `getLeagueTournamentsSummary()` */
  static readonly GetLeagueTournamentsSummaryPath = '/league/{leagueId}/tournaments/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeagueTournamentsSummary$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueTournamentsSummary$Plain$Response(params: GetLeagueTournamentsSummary$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TournamentSummaryDto>>> {
    return getLeagueTournamentsSummary$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeagueTournamentsSummary$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueTournamentsSummary$Plain(params: GetLeagueTournamentsSummary$Plain$Params, context?: HttpContext): Observable<Array<TournamentSummaryDto>> {
    return this.getLeagueTournamentsSummary$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TournamentSummaryDto>>): Array<TournamentSummaryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeagueTournamentsSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueTournamentsSummary$Response(params: GetLeagueTournamentsSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TournamentSummaryDto>>> {
    return getLeagueTournamentsSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeagueTournamentsSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueTournamentsSummary(params: GetLeagueTournamentsSummary$Params, context?: HttpContext): Observable<Array<TournamentSummaryDto>> {
    return this.getLeagueTournamentsSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TournamentSummaryDto>>): Array<TournamentSummaryDto> => r.body)
    );
  }

  /** Path part for operation `getTournamentStats()` */
  static readonly GetTournamentStatsPath = '/league/{leagueId}/tournaments/{leagueTournamentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentStats$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentStats$Plain$Response(params: GetTournamentStats$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueTournamentStatsDto>> {
    return getTournamentStats$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentStats$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentStats$Plain(params: GetTournamentStats$Plain$Params, context?: HttpContext): Observable<LeagueTournamentStatsDto> {
    return this.getTournamentStats$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueTournamentStatsDto>): LeagueTournamentStatsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentStats$Response(params: GetTournamentStats$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueTournamentStatsDto>> {
    return getTournamentStats(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentStats(params: GetTournamentStats$Params, context?: HttpContext): Observable<LeagueTournamentStatsDto> {
    return this.getTournamentStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueTournamentStatsDto>): LeagueTournamentStatsDto => r.body)
    );
  }

  /** Path part for operation `updateLeagueTournament()` */
  static readonly UpdateLeagueTournamentPath = '/league/{leagueId}/tournaments/{leagueTournamentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLeagueTournament()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLeagueTournament$Response(params: UpdateLeagueTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateLeagueTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLeagueTournament$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLeagueTournament(params: UpdateLeagueTournament$Params, context?: HttpContext): Observable<void> {
    return this.updateLeagueTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
