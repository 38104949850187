<div class="flex flex-column surface-ground">
    <!--  mx-2 stops horizontal scroll bar appearing -->
    <div *ngIf="(currentUser$ | async) as user">
        <app-navbar></app-navbar>
    </div>
    <p-messages key="banner" [enableService]="true"></p-messages>
    <p-toast key="toast" position="top-right"></p-toast>
    <p-confirmDialog header="Confirmation" icon="fa-solid fa-exclamation-triangle" [style]="{'max-width': '100vw', 'width': '600px', 'font-size': '1rem'}"></p-confirmDialog>
    <p-confirmDialog header="Confirmation" icon="fa-solid fa-exclamation-triangle" [style]="{'max-width': '100vw', 'width': '600px', 'font-size': '1rem'}" key="secondConfirmation"></p-confirmDialog>

    <!-- <app-breadcrumb></app-breadcrumb> -->
    <div class="flex flex-column flex-auto">
        <div class="surface-section flex-auto">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>