/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPaymentInfo } from '../fn/payment/get-payment-info';
import { GetPaymentInfo$Params } from '../fn/payment/get-payment-info';
import { getPaymentInfo$Plain } from '../fn/payment/get-payment-info-plain';
import { GetPaymentInfo$Plain$Params } from '../fn/payment/get-payment-info-plain';
import { PaymentInfoDto } from '../models/payment-info-dto';
import { PaymentResponseDto } from '../models/payment-response-dto';
import { submitPayment } from '../fn/payment/submit-payment';
import { SubmitPayment$Params } from '../fn/payment/submit-payment';
import { submitPayment$Plain } from '../fn/payment/submit-payment-plain';
import { SubmitPayment$Plain$Params } from '../fn/payment/submit-payment-plain';

@Injectable({ providedIn: 'root' })
export class PaymentServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPaymentInfo()` */
  static readonly GetPaymentInfoPath = '/payment/{invoiceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentInfo$Plain$Response(params: GetPaymentInfo$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentInfoDto>> {
    return getPaymentInfo$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentInfo$Plain(params: GetPaymentInfo$Plain$Params, context?: HttpContext): Observable<PaymentInfoDto> {
    return this.getPaymentInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentInfoDto>): PaymentInfoDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentInfo$Response(params: GetPaymentInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentInfoDto>> {
    return getPaymentInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaymentInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentInfo(params: GetPaymentInfo$Params, context?: HttpContext): Observable<PaymentInfoDto> {
    return this.getPaymentInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentInfoDto>): PaymentInfoDto => r.body)
    );
  }

  /** Path part for operation `submitPayment()` */
  static readonly SubmitPaymentPath = '/payment/{invoiceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitPayment$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitPayment$Plain$Response(params: SubmitPayment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentResponseDto>> {
    return submitPayment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitPayment$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitPayment$Plain(params: SubmitPayment$Plain$Params, context?: HttpContext): Observable<PaymentResponseDto> {
    return this.submitPayment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentResponseDto>): PaymentResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitPayment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitPayment$Response(params: SubmitPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentResponseDto>> {
    return submitPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitPayment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitPayment(params: SubmitPayment$Params, context?: HttpContext): Observable<PaymentResponseDto> {
    return this.submitPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentResponseDto>): PaymentResponseDto => r.body)
    );
  }

}
