/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface AddTeamMatchPairing$Params {
  id: string;
  player1RegistrationId?: string;
  player2RegistrationId?: string;
}

export function addTeamMatchPairing(http: HttpClient, rootUrl: string, params: AddTeamMatchPairing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, addTeamMatchPairing.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('player1RegistrationId', params.player1RegistrationId, {});
    rb.query('player2RegistrationId', params.player2RegistrationId, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

addTeamMatchPairing.PATH = '/tournament/pairing/{id}/add-team-matchup';
