/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getOpponents } from '../fn/registered-player/get-opponents';
import { GetOpponents$Params } from '../fn/registered-player/get-opponents';
import { getOpponents$Plain } from '../fn/registered-player/get-opponents-plain';
import { GetOpponents$Plain$Params } from '../fn/registered-player/get-opponents-plain';
import { invitePlayers } from '../fn/registered-player/invite-players';
import { InvitePlayers$Params } from '../fn/registered-player/invite-players';
import { invitePlayers$Plain } from '../fn/registered-player/invite-players-plain';
import { InvitePlayers$Plain$Params } from '../fn/registered-player/invite-players-plain';
import { InvitePlayersResponse } from '../models/invite-players-response';
import { registerPlayer } from '../fn/registered-player/register-player';
import { RegisterPlayer$Params } from '../fn/registered-player/register-player';
import { registerPlayer$Plain } from '../fn/registered-player/register-player-plain';
import { RegisterPlayer$Plain$Params } from '../fn/registered-player/register-player-plain';
import { RegistrationDto } from '../models/registration-dto';
import { removeFromTournament } from '../fn/registered-player/remove-from-tournament';
import { RemoveFromTournament$Params } from '../fn/registered-player/remove-from-tournament';
import { removeRegisteredPlayer } from '../fn/registered-player/remove-registered-player';
import { RemoveRegisteredPlayer$Params } from '../fn/registered-player/remove-registered-player';
import { setFavouriteArmies } from '../fn/registered-player/set-favourite-armies';
import { SetFavouriteArmies$Params } from '../fn/registered-player/set-favourite-armies';
import { setFavouriteOpponents } from '../fn/registered-player/set-favourite-opponents';
import { SetFavouriteOpponents$Params } from '../fn/registered-player/set-favourite-opponents';
import { toggleApproveListing } from '../fn/registered-player/toggle-approve-listing';
import { ToggleApproveListing$Params } from '../fn/registered-player/toggle-approve-listing';
import { toggleArmyShortlisted } from '../fn/registered-player/toggle-army-shortlisted';
import { ToggleArmyShortlisted$Params } from '../fn/registered-player/toggle-army-shortlisted';
import { updatePlayerArmyDetails } from '../fn/registered-player/update-player-army-details';
import { UpdatePlayerArmyDetails$Params } from '../fn/registered-player/update-player-army-details';
import { updatePlayerArmyDetails$Plain } from '../fn/registered-player/update-player-army-details-plain';
import { UpdatePlayerArmyDetails$Plain$Params } from '../fn/registered-player/update-player-army-details-plain';

@Injectable({ providedIn: 'root' })
export class RegisteredPlayerServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `registerPlayer()` */
  static readonly RegisterPlayerPath = '/registered-player/add';

  /**
   * Adds a player to a tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerPlayer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerPlayer$Plain$Response(params?: RegisterPlayer$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistrationDto>> {
    return registerPlayer$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds a player to a tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerPlayer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerPlayer$Plain(params?: RegisterPlayer$Plain$Params, context?: HttpContext): Observable<RegistrationDto> {
    return this.registerPlayer$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationDto>): RegistrationDto => r.body)
    );
  }

  /**
   * Adds a player to a tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerPlayer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerPlayer$Response(params?: RegisterPlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistrationDto>> {
    return registerPlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds a player to a tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerPlayer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerPlayer(params?: RegisterPlayer$Params, context?: HttpContext): Observable<RegistrationDto> {
    return this.registerPlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationDto>): RegistrationDto => r.body)
    );
  }

  /** Path part for operation `removeRegisteredPlayer()` */
  static readonly RemoveRegisteredPlayerPath = '/registered-player/remove';

  /**
   * Removes player from tournament PRIOR to it starting
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRegisteredPlayer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeRegisteredPlayer$Response(params?: RemoveRegisteredPlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeRegisteredPlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes player from tournament PRIOR to it starting
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeRegisteredPlayer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeRegisteredPlayer(params?: RemoveRegisteredPlayer$Params, context?: HttpContext): Observable<void> {
    return this.removeRegisteredPlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `invitePlayers()` */
  static readonly InvitePlayersPath = '/registered-player/invite';

  /**
   * Invites players to the tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invitePlayers$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  invitePlayers$Plain$Response(params?: InvitePlayers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InvitePlayersResponse>> {
    return invitePlayers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Invites players to the tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invitePlayers$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  invitePlayers$Plain(params?: InvitePlayers$Plain$Params, context?: HttpContext): Observable<InvitePlayersResponse> {
    return this.invitePlayers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvitePlayersResponse>): InvitePlayersResponse => r.body)
    );
  }

  /**
   * Invites players to the tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invitePlayers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  invitePlayers$Response(params?: InvitePlayers$Params, context?: HttpContext): Observable<StrictHttpResponse<InvitePlayersResponse>> {
    return invitePlayers(this.http, this.rootUrl, params, context);
  }

  /**
   * Invites players to the tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invitePlayers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  invitePlayers(params?: InvitePlayers$Params, context?: HttpContext): Observable<InvitePlayersResponse> {
    return this.invitePlayers$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvitePlayersResponse>): InvitePlayersResponse => r.body)
    );
  }

  /** Path part for operation `removeFromTournament()` */
  static readonly RemoveFromTournamentPath = '/registered-player/{registrationId}/remove';

  /**
   * Excludes the player from future rounds in this tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeFromTournament()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFromTournament$Response(params: RemoveFromTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeFromTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * Excludes the player from future rounds in this tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeFromTournament$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFromTournament(params: RemoveFromTournament$Params, context?: HttpContext): Observable<void> {
    return this.removeFromTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updatePlayerArmyDetails()` */
  static readonly UpdatePlayerArmyDetailsPath = '/registered-player/{registrationId}';

  /**
   * Updates the army details of the player for this tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlayerArmyDetails$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlayerArmyDetails$Plain$Response(params: UpdatePlayerArmyDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistrationDto>> {
    return updatePlayerArmyDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the army details of the player for this tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlayerArmyDetails$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlayerArmyDetails$Plain(params: UpdatePlayerArmyDetails$Plain$Params, context?: HttpContext): Observable<RegistrationDto> {
    return this.updatePlayerArmyDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationDto>): RegistrationDto => r.body)
    );
  }

  /**
   * Updates the army details of the player for this tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlayerArmyDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlayerArmyDetails$Response(params: UpdatePlayerArmyDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistrationDto>> {
    return updatePlayerArmyDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the army details of the player for this tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlayerArmyDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlayerArmyDetails(params: UpdatePlayerArmyDetails$Params, context?: HttpContext): Observable<RegistrationDto> {
    return this.updatePlayerArmyDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationDto>): RegistrationDto => r.body)
    );
  }

  /** Path part for operation `toggleApproveListing()` */
  static readonly ToggleApproveListingPath = '/registered-player/{registrationId}/toggle-approve-listing';

  /**
   * Toggles whether the listing is approved or not
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleApproveListing()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleApproveListing$Response(params: ToggleApproveListing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return toggleApproveListing(this.http, this.rootUrl, params, context);
  }

  /**
   * Toggles whether the listing is approved or not
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleApproveListing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleApproveListing(params: ToggleApproveListing$Params, context?: HttpContext): Observable<void> {
    return this.toggleApproveListing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `toggleArmyShortlisted()` */
  static readonly ToggleArmyShortlistedPath = '/registered-player/{registrationId}/toggle-army-shortlisted';

  /**
   * Toggles whether the players army has been shortlisted as a best army
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleArmyShortlisted()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleArmyShortlisted$Response(params: ToggleArmyShortlisted$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return toggleArmyShortlisted(this.http, this.rootUrl, params, context);
  }

  /**
   * Toggles whether the players army has been shortlisted as a best army
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleArmyShortlisted$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleArmyShortlisted(params: ToggleArmyShortlisted$Params, context?: HttpContext): Observable<void> {
    return this.toggleArmyShortlisted$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getOpponents()` */
  static readonly GetOpponentsPath = '/registered-player/{registrationId}/get-opponents';

  /**
   * Get the opponents that this player has faced
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpponents$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpponents$Plain$Response(params: GetOpponents$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistrationDto>>> {
    return getOpponents$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the opponents that this player has faced
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpponents$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpponents$Plain(params: GetOpponents$Plain$Params, context?: HttpContext): Observable<Array<RegistrationDto>> {
    return this.getOpponents$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationDto>>): Array<RegistrationDto> => r.body)
    );
  }

  /**
   * Get the opponents that this player has faced
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpponents$Response(params: GetOpponents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistrationDto>>> {
    return getOpponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the opponents that this player has faced
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpponents(params: GetOpponents$Params, context?: HttpContext): Observable<Array<RegistrationDto>> {
    return this.getOpponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationDto>>): Array<RegistrationDto> => r.body)
    );
  }

  /** Path part for operation `setFavouriteOpponents()` */
  static readonly SetFavouriteOpponentsPath = '/registered-player/{registrationId}/favourite-opponents';

  /**
   * Set the favourite opponents for this player
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setFavouriteOpponents()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setFavouriteOpponents$Response(params: SetFavouriteOpponents$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setFavouriteOpponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Set the favourite opponents for this player
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setFavouriteOpponents$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setFavouriteOpponents(params: SetFavouriteOpponents$Params, context?: HttpContext): Observable<void> {
    return this.setFavouriteOpponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `setFavouriteArmies()` */
  static readonly SetFavouriteArmiesPath = '/registered-player/{registrationId}/favourite-army';

  /**
   * Set the favourite armies for this player
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setFavouriteArmies()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setFavouriteArmies$Response(params: SetFavouriteArmies$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setFavouriteArmies(this.http, this.rootUrl, params, context);
  }

  /**
   * Set the favourite armies for this player
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setFavouriteArmies$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setFavouriteArmies(params: SetFavouriteArmies$Params, context?: HttpContext): Observable<void> {
    return this.setFavouriteArmies$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
