/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTournament } from '../fn/tournament/create-tournament';
import { CreateTournament$Params } from '../fn/tournament/create-tournament';
import { createTournament$Plain } from '../fn/tournament/create-tournament-plain';
import { CreateTournament$Plain$Params } from '../fn/tournament/create-tournament-plain';
import { CreateTournamentResponseDto } from '../models/create-tournament-response-dto';
import { deleteTournament } from '../fn/tournament/delete-tournament';
import { DeleteTournament$Params } from '../fn/tournament/delete-tournament';
import { duplicateTournament } from '../fn/tournament/duplicate-tournament';
import { DuplicateTournament$Params } from '../fn/tournament/duplicate-tournament';
import { duplicateTournament$Plain } from '../fn/tournament/duplicate-tournament-plain';
import { DuplicateTournament$Plain$Params } from '../fn/tournament/duplicate-tournament-plain';
import { getAllPlayersAndTeams } from '../fn/tournament/get-all-players-and-teams';
import { GetAllPlayersAndTeams$Params } from '../fn/tournament/get-all-players-and-teams';
import { getAllPlayersAndTeams$Plain } from '../fn/tournament/get-all-players-and-teams-plain';
import { GetAllPlayersAndTeams$Plain$Params } from '../fn/tournament/get-all-players-and-teams-plain';
import { getAllTournaments } from '../fn/tournament/get-all-tournaments';
import { GetAllTournaments$Params } from '../fn/tournament/get-all-tournaments';
import { getAllTournaments$Plain } from '../fn/tournament/get-all-tournaments-plain';
import { GetAllTournaments$Plain$Params } from '../fn/tournament/get-all-tournaments-plain';
import { getPlayers } from '../fn/tournament/get-players';
import { GetPlayers$Params } from '../fn/tournament/get-players';
import { getPlayers$Plain } from '../fn/tournament/get-players-plain';
import { GetPlayers$Plain$Params } from '../fn/tournament/get-players-plain';
import { getTournament } from '../fn/tournament/get-tournament';
import { GetTournament$Params } from '../fn/tournament/get-tournament';
import { getTournament$Plain } from '../fn/tournament/get-tournament-plain';
import { GetTournament$Plain$Params } from '../fn/tournament/get-tournament-plain';
import { publishResults } from '../fn/tournament/publish-results';
import { PublishResults$Params } from '../fn/tournament/publish-results';
import { publishResults$Plain } from '../fn/tournament/publish-results-plain';
import { PublishResults$Plain$Params } from '../fn/tournament/publish-results-plain';
import { RegistrationDto } from '../models/registration-dto';
import { resetsTournament } from '../fn/tournament/resets-tournament';
import { ResetsTournament$Params } from '../fn/tournament/resets-tournament';
import { resetsTournament$Plain } from '../fn/tournament/resets-tournament-plain';
import { ResetsTournament$Plain$Params } from '../fn/tournament/resets-tournament-plain';
import { submitToLeague } from '../fn/tournament/submit-to-league';
import { SubmitToLeague$Params } from '../fn/tournament/submit-to-league';
import { submitToLeague$Plain } from '../fn/tournament/submit-to-league-plain';
import { SubmitToLeague$Plain$Params } from '../fn/tournament/submit-to-league-plain';
import { TournamentDto } from '../models/tournament-dto';
import { TournamentListItemDto } from '../models/tournament-list-item-dto';
import { updateTournament } from '../fn/tournament/update-tournament';
import { UpdateTournament$Params } from '../fn/tournament/update-tournament';
import { updateTournament$Plain } from '../fn/tournament/update-tournament-plain';
import { UpdateTournament$Plain$Params } from '../fn/tournament/update-tournament-plain';
import { AddTournamentToLeague$Params, addTournamentToLeague } from '../fn/league-entry-request/add-tournament-to-league'

@Injectable({ providedIn: 'root' })
export class TournamentServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllTournaments()` */
  static readonly GetAllTournamentsPath = '/tournament';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTournaments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTournaments$Plain$Response(params?: GetAllTournaments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TournamentListItemDto>>> {
    return getAllTournaments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTournaments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTournaments$Plain(params?: GetAllTournaments$Plain$Params, context?: HttpContext): Observable<Array<TournamentListItemDto>> {
    return this.getAllTournaments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TournamentListItemDto>>): Array<TournamentListItemDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTournaments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTournaments$Response(params?: GetAllTournaments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TournamentListItemDto>>> {
    return getAllTournaments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTournaments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTournaments(params?: GetAllTournaments$Params, context?: HttpContext): Observable<Array<TournamentListItemDto>> {
    return this.getAllTournaments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TournamentListItemDto>>): Array<TournamentListItemDto> => r.body)
    );
  }

  /** Path part for operation `createTournament()` */
  static readonly CreateTournamentPath = '/tournament';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTournament$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTournament$Plain$Response(params?: CreateTournament$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTournamentResponseDto>> {
    return createTournament$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTournament$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTournament$Plain(params?: CreateTournament$Plain$Params, context?: HttpContext): Observable<CreateTournamentResponseDto> {
    return this.createTournament$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTournamentResponseDto>): CreateTournamentResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTournament()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTournament$Response(params?: CreateTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTournamentResponseDto>> {
    return createTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTournament$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTournament(params?: CreateTournament$Params, context?: HttpContext): Observable<CreateTournamentResponseDto> {
    return this.createTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTournamentResponseDto>): CreateTournamentResponseDto => r.body)
    );
  }

  /** Path part for operation `getTournament()` */
  static readonly GetTournamentPath = '/tournament/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournament$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournament$Plain$Response(params: GetTournament$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return getTournament$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournament$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournament$Plain(params: GetTournament$Plain$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.getTournament$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournament()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournament$Response(params: GetTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return getTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournament$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournament(params: GetTournament$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.getTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /** Path part for operation `updateTournament()` */
  static readonly UpdateTournamentPath = '/tournament/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTournament$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTournament$Plain$Response(params: UpdateTournament$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return updateTournament$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTournament$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTournament$Plain(params: UpdateTournament$Plain$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.updateTournament$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTournament()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTournament$Response(params: UpdateTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return updateTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTournament$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTournament(params: UpdateTournament$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.updateTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /** Path part for operation `deleteTournament()` */
  static readonly DeleteTournamentPath = '/tournament/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTournament()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTournament$Response(params: DeleteTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTournament$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTournament(params: DeleteTournament$Params, context?: HttpContext): Observable<void> {
    return this.deleteTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getPlayers()` */
  static readonly GetPlayersPath = '/tournament/{id}/players';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlayers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayers$Plain$Response(params: GetPlayers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistrationDto>>> {
    return getPlayers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlayers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayers$Plain(params: GetPlayers$Plain$Params, context?: HttpContext): Observable<Array<RegistrationDto>> {
    return this.getPlayers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationDto>>): Array<RegistrationDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlayers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayers$Response(params: GetPlayers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistrationDto>>> {
    return getPlayers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlayers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayers(params: GetPlayers$Params, context?: HttpContext): Observable<Array<RegistrationDto>> {
    return this.getPlayers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationDto>>): Array<RegistrationDto> => r.body)
    );
  }

  /** Path part for operation `getAllPlayersAndTeams()` */
  static readonly GetAllPlayersAndTeamsPath = '/tournament/{id}/players-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPlayersAndTeams$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlayersAndTeams$Plain$Response(params: GetAllPlayersAndTeams$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistrationDto>>> {
    return getAllPlayersAndTeams$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPlayersAndTeams$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlayersAndTeams$Plain(params: GetAllPlayersAndTeams$Plain$Params, context?: HttpContext): Observable<Array<RegistrationDto>> {
    return this.getAllPlayersAndTeams$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationDto>>): Array<RegistrationDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPlayersAndTeams()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlayersAndTeams$Response(params: GetAllPlayersAndTeams$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistrationDto>>> {
    return getAllPlayersAndTeams(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPlayersAndTeams$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlayersAndTeams(params: GetAllPlayersAndTeams$Params, context?: HttpContext): Observable<Array<RegistrationDto>> {
    return this.getAllPlayersAndTeams$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationDto>>): Array<RegistrationDto> => r.body)
    );
  }

  /** Path part for operation `publishResults()` */
  static readonly PublishResultsPath = '/tournament/{id}/publish-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishResults$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishResults$Plain$Response(params: PublishResults$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return publishResults$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishResults$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishResults$Plain(params: PublishResults$Plain$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.publishResults$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishResults$Response(params: PublishResults$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return publishResults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishResults(params: PublishResults$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.publishResults$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /** Path part for operation `submitToLeague()` */
  static readonly SubmitToLeaguePath = '/tournament/{id}/submit-to-league';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitToLeague$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitToLeague$Plain$Response(params: SubmitToLeague$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return submitToLeague$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitToLeague$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitToLeague$Plain(params: SubmitToLeague$Plain$Params, context?: HttpContext): Observable<string> {
    return this.submitToLeague$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitToLeague()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitToLeague$Response(params: SubmitToLeague$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return submitToLeague(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitToLeague$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitToLeague(params: SubmitToLeague$Params, context?: HttpContext): Observable<string> {
    return this.submitToLeague$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  addTournamentToLeague$Response(params: AddTournamentToLeague$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return addTournamentToLeague(this.http, this.rootUrl, params, context);
  }

  addTournamentToLeague(params: AddTournamentToLeague$Params, context?: HttpContext): Observable<string> {
    return this.addTournamentToLeague$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `resetsTournament()` */
  static readonly ResetsTournamentPath = '/tournament/{id}/reset-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetsTournament$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetsTournament$Plain$Response(params: ResetsTournament$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return resetsTournament$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetsTournament$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetsTournament$Plain(params: ResetsTournament$Plain$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.resetsTournament$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetsTournament()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetsTournament$Response(params: ResetsTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return resetsTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetsTournament$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetsTournament(params: ResetsTournament$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.resetsTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /** Path part for operation `duplicateTournament()` */
  static readonly DuplicateTournamentPath = '/tournament/duplicate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateTournament$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  duplicateTournament$Plain$Response(params?: DuplicateTournament$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return duplicateTournament$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `duplicateTournament$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  duplicateTournament$Plain(params?: DuplicateTournament$Plain$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.duplicateTournament$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateTournament()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  duplicateTournament$Response(params?: DuplicateTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentDto>> {
    return duplicateTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `duplicateTournament$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  duplicateTournament(params?: DuplicateTournament$Params, context?: HttpContext): Observable<TournamentDto> {
    return this.duplicateTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentDto>): TournamentDto => r.body)
    );
  }

}
