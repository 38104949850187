/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ImportProcessingDto } from '../../models/import-processing-dto';
import { ImportResultDto } from '../../models/import-result-dto';

export interface ProcessExternalTournamentResults$Params {
  leagueId: string;
      body?: Array<ImportResultDto>
}

export function processExternalTournamentResults(http: HttpClient, rootUrl: string, params: ProcessExternalTournamentResults$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ImportProcessingDto>>> {
  const rb = new RequestBuilder(rootUrl, processExternalTournamentResults.PATH, 'post');
  if (params) {
    rb.path('leagueId', params.leagueId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ImportProcessingDto>>;
    })
  );
}

processExternalTournamentResults.PATH = '/external-tournament/process/{leagueId}';
