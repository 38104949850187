/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addFairplayScore } from '../fn/touranment-fairplay-score/add-fairplay-score';
import { AddFairplayScore$Params } from '../fn/touranment-fairplay-score/add-fairplay-score';
import { addFairplayScore$Plain } from '../fn/touranment-fairplay-score/add-fairplay-score-plain';
import { AddFairplayScore$Plain$Params } from '../fn/touranment-fairplay-score/add-fairplay-score-plain';
import { FairplayScoreItemDto } from '../models/fairplay-score-item-dto';
import { removeFairplayScore } from '../fn/touranment-fairplay-score/remove-fairplay-score';
import { RemoveFairplayScore$Params } from '../fn/touranment-fairplay-score/remove-fairplay-score';
import { updateFairplayScore } from '../fn/touranment-fairplay-score/update-fairplay-score';
import { UpdateFairplayScore$Params } from '../fn/touranment-fairplay-score/update-fairplay-score';
import { updateFairplayScore$Plain } from '../fn/touranment-fairplay-score/update-fairplay-score-plain';
import { UpdateFairplayScore$Plain$Params } from '../fn/touranment-fairplay-score/update-fairplay-score-plain';

@Injectable({ providedIn: 'root' })
export class TouranmentFairplayScoreServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `addFairplayScore()` */
  static readonly AddFairplayScorePath = '/tournament/{id}/fairplay';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFairplayScore$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFairplayScore$Plain$Response(params: AddFairplayScore$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FairplayScoreItemDto>> {
    return addFairplayScore$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFairplayScore$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFairplayScore$Plain(params: AddFairplayScore$Plain$Params, context?: HttpContext): Observable<FairplayScoreItemDto> {
    return this.addFairplayScore$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FairplayScoreItemDto>): FairplayScoreItemDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFairplayScore()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFairplayScore$Response(params: AddFairplayScore$Params, context?: HttpContext): Observable<StrictHttpResponse<FairplayScoreItemDto>> {
    return addFairplayScore(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFairplayScore$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFairplayScore(params: AddFairplayScore$Params, context?: HttpContext): Observable<FairplayScoreItemDto> {
    return this.addFairplayScore$Response(params, context).pipe(
      map((r: StrictHttpResponse<FairplayScoreItemDto>): FairplayScoreItemDto => r.body)
    );
  }

  /** Path part for operation `updateFairplayScore()` */
  static readonly UpdateFairplayScorePath = '/tournament/{id}/fairplay/{scoreId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFairplayScore$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFairplayScore$Plain$Response(params: UpdateFairplayScore$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FairplayScoreItemDto>> {
    return updateFairplayScore$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFairplayScore$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFairplayScore$Plain(params: UpdateFairplayScore$Plain$Params, context?: HttpContext): Observable<FairplayScoreItemDto> {
    return this.updateFairplayScore$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FairplayScoreItemDto>): FairplayScoreItemDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFairplayScore()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFairplayScore$Response(params: UpdateFairplayScore$Params, context?: HttpContext): Observable<StrictHttpResponse<FairplayScoreItemDto>> {
    return updateFairplayScore(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFairplayScore$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFairplayScore(params: UpdateFairplayScore$Params, context?: HttpContext): Observable<FairplayScoreItemDto> {
    return this.updateFairplayScore$Response(params, context).pipe(
      map((r: StrictHttpResponse<FairplayScoreItemDto>): FairplayScoreItemDto => r.body)
    );
  }

  /** Path part for operation `removeFairplayScore()` */
  static readonly RemoveFairplayScorePath = '/tournament/{id}/fairplay/{scoreId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeFairplayScore()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFairplayScore$Response(params: RemoveFairplayScore$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeFairplayScore(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeFairplayScore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFairplayScore(params: RemoveFairplayScore$Params, context?: HttpContext): Observable<void> {
    return this.removeFairplayScore$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
