/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RegistrationDto } from '../../models/registration-dto';
import { UpdatePlayerRegistartionRequest } from '../../models/update-player-registartion-request';

export interface UpdatePlayerArmyDetails$Plain$Params {
  registrationId: string;
      body?: UpdatePlayerRegistartionRequest
}

export function updatePlayerArmyDetails$Plain(http: HttpClient, rootUrl: string, params: UpdatePlayerArmyDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistrationDto>> {
  const rb = new RequestBuilder(rootUrl, updatePlayerArmyDetails$Plain.PATH, 'put');
  if (params) {
    rb.path('registrationId', params.registrationId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RegistrationDto>;
    })
  );
}

updatePlayerArmyDetails$Plain.PATH = '/registered-player/{registrationId}';
