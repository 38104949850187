import { AccountTournamentDto, TournamentListItemDto, UserRole } from 'src/app/api/models';
import { AccountService } from 'src/app/modules/core/services/account.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingService {
  isAdmin = false;
  canExport = false;

  constructor(private router: Router, private accountService: AccountService) {
    this.accountService.account$.subscribe({
      next: (account) => {
        this.isAdmin = account?.role === UserRole.Administrator;
        this.canExport = account?.exportResults ?? false;
      }
    })
  }

  /**
   * Determines which view of tournament we display based on the user type and the tournament state
   * @param tournament 
   */
  showTournament(tournament: AccountTournamentDto | TournamentListItemDto) {
    let summaryOnly = true;
    if (tournament.publishLists || tournament.isTournamentOrganiser || this.isAdmin) summaryOnly = false;
    if (dayjs(tournament.startDate).isBefore(dayjs())) summaryOnly = false; // handle case for old tournaments before publishLists was added

    if (summaryOnly) {
      this.router.navigate(['player', 'tournaments', tournament.tournamentId]);
    } else {
      this.router.navigate(['tournament', 'show', tournament.tournamentId]);
    }
  }

  editTournament(tournamentId: string) {
    this.router.navigate(['organiser', 'edit-tournament', tournamentId]);
  }
  newTournament() {
    this.router.navigate(['organiser', 'new-tournament']);
  }
  findTournament() {
    this.router.navigate(['player', 'tournaments']);
  }
  userTournamentList() {
    if (this.isAdmin) {
      this.router.navigate(['admin', 'tournaments']);
    } else {
      this.router.navigate(['organiser', 'my-tournaments']);
    }
  }

  login(returnUrl: string = '') {
    if (returnUrl) {
      this.router.navigate(['/account/login'], { queryParams: { returnUrl } });
    } else {
      this.router.navigate(['/account/login']);
    }

  }

  profile() {
    this.router.navigate(['player', 'profile']);
  }

  editLeague(leagueId: string) {
    this.router.navigate(['league', 'edit-league', leagueId]);
  }
  editExternalTournament(leagueId: string, externalTournamentId: string) {
    this.router.navigate(['league', 'edit-league', leagueId, 'ext', externalTournamentId]);
  }
  userLeagueList() {
    if (this.isAdmin) {
      this.router.navigate(['admin', 'leagues']);
    } else {
      this.router.navigate(['league', 'my-leagues']);
    }
  }
  showLeagueHomePage(leagueId: string) {
    this.router.navigate(['league', 'show', leagueId]);
  }
  showLeaguePlayerStats(leagueId: string, leagueRegistrationId: string) {
    this.router.navigate(['league', 'show', leagueId, 'player', leagueRegistrationId]);
  }
  showLeagueTournamentStats(leagueId: string, leagueTournamentId: string) {
    this.router.navigate(['league', 'show', leagueId, 'tournament', leagueTournamentId]);
  }
  leagueHome() {
    this.router.navigate(['league', 'home']);
  }

  fullTournamentNewTab(tournamentId: string) {
    // Converts the route into a string that can be used 
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tournament', 'show', tournamentId])
    );

    window.open(url, '_blank');
  }
}
