import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

export enum AlertPosition {
  Toast,
  Banner
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private messageService: MessageService) { }

  success(title: string, detail: string, position: AlertPosition = AlertPosition.Toast) {
    if (position === AlertPosition.Toast) {
      this.messageService.add({ severity: 'success', summary: title, detail, key: 'toast', life: 2500  });
    } else {
      this.messageService.add({ severity: 'success', summary: title, detail, key: 'banner'});
    }
  }

  error(title: string, detail: string, position: AlertPosition = AlertPosition.Toast) {
    if (position === AlertPosition.Toast) {
      this.messageService.add({ severity: 'error', summary: title, detail, key: 'toast', life: 2500 });
    } else {
      this.messageService.add({ severity: 'error', summary: title, detail, key: 'banner' });
    }
  }

  info(title: string, detail: string, position: AlertPosition = AlertPosition.Toast) {
    if (position === AlertPosition.Toast) {
      this.messageService.add({ severity: 'info', summary: title, detail, key: 'toast', life: 2500 });
    } else {
      this.messageService.add({ severity: 'info', summary: title, detail, key: 'banner' });
    }
  }
}
