/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountTournamentDto } from '../models/account-tournament-dto';
import { CurrentMatchDto } from '../models/current-match-dto';
import { getCurrentMatch } from '../fn/user/get-current-match';
import { GetCurrentMatch$Params } from '../fn/user/get-current-match';
import { getCurrentMatch$Plain } from '../fn/user/get-current-match-plain';
import { GetCurrentMatch$Plain$Params } from '../fn/user/get-current-match-plain';
import { getCurrentStandings } from '../fn/user/get-current-standings';
import { GetCurrentStandings$Params } from '../fn/user/get-current-standings';
import { getCurrentStandings$Plain } from '../fn/user/get-current-standings-plain';
import { GetCurrentStandings$Plain$Params } from '../fn/user/get-current-standings-plain';
import { getOrganisersTournaments } from '../fn/user/get-organisers-tournaments';
import { GetOrganisersTournaments$Params } from '../fn/user/get-organisers-tournaments';
import { getOrganisersTournaments$Plain } from '../fn/user/get-organisers-tournaments-plain';
import { GetOrganisersTournaments$Plain$Params } from '../fn/user/get-organisers-tournaments-plain';
import { getPlayerTournaments } from '../fn/user/get-player-tournaments';
import { GetPlayerTournaments$Params } from '../fn/user/get-player-tournaments';
import { getPlayerTournaments$Plain } from '../fn/user/get-player-tournaments-plain';
import { GetPlayerTournaments$Plain$Params } from '../fn/user/get-player-tournaments-plain';
import { getProfile } from '../fn/user/get-profile';
import { GetProfile$Params } from '../fn/user/get-profile';
import { getProfile$Plain } from '../fn/user/get-profile-plain';
import { GetProfile$Plain$Params } from '../fn/user/get-profile-plain';
import { TournamentListItemDto } from '../models/tournament-list-item-dto';
import { TournamentStandingsDto } from '../models/tournament-standings-dto';
import { updateProfile } from '../fn/user/update-profile';
import { UpdateProfile$Params } from '../fn/user/update-profile';
import { UserPlayerInfoDto } from '../models/user-player-info-dto';

@Injectable({ providedIn: 'root' })
export class UserServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlayerTournaments()` */
  static readonly GetPlayerTournamentsPath = '/user/tournaments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlayerTournaments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerTournaments$Plain$Response(params?: GetPlayerTournaments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountTournamentDto>>> {
    return getPlayerTournaments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlayerTournaments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerTournaments$Plain(params?: GetPlayerTournaments$Plain$Params, context?: HttpContext): Observable<Array<AccountTournamentDto>> {
    return this.getPlayerTournaments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountTournamentDto>>): Array<AccountTournamentDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlayerTournaments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerTournaments$Response(params?: GetPlayerTournaments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountTournamentDto>>> {
    return getPlayerTournaments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlayerTournaments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerTournaments(params?: GetPlayerTournaments$Params, context?: HttpContext): Observable<Array<AccountTournamentDto>> {
    return this.getPlayerTournaments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountTournamentDto>>): Array<AccountTournamentDto> => r.body)
    );
  }

  /** Path part for operation `getOrganisersTournaments()` */
  static readonly GetOrganisersTournamentsPath = '/user/my-tournaments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganisersTournaments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganisersTournaments$Plain$Response(params?: GetOrganisersTournaments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TournamentListItemDto>>> {
    return getOrganisersTournaments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganisersTournaments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganisersTournaments$Plain(params?: GetOrganisersTournaments$Plain$Params, context?: HttpContext): Observable<Array<TournamentListItemDto>> {
    return this.getOrganisersTournaments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TournamentListItemDto>>): Array<TournamentListItemDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganisersTournaments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganisersTournaments$Response(params?: GetOrganisersTournaments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TournamentListItemDto>>> {
    return getOrganisersTournaments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganisersTournaments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganisersTournaments(params?: GetOrganisersTournaments$Params, context?: HttpContext): Observable<Array<TournamentListItemDto>> {
    return this.getOrganisersTournaments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TournamentListItemDto>>): Array<TournamentListItemDto> => r.body)
    );
  }

  /** Path part for operation `getCurrentMatch()` */
  static readonly GetCurrentMatchPath = '/user/tournament/{tournamentId}/currentmatch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentMatch$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentMatch$Plain$Response(params: GetCurrentMatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrentMatchDto>> {
    return getCurrentMatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentMatch$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentMatch$Plain(params: GetCurrentMatch$Plain$Params, context?: HttpContext): Observable<CurrentMatchDto> {
    return this.getCurrentMatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentMatchDto>): CurrentMatchDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentMatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentMatch$Response(params: GetCurrentMatch$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrentMatchDto>> {
    return getCurrentMatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentMatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentMatch(params: GetCurrentMatch$Params, context?: HttpContext): Observable<CurrentMatchDto> {
    return this.getCurrentMatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentMatchDto>): CurrentMatchDto => r.body)
    );
  }

  /** Path part for operation `getCurrentStandings()` */
  static readonly GetCurrentStandingsPath = '/user/tournament/{tournamentId}/standings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentStandings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentStandings$Plain$Response(params: GetCurrentStandings$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentStandingsDto>> {
    return getCurrentStandings$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentStandings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentStandings$Plain(params: GetCurrentStandings$Plain$Params, context?: HttpContext): Observable<TournamentStandingsDto> {
    return this.getCurrentStandings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentStandingsDto>): TournamentStandingsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentStandings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentStandings$Response(params: GetCurrentStandings$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentStandingsDto>> {
    return getCurrentStandings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentStandings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentStandings(params: GetCurrentStandings$Params, context?: HttpContext): Observable<TournamentStandingsDto> {
    return this.getCurrentStandings$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentStandingsDto>): TournamentStandingsDto => r.body)
    );
  }

  /** Path part for operation `getProfile()` */
  static readonly GetProfilePath = '/user/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile$Plain$Response(params?: GetProfile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPlayerInfoDto>> {
    return getProfile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile$Plain(params?: GetProfile$Plain$Params, context?: HttpContext): Observable<UserPlayerInfoDto> {
    return this.getProfile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPlayerInfoDto>): UserPlayerInfoDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile$Response(params?: GetProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPlayerInfoDto>> {
    return getProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile(params?: GetProfile$Params, context?: HttpContext): Observable<UserPlayerInfoDto> {
    return this.getProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserPlayerInfoDto>): UserPlayerInfoDto => r.body)
    );
  }

  /** Path part for operation `updateProfile()` */
  static readonly UpdateProfilePath = '/user/profile/{id}';

  /**
   * Updates the current user's profile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateProfile$Response(params: UpdateProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the current user's profile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateProfile(params: UpdateProfile$Params, context?: HttpContext): Observable<void> {
    return this.updateProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
