/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PaymentInfoDto } from '../../models/payment-info-dto';

export interface GetPaymentInfo$Plain$Params {
  invoiceId: string;
}

export function getPaymentInfo$Plain(http: HttpClient, rootUrl: string, params: GetPaymentInfo$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentInfoDto>> {
  const rb = new RequestBuilder(rootUrl, getPaymentInfo$Plain.PATH, 'get');
  if (params) {
    rb.path('invoiceId', params.invoiceId, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PaymentInfoDto>;
    })
  );
}

getPaymentInfo$Plain.PATH = '/payment/{invoiceId}';
