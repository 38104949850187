/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLeagueLadder } from '../fn/league-ladder/get-league-ladder';
import { GetLeagueLadder$Params } from '../fn/league-ladder/get-league-ladder';
import { getLeagueLadder$Plain } from '../fn/league-ladder/get-league-ladder-plain';
import { GetLeagueLadder$Plain$Params } from '../fn/league-ladder/get-league-ladder-plain';
import { PlayerRankingDto } from '../models/player-ranking-dto';
import { regenerateResults } from '../fn/league-ladder/regenerate-results';
import { RegenerateResults$Params } from '../fn/league-ladder/regenerate-results';

@Injectable({ providedIn: 'root' })
export class LeagueLadderServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `regenerateResults()` */
  static readonly RegenerateResultsPath = '/league/{leagueId}/ladder/{leagueTournamentId}/regenerate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `regenerateResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateResults$Response(params: RegenerateResults$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return regenerateResults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `regenerateResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateResults(params: RegenerateResults$Params, context?: HttpContext): Observable<void> {
    return this.regenerateResults$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getLeagueLadder()` */
  static readonly GetLeagueLadderPath = '/league/{leagueId}/ladder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeagueLadder$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueLadder$Plain$Response(params: GetLeagueLadder$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlayerRankingDto>>> {
    return getLeagueLadder$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeagueLadder$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueLadder$Plain(params: GetLeagueLadder$Plain$Params, context?: HttpContext): Observable<Array<PlayerRankingDto>> {
    return this.getLeagueLadder$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlayerRankingDto>>): Array<PlayerRankingDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeagueLadder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueLadder$Response(params: GetLeagueLadder$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlayerRankingDto>>> {
    return getLeagueLadder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeagueLadder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueLadder(params: GetLeagueLadder$Params, context?: HttpContext): Observable<Array<PlayerRankingDto>> {
    return this.getLeagueLadder$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlayerRankingDto>>): Array<PlayerRankingDto> => r.body)
    );
  }

}
