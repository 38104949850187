/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { findPlayers } from '../fn/player/find-players';
import { FindPlayers$Params } from '../fn/player/find-players';
import { findPlayers$Plain } from '../fn/player/find-players-plain';
import { FindPlayers$Plain$Params } from '../fn/player/find-players-plain';
import { PlayerDto } from '../models/player-dto';
import { updatePlayer } from '../fn/player/update-player';
import { UpdatePlayer$Params } from '../fn/player/update-player';

@Injectable({ providedIn: 'root' })
export class PlayerServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findPlayers()` */
  static readonly FindPlayersPath = '/player/search';

  /**
   * Find players to add to a tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findPlayers$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findPlayers$Plain$Response(params?: FindPlayers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlayerDto>>> {
    return findPlayers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Find players to add to a tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findPlayers$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findPlayers$Plain(params?: FindPlayers$Plain$Params, context?: HttpContext): Observable<Array<PlayerDto>> {
    return this.findPlayers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlayerDto>>): Array<PlayerDto> => r.body)
    );
  }

  /**
   * Find players to add to a tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findPlayers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findPlayers$Response(params?: FindPlayers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlayerDto>>> {
    return findPlayers(this.http, this.rootUrl, params, context);
  }

  /**
   * Find players to add to a tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findPlayers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findPlayers(params?: FindPlayers$Params, context?: HttpContext): Observable<Array<PlayerDto>> {
    return this.findPlayers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlayerDto>>): Array<PlayerDto> => r.body)
    );
  }

  /** Path part for operation `updatePlayer()` */
  static readonly UpdatePlayerPath = '/player/update/{playerId}';

  /**
   * Updates player and account details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlayer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlayer$Response(params: UpdatePlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updatePlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates player and account details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlayer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlayer(params: UpdatePlayer$Params, context?: HttpContext): Observable<void> {
    return this.updatePlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
