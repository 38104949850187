/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addCustomScore } from '../fn/tournament-custom-score/add-custom-score';
import { AddCustomScore$Params } from '../fn/tournament-custom-score/add-custom-score';
import { addCustomScore$Plain } from '../fn/tournament-custom-score/add-custom-score-plain';
import { AddCustomScore$Plain$Params } from '../fn/tournament-custom-score/add-custom-score-plain';
import { CustomScoreItemDto } from '../models/custom-score-item-dto';
import { removeCustomScore } from '../fn/tournament-custom-score/remove-custom-score';
import { RemoveCustomScore$Params } from '../fn/tournament-custom-score/remove-custom-score';
import { updateCustomScore } from '../fn/tournament-custom-score/update-custom-score';
import { UpdateCustomScore$Params } from '../fn/tournament-custom-score/update-custom-score';
import { updateCustomScore$Plain } from '../fn/tournament-custom-score/update-custom-score-plain';
import { UpdateCustomScore$Plain$Params } from '../fn/tournament-custom-score/update-custom-score-plain';

@Injectable({ providedIn: 'root' })
export class TournamentCustomScoreServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `addCustomScore()` */
  static readonly AddCustomScorePath = '/tournament/{id}/customscore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCustomScore$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCustomScore$Plain$Response(params: AddCustomScore$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomScoreItemDto>> {
    return addCustomScore$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCustomScore$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCustomScore$Plain(params: AddCustomScore$Plain$Params, context?: HttpContext): Observable<CustomScoreItemDto> {
    return this.addCustomScore$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomScoreItemDto>): CustomScoreItemDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCustomScore()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCustomScore$Response(params: AddCustomScore$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomScoreItemDto>> {
    return addCustomScore(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCustomScore$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCustomScore(params: AddCustomScore$Params, context?: HttpContext): Observable<CustomScoreItemDto> {
    return this.addCustomScore$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomScoreItemDto>): CustomScoreItemDto => r.body)
    );
  }

  /** Path part for operation `updateCustomScore()` */
  static readonly UpdateCustomScorePath = '/tournament/{id}/customscore/{scoreId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomScore$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCustomScore$Plain$Response(params: UpdateCustomScore$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomScoreItemDto>> {
    return updateCustomScore$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomScore$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCustomScore$Plain(params: UpdateCustomScore$Plain$Params, context?: HttpContext): Observable<CustomScoreItemDto> {
    return this.updateCustomScore$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomScoreItemDto>): CustomScoreItemDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomScore()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCustomScore$Response(params: UpdateCustomScore$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomScoreItemDto>> {
    return updateCustomScore(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomScore$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCustomScore(params: UpdateCustomScore$Params, context?: HttpContext): Observable<CustomScoreItemDto> {
    return this.updateCustomScore$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomScoreItemDto>): CustomScoreItemDto => r.body)
    );
  }

  /** Path part for operation `removeCustomScore()` */
  static readonly RemoveCustomScorePath = '/tournament/{id}/customscore/{scoreId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCustomScore()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCustomScore$Response(params: RemoveCustomScore$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeCustomScore(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeCustomScore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCustomScore(params: RemoveCustomScore$Params, context?: HttpContext): Observable<void> {
    return this.removeCustomScore$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
