import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './components/layout/layout.component';
import { StyleClassModule } from 'primeng/styleclass';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { TabMenuModule } from 'primeng/tabmenu';

@NgModule({
  declarations: [
    LayoutComponent,
    BreadcrumbComponent,
    NavbarComponent,
  ],
  imports: [
    CommonModule,
    StyleClassModule,
    RouterModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    MenubarModule,
    TabMenuModule,
  ],
  exports: [
    LayoutComponent
  ],
})
export class CoreModule { }
