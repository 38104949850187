import { AppRoutingService } from 'src/app/modules/core/services/app-routing.service';
import { ActivatedRoute } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { UserAccountDto, UserRole } from 'src/app/api/models';
import { AccountService } from '../../services/account.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentUser$: Observable<UserAccountDto | null>;
  items: MenuItem[] = [];

  userItems: MenuItem[] = [
    { label: 'Home', icon: 'fa-solid fa-house', routerLink: 'home' },
    {
      label: 'Player', icon: 'fa-solid fa-dice-d20', routerLink: 'player', routerLinkActiveOptions: { exact: false }, 
      items: [
        { label: 'Find Tournament', icon: 'fa-solid fa-dice-d20', routerLink: 'player/tournaments' },
        { label: 'Edit Profile', icon: 'fa-solid fa-user', routerLink: 'player/profile' }
      ]
    },
    {
      label: 'Tournament Organiser', icon: 'fa-solid fa-clipboard-list', routerLink: 'organiser',   items: [
        { label: 'My Tournaments', icon: 'fa-solid fa-clipboard-user', routerLink: 'organiser/my-tournaments' },
        { label: 'Create Tournament', icon: 'fa-solid fa-clipboard-medical', routerLink: 'organiser/new-tournament' },
      ]
    },
    {
      label: 'Leagues', icon: 'fa-solid fa-trophy-star', routerLink: 'league', items: [
        { label: 'Find', icon: 'fa-solid fa-trophy-star', routerLink: 'league/search' },
        { label: 'My Leagues', icon: 'fa-solid fa-scroll-old', routerLink: 'league/my-leagues' },
      ]
    },
  ];

  
  adminMenuItems: MenuItem[] = [
    { label: 'All Tournaments', icon: 'fa-solid fa-list-timeline', routerLink: 'admin/tournaments' },
    { label: 'Users', icon: 'fa-solid fa-users', routerLink: 'admin/users' },
    { label: 'Configuration', icon: 'fa-solid fa-cog', routerLink: 'admin/data-admin/gametypes' },
  ]

  exportMenu: MenuItem[] = [
    { label: 'Export Results', icon: 'fa-solid fa-download', routerLink: 'tournament/export' },
  ]


  constructor(private accountService: AccountService, private routingService: AppRoutingService) {
    this.currentUser$ = this.accountService.account$.pipe(tap((usr) => {
      this.items = (usr?.role == UserRole.Administrator) ? this.adminMenuItems : this.userItems;
      if (usr?.exportResults) {
        this.items = this.items.concat(this.exportMenu);
      }
    }));
  }

  ngOnInit(): void {
  }

  logout() {
    this.accountService.logout();
  }

  gotoProfile() {
    this.routingService.profile();
  }

}

