/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TeamMemberDto } from '../../models/team-member-dto';

export interface AddPlayerToTeam$Plain$Params {
  tournamentId: string;
  teamRegistrationId: string;
  playerRegistrationId: string;
}

export function addPlayerToTeam$Plain(http: HttpClient, rootUrl: string, params: AddPlayerToTeam$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamMemberDto>> {
  const rb = new RequestBuilder(rootUrl, addPlayerToTeam$Plain.PATH, 'post');
  if (params) {
    rb.path('tournamentId', params.tournamentId, {});
    rb.path('teamRegistrationId', params.teamRegistrationId, {});
    rb.path('playerRegistrationId', params.playerRegistrationId, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TeamMemberDto>;
    })
  );
}

addPlayerToTeam$Plain.PATH = '/tournament/{tournamentId}/teams/{teamRegistrationId}/add-player/{playerRegistrationId}';
