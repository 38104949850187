/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getResult } from '../fn/pairing-result/get-result';
import { GetResult$Params } from '../fn/pairing-result/get-result';
import { getResult$Plain } from '../fn/pairing-result/get-result-plain';
import { GetResult$Plain$Params } from '../fn/pairing-result/get-result-plain';
import { PairingResultDto } from '../models/pairing-result-dto';
import { submitResult } from '../fn/pairing-result/submit-result';
import { SubmitResult$Params } from '../fn/pairing-result/submit-result';

@Injectable({ providedIn: 'root' })
export class PairingResultServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getResult()` */
  static readonly GetResultPath = '/pairingresult/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResult$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResult$Plain$Response(params: GetResult$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PairingResultDto>> {
    return getResult$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResult$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResult$Plain(params: GetResult$Plain$Params, context?: HttpContext): Observable<PairingResultDto> {
    return this.getResult$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PairingResultDto>): PairingResultDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResult$Response(params: GetResult$Params, context?: HttpContext): Observable<StrictHttpResponse<PairingResultDto>> {
    return getResult(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResult(params: GetResult$Params, context?: HttpContext): Observable<PairingResultDto> {
    return this.getResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<PairingResultDto>): PairingResultDto => r.body)
    );
  }

  /** Path part for operation `submitResult()` */
  static readonly SubmitResultPath = '/pairingresult';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitResult()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitResult$Response(params?: SubmitResult$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return submitResult(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitResult$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitResult(params?: SubmitResult$Params, context?: HttpContext): Observable<void> {
    return this.submitResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
