/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPlayerStats } from '../fn/league-player/get-player-stats';
import { GetPlayerStats$Params } from '../fn/league-player/get-player-stats';
import { getPlayerStats$Plain } from '../fn/league-player/get-player-stats-plain';
import { GetPlayerStats$Plain$Params } from '../fn/league-player/get-player-stats-plain';
import { LeaguePlayerStatsDto } from '../models/league-player-stats-dto';

@Injectable({ providedIn: 'root' })
export class LeaguePlayerServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlayerStats()` */
  static readonly GetPlayerStatsPath = '/league/{leagueId}/players/{leagueRegistrationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlayerStats$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerStats$Plain$Response(params: GetPlayerStats$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LeaguePlayerStatsDto>> {
    return getPlayerStats$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlayerStats$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerStats$Plain(params: GetPlayerStats$Plain$Params, context?: HttpContext): Observable<LeaguePlayerStatsDto> {
    return this.getPlayerStats$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeaguePlayerStatsDto>): LeaguePlayerStatsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlayerStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerStats$Response(params: GetPlayerStats$Params, context?: HttpContext): Observable<StrictHttpResponse<LeaguePlayerStatsDto>> {
    return getPlayerStats(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlayerStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlayerStats(params: GetPlayerStats$Params, context?: HttpContext): Observable<LeaguePlayerStatsDto> {
    return this.getPlayerStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeaguePlayerStatsDto>): LeaguePlayerStatsDto => r.body)
    );
  }

}
