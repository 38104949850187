/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addSeason } from '../fn/league/add-season';
import { AddSeason$Params } from '../fn/league/add-season';
import { addSeason$Plain } from '../fn/league/add-season-plain';
import { AddSeason$Plain$Params } from '../fn/league/add-season-plain';
import { createLeague } from '../fn/league/create-league';
import { CreateLeague$Params } from '../fn/league/create-league';
import { createLeague$Plain } from '../fn/league/create-league-plain';
import { CreateLeague$Plain$Params } from '../fn/league/create-league-plain';
import { deleteSeason } from '../fn/league/delete-season';
import { DeleteSeason$Params } from '../fn/league/delete-season';
import { getAllLeagues } from '../fn/league/get-all-leagues';
import { GetAllLeagues$Params } from '../fn/league/get-all-leagues';
import { getAllLeagues$Plain } from '../fn/league/get-all-leagues-plain';
import { GetAllLeagues$Plain$Params } from '../fn/league/get-all-leagues-plain';
import { getLeague } from '../fn/league/get-league';
import { GetLeague$Params } from '../fn/league/get-league';
import { getLeague$Plain } from '../fn/league/get-league-plain';
import { GetLeague$Plain$Params } from '../fn/league/get-league-plain';
import { getUserLeagues } from '../fn/league/get-user-leagues';
import { GetUserLeagues$Params } from '../fn/league/get-user-leagues';
import { getUserLeagues$Plain } from '../fn/league/get-user-leagues-plain';
import { GetUserLeagues$Plain$Params } from '../fn/league/get-user-leagues-plain';
import { LeagueDto } from '../models/league-dto';
import { LeagueListItemDto } from '../models/league-list-item-dto';
import { SeasonDto } from '../models/season-dto';
import { updateLeague } from '../fn/league/update-league';
import { UpdateLeague$Params } from '../fn/league/update-league';
import { updateLeague$Plain } from '../fn/league/update-league-plain';
import { UpdateLeague$Plain$Params } from '../fn/league/update-league-plain';
import { updateSeason } from '../fn/league/update-season';
import { UpdateSeason$Params } from '../fn/league/update-season';

@Injectable({ providedIn: 'root' })
export class LeagueServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllLeagues()` */
  static readonly GetAllLeaguesPath = '/league';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllLeagues$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllLeagues$Plain$Response(params?: GetAllLeagues$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LeagueListItemDto>>> {
    return getAllLeagues$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllLeagues$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllLeagues$Plain(params?: GetAllLeagues$Plain$Params, context?: HttpContext): Observable<Array<LeagueListItemDto>> {
    return this.getAllLeagues$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LeagueListItemDto>>): Array<LeagueListItemDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllLeagues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllLeagues$Response(params?: GetAllLeagues$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LeagueListItemDto>>> {
    return getAllLeagues(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllLeagues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllLeagues(params?: GetAllLeagues$Params, context?: HttpContext): Observable<Array<LeagueListItemDto>> {
    return this.getAllLeagues$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LeagueListItemDto>>): Array<LeagueListItemDto> => r.body)
    );
  }

  /** Path part for operation `createLeague()` */
  static readonly CreateLeaguePath = '/league';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLeague$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createLeague$Plain$Response(params?: CreateLeague$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueDto>> {
    return createLeague$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createLeague$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createLeague$Plain(params?: CreateLeague$Plain$Params, context?: HttpContext): Observable<LeagueDto> {
    return this.createLeague$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueDto>): LeagueDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLeague()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createLeague$Response(params?: CreateLeague$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueDto>> {
    return createLeague(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createLeague$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createLeague(params?: CreateLeague$Params, context?: HttpContext): Observable<LeagueDto> {
    return this.createLeague$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueDto>): LeagueDto => r.body)
    );
  }

  /** Path part for operation `getUserLeagues()` */
  static readonly GetUserLeaguesPath = '/league/my-leagues';

  /**
   * Gets the leagues that this user is responsible for
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserLeagues$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserLeagues$Plain$Response(params?: GetUserLeagues$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LeagueListItemDto>>> {
    return getUserLeagues$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the leagues that this user is responsible for
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserLeagues$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserLeagues$Plain(params?: GetUserLeagues$Plain$Params, context?: HttpContext): Observable<Array<LeagueListItemDto>> {
    return this.getUserLeagues$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LeagueListItemDto>>): Array<LeagueListItemDto> => r.body)
    );
  }

  /**
   * Gets the leagues that this user is responsible for
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserLeagues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserLeagues$Response(params?: GetUserLeagues$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LeagueListItemDto>>> {
    return getUserLeagues(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the leagues that this user is responsible for
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserLeagues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserLeagues(params?: GetUserLeagues$Params, context?: HttpContext): Observable<Array<LeagueListItemDto>> {
    return this.getUserLeagues$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LeagueListItemDto>>): Array<LeagueListItemDto> => r.body)
    );
  }

  /** Path part for operation `getLeague()` */
  static readonly GetLeaguePath = '/league/{leagueId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeague$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeague$Plain$Response(params: GetLeague$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueDto>> {
    return getLeague$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeague$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeague$Plain(params: GetLeague$Plain$Params, context?: HttpContext): Observable<LeagueDto> {
    return this.getLeague$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueDto>): LeagueDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeague()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeague$Response(params: GetLeague$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueDto>> {
    return getLeague(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeague$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeague(params: GetLeague$Params, context?: HttpContext): Observable<LeagueDto> {
    return this.getLeague$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueDto>): LeagueDto => r.body)
    );
  }

  /** Path part for operation `updateLeague()` */
  static readonly UpdateLeaguePath = '/league/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLeague$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLeague$Plain$Response(params: UpdateLeague$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueDto>> {
    return updateLeague$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLeague$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLeague$Plain(params: UpdateLeague$Plain$Params, context?: HttpContext): Observable<LeagueDto> {
    return this.updateLeague$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueDto>): LeagueDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLeague()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLeague$Response(params: UpdateLeague$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueDto>> {
    return updateLeague(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLeague$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLeague(params: UpdateLeague$Params, context?: HttpContext): Observable<LeagueDto> {
    return this.updateLeague$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueDto>): LeagueDto => r.body)
    );
  }

  /** Path part for operation `addSeason()` */
  static readonly AddSeasonPath = '/league/{leagueId}/season';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSeason$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addSeason$Plain$Response(params: AddSeason$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SeasonDto>> {
    return addSeason$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addSeason$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addSeason$Plain(params: AddSeason$Plain$Params, context?: HttpContext): Observable<SeasonDto> {
    return this.addSeason$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeasonDto>): SeasonDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSeason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addSeason$Response(params: AddSeason$Params, context?: HttpContext): Observable<StrictHttpResponse<SeasonDto>> {
    return addSeason(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addSeason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addSeason(params: AddSeason$Params, context?: HttpContext): Observable<SeasonDto> {
    return this.addSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeasonDto>): SeasonDto => r.body)
    );
  }

  /** Path part for operation `updateSeason()` */
  static readonly UpdateSeasonPath = '/league/season/{seasonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSeason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSeason$Response(params: UpdateSeason$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateSeason(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSeason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateSeason(params: UpdateSeason$Params, context?: HttpContext): Observable<void> {
    return this.updateSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteSeason()` */
  static readonly DeleteSeasonPath = '/league/season/{seasonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSeason()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSeason$Response(params: DeleteSeason$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSeason(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSeason$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSeason(params: DeleteSeason$Params, context?: HttpContext): Observable<void> {
    return this.deleteSeason$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
