/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addScoreAdjustment } from '../fn/score-adjustment/add-score-adjustment';
import { AddScoreAdjustment$Params } from '../fn/score-adjustment/add-score-adjustment';
import { addScoreAdjustment$Plain } from '../fn/score-adjustment/add-score-adjustment-plain';
import { AddScoreAdjustment$Plain$Params } from '../fn/score-adjustment/add-score-adjustment-plain';
import { removeScoreAdjustment } from '../fn/score-adjustment/remove-score-adjustment';
import { RemoveScoreAdjustment$Params } from '../fn/score-adjustment/remove-score-adjustment';
import { ScoreAdjustmentDto } from '../models/score-adjustment-dto';

@Injectable({ providedIn: 'root' })
export class ScoreAdjustmentServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `addScoreAdjustment()` */
  static readonly AddScoreAdjustmentPath = '/tournament/{tournamentId}/{registrationId}/scoreadjustment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addScoreAdjustment$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addScoreAdjustment$Plain$Response(params: AddScoreAdjustment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ScoreAdjustmentDto>> {
    return addScoreAdjustment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addScoreAdjustment$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addScoreAdjustment$Plain(params: AddScoreAdjustment$Plain$Params, context?: HttpContext): Observable<ScoreAdjustmentDto> {
    return this.addScoreAdjustment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScoreAdjustmentDto>): ScoreAdjustmentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addScoreAdjustment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addScoreAdjustment$Response(params: AddScoreAdjustment$Params, context?: HttpContext): Observable<StrictHttpResponse<ScoreAdjustmentDto>> {
    return addScoreAdjustment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addScoreAdjustment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addScoreAdjustment(params: AddScoreAdjustment$Params, context?: HttpContext): Observable<ScoreAdjustmentDto> {
    return this.addScoreAdjustment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScoreAdjustmentDto>): ScoreAdjustmentDto => r.body)
    );
  }

  /** Path part for operation `removeScoreAdjustment()` */
  static readonly RemoveScoreAdjustmentPath = '/tournament/{tournamentId}/{registrationId}/scoreadjustment/{adjustmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeScoreAdjustment()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeScoreAdjustment$Response(params: RemoveScoreAdjustment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeScoreAdjustment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeScoreAdjustment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeScoreAdjustment(params: RemoveScoreAdjustment$Params, context?: HttpContext): Observable<void> {
    return this.removeScoreAdjustment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
