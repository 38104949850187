/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addPlayerToTeam } from '../fn/tournament-teams/add-player-to-team';
import { AddPlayerToTeam$Params } from '../fn/tournament-teams/add-player-to-team';
import { addPlayerToTeam$Plain } from '../fn/tournament-teams/add-player-to-team-plain';
import { AddPlayerToTeam$Plain$Params } from '../fn/tournament-teams/add-player-to-team-plain';
import { createTeam } from '../fn/tournament-teams/create-team';
import { CreateTeam$Params } from '../fn/tournament-teams/create-team';
import { deleteTeam } from '../fn/tournament-teams/delete-team';
import { DeleteTeam$Params } from '../fn/tournament-teams/delete-team';
import { getTeams } from '../fn/tournament-teams/get-teams';
import { GetTeams$Params } from '../fn/tournament-teams/get-teams';
import { getTeams$Plain } from '../fn/tournament-teams/get-teams-plain';
import { GetTeams$Plain$Params } from '../fn/tournament-teams/get-teams-plain';
import { RegistrationDto } from '../models/registration-dto';
import { removePlayerFromTeam } from '../fn/tournament-teams/remove-player-from-team';
import { RemovePlayerFromTeam$Params } from '../fn/tournament-teams/remove-player-from-team';
import { TeamMemberDto } from '../models/team-member-dto';
import { updateTeamName } from '../fn/tournament-teams/update-team-name';
import { UpdateTeamName$Params } from '../fn/tournament-teams/update-team-name';

@Injectable({ providedIn: 'root' })
export class TournamentTeamsServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTeams()` */
  static readonly GetTeamsPath = '/tournament/{tournamentId}/teams';

  /**
   * Gets the touranment teams for a team tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTeams$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeams$Plain$Response(params: GetTeams$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistrationDto>>> {
    return getTeams$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the touranment teams for a team tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTeams$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeams$Plain(params: GetTeams$Plain$Params, context?: HttpContext): Observable<Array<RegistrationDto>> {
    return this.getTeams$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationDto>>): Array<RegistrationDto> => r.body)
    );
  }

  /**
   * Gets the touranment teams for a team tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTeams()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeams$Response(params: GetTeams$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistrationDto>>> {
    return getTeams(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the touranment teams for a team tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTeams$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeams(params: GetTeams$Params, context?: HttpContext): Observable<Array<RegistrationDto>> {
    return this.getTeams$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistrationDto>>): Array<RegistrationDto> => r.body)
    );
  }

  /** Path part for operation `createTeam()` */
  static readonly CreateTeamPath = '/tournament/{tournamentId}/teams';

  /**
   * Create a new team for the tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTeam()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTeam$Response(params: CreateTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new team for the tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTeam$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTeam(params: CreateTeam$Params, context?: HttpContext): Observable<void> {
    return this.createTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addPlayerToTeam()` */
  static readonly AddPlayerToTeamPath = '/tournament/{tournamentId}/teams/{teamRegistrationId}/add-player/{playerRegistrationId}';

  /**
   * Adds a new team member to the team
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPlayerToTeam$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addPlayerToTeam$Plain$Response(params: AddPlayerToTeam$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamMemberDto>> {
    return addPlayerToTeam$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds a new team member to the team
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPlayerToTeam$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addPlayerToTeam$Plain(params: AddPlayerToTeam$Plain$Params, context?: HttpContext): Observable<TeamMemberDto> {
    return this.addPlayerToTeam$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamMemberDto>): TeamMemberDto => r.body)
    );
  }

  /**
   * Adds a new team member to the team
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPlayerToTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  addPlayerToTeam$Response(params: AddPlayerToTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamMemberDto>> {
    return addPlayerToTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds a new team member to the team
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPlayerToTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addPlayerToTeam(params: AddPlayerToTeam$Params, context?: HttpContext): Observable<TeamMemberDto> {
    return this.addPlayerToTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamMemberDto>): TeamMemberDto => r.body)
    );
  }

  /** Path part for operation `updateTeamName()` */
  static readonly UpdateTeamNamePath = '/tournament/{tournamentId}/teams/{teamRegistrationId}';

  /**
   * Updates the team name
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTeamName()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTeamName$Response(params: UpdateTeamName$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateTeamName(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the team name
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTeamName$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTeamName(params: UpdateTeamName$Params, context?: HttpContext): Observable<void> {
    return this.updateTeamName$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteTeam()` */
  static readonly DeleteTeamPath = '/tournament/{tournamentId}/teams/{teamRegistrationId}';

  /**
   * Removes a team from the tournament
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTeam$Response(params: DeleteTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes a team from the tournament
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTeam(params: DeleteTeam$Params, context?: HttpContext): Observable<void> {
    return this.deleteTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removePlayerFromTeam()` */
  static readonly RemovePlayerFromTeamPath = '/tournament/{tournamentId}/teams/{teamRegistrationId}/player/{playerRegistrationId}';

  /**
   * Removes a player from a team
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removePlayerFromTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePlayerFromTeam$Response(params: RemovePlayerFromTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removePlayerFromTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes a player from a team
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removePlayerFromTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePlayerFromTeam(params: RemovePlayerFromTeam$Params, context?: HttpContext): Observable<void> {
    return this.removePlayerFromTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
