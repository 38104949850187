import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';
import { AppRoutingService } from '../services/app-routing.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private appRouting: AppRoutingService
) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const account = this.accountService.accountValue;
      if (account) {
          // check if route is restricted by role
          if (route.data["roles"] && !route.data["roles"].includes(account.role)) {
              // role not authorized so redirect to home page
              this.router.navigate(['/']);
              return false;
          }

          // authorized so return true
          return true;
      }

      // not logged in so redirect to login page with the return url
      this.appRouting.login(state.url);
      
      return false;
  }
  
}
