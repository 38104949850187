/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountsServiceAPI } from './services/accounts-service-api';
import { ArmyServiceAPI } from './services/army-service-api';
import { ClubServiceAPI } from './services/club-service-api';
import { DocumentServiceAPI } from './services/document-service-api';
import { ExportServiceAPI } from './services/export-service-api';
import { ExternalTournamentServiceAPI } from './services/external-tournament-service-api';
import { FinanceServiceAPI } from './services/finance-service-api';
import { GameTypeServiceAPI } from './services/game-type-service-api';
import { LadderServiceAPI } from './services/ladder-service-api';
import { LeagueServiceAPI } from './services/league-service-api';
import { LeagueEntryRequestServiceAPI } from './services/league-entry-request-service-api';
import { LeagueHomeServiceAPI } from './services/league-home-service-api';
import { LeagueLadderServiceAPI } from './services/league-ladder-service-api';
import { LeaguePlayerServiceAPI } from './services/league-player-service-api';
import { LeagueTournamentServiceAPI } from './services/league-tournament-service-api';
import { PairingServiceAPI } from './services/pairing-service-api';
import { PairingResultServiceAPI } from './services/pairing-result-service-api';
import { PaymentServiceAPI } from './services/payment-service-api';
import { PlayerServiceAPI } from './services/player-service-api';
import { ProductServiceAPI } from './services/product-service-api';
import { RegisteredPlayerServiceAPI } from './services/registered-player-service-api';
import { RoundServiceAPI } from './services/round-service-api';
import { ScoreAdjustmentServiceAPI } from './services/score-adjustment-service-api';
import { ScoringSystemServiceAPI } from './services/scoring-system-service-api';
import { TouranmentFairplayScoreServiceAPI } from './services/touranment-fairplay-score-service-api';
import { TournamentServiceAPI } from './services/tournament-service-api';
import { TournamentCustomScoreServiceAPI } from './services/tournament-custom-score-service-api';
import { TournamentOrganisersServiceAPI } from './services/tournament-organisers-service-api';
import { TournamentTeamsServiceAPI } from './services/tournament-teams-service-api';
import { UserServiceAPI } from './services/user-service-api';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountsServiceAPI,
    ArmyServiceAPI,
    ClubServiceAPI,
    DocumentServiceAPI,
    ExportServiceAPI,
    ExternalTournamentServiceAPI,
    FinanceServiceAPI,
    GameTypeServiceAPI,
    LadderServiceAPI,
    LeagueServiceAPI,
    LeagueEntryRequestServiceAPI,
    LeagueHomeServiceAPI,
    LeagueLadderServiceAPI,
    LeaguePlayerServiceAPI,
    LeagueTournamentServiceAPI,
    PairingServiceAPI,
    PairingResultServiceAPI,
    PaymentServiceAPI,
    PlayerServiceAPI,
    ProductServiceAPI,
    RegisteredPlayerServiceAPI,
    RoundServiceAPI,
    ScoreAdjustmentServiceAPI,
    ScoringSystemServiceAPI,
    TouranmentFairplayScoreServiceAPI,
    TournamentServiceAPI,
    TournamentCustomScoreServiceAPI,
    TournamentOrganisersServiceAPI,
    TournamentTeamsServiceAPI,
    UserServiceAPI,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
