import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAccountDto } from 'src/app/api/models';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  currentUser$: Observable<UserAccountDto | null>;

  constructor(accountService: AccountService) {
    this.currentUser$ = accountService.account$;
   }

  ngOnInit(): void {
  }

}
