import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CountryStateService {
    //loaded$ = new BehaviorSubject<boolean>(false);
    private _allData: CountryData[] = [];
    countries$ = new ReplaySubject<CountryData[]>();

    //List of states for a specific country
    states$ = new ReplaySubject<StateData[]>();

    constructor(private http: HttpClient) {
        this.http.get<CountryData[]>('assets/data/countriesstates.json').subscribe({
            next: (res) => {
                this._allData = res.map(x => <CountryData>{
                    name: x.name,
                    iso3: x.iso3,
                    states: x.states.map(y => <StateData>{
                        id: y.id,
                        name: y.name,
                        state_code: y.state_code,
                        type: y.type
                    })
                });
                this.countries$.next(this._allData);
            }, error: (err) => console.log(err)
        });
    }

    setCountry(countryName: string) {
        const states = this._allData.find(x => x.name == countryName)?.states || [];
        this.states$.next(states);
    }

}

export interface CountryData {
    name: string;
    iso3: string;
    states: StateData[]
}

export interface StateData {
    id: number;
    name: string;
    state_code: string;
    type: string;
}

// const allData = [
//     {
//         "name": "Australia",
//         "iso3": "AUS",
//         "iso2": "AU",
//         "numeric_code": "036",
//         "phone_code": "61",
//         "capital": "Canberra",
//         "currency": "AUD",
//         "currency_name": "Australian dollar",
//         "currency_symbol": "$",
//         "tld": ".au",
//         "native": "Australia",
//         "region": "Oceania",
//         "subregion": "Australia and New Zealand",
//         "timezones": [
//             {
//                 "zoneName": "Antarctica\/Macquarie",
//                 "gmtOffset": 39600,
//                 "gmtOffsetName": "UTC+11:00",
//                 "abbreviation": "MIST",
//                 "tzName": "Macquarie Island Station Time"
//             },
//             {
//                 "zoneName": "Australia\/Adelaide",
//                 "gmtOffset": 37800,
//                 "gmtOffsetName": "UTC+10:30",
//                 "abbreviation": "ACDT",
//                 "tzName": "Australian Central Daylight Saving Time"
//             },
//             {
//                 "zoneName": "Australia\/Brisbane",
//                 "gmtOffset": 36000,
//                 "gmtOffsetName": "UTC+10:00",
//                 "abbreviation": "AEST",
//                 "tzName": "Australian Eastern Standard Time"
//             },
//             {
//                 "zoneName": "Australia\/Broken_Hill",
//                 "gmtOffset": 37800,
//                 "gmtOffsetName": "UTC+10:30",
//                 "abbreviation": "ACDT",
//                 "tzName": "Australian Central Daylight Saving Time"
//             },
//             {
//                 "zoneName": "Australia\/Currie",
//                 "gmtOffset": 39600,
//                 "gmtOffsetName": "UTC+11:00",
//                 "abbreviation": "AEDT",
//                 "tzName": "Australian Eastern Daylight Saving Time"
//             },
//             {
//                 "zoneName": "Australia\/Darwin",
//                 "gmtOffset": 34200,
//                 "gmtOffsetName": "UTC+09:30",
//                 "abbreviation": "ACST",
//                 "tzName": "Australian Central Standard Time"
//             },
//             {
//                 "zoneName": "Australia\/Eucla",
//                 "gmtOffset": 31500,
//                 "gmtOffsetName": "UTC+08:45",
//                 "abbreviation": "ACWST",
//                 "tzName": "Australian Central Western Standard Time (Unofficial)"
//             },
//             {
//                 "zoneName": "Australia\/Hobart",
//                 "gmtOffset": 39600,
//                 "gmtOffsetName": "UTC+11:00",
//                 "abbreviation": "AEDT",
//                 "tzName": "Australian Eastern Daylight Saving Time"
//             },
//             {
//                 "zoneName": "Australia\/Lindeman",
//                 "gmtOffset": 36000,
//                 "gmtOffsetName": "UTC+10:00",
//                 "abbreviation": "AEST",
//                 "tzName": "Australian Eastern Standard Time"
//             },
//             {
//                 "zoneName": "Australia\/Lord_Howe",
//                 "gmtOffset": 39600,
//                 "gmtOffsetName": "UTC+11:00",
//                 "abbreviation": "LHST",
//                 "tzName": "Lord Howe Summer Time"
//             },
//             {
//                 "zoneName": "Australia\/Melbourne",
//                 "gmtOffset": 39600,
//                 "gmtOffsetName": "UTC+11:00",
//                 "abbreviation": "AEDT",
//                 "tzName": "Australian Eastern Daylight Saving Time"
//             },
//             {
//                 "zoneName": "Australia\/Perth",
//                 "gmtOffset": 28800,
//                 "gmtOffsetName": "UTC+08:00",
//                 "abbreviation": "AWST",
//                 "tzName": "Australian Western Standard Time"
//             },
//             {
//                 "zoneName": "Australia\/Sydney",
//                 "gmtOffset": 39600,
//                 "gmtOffsetName": "UTC+11:00",
//                 "abbreviation": "AEDT",
//                 "tzName": "Australian Eastern Daylight Saving Time"
//             }
//         ],
//         "translations": {
//             "kr": "호주",
//             "pt-BR": "Austrália",
//             "pt": "Austrália",
//             "nl": "Australië",
//             "hr": "Australija",
//             "fa": "استرالیا",
//             "de": "Australien",
//             "es": "Australia",
//             "fr": "Australie",
//             "ja": "オーストラリア",
//             "it": "Australia",
//             "cn": "澳大利亚",
//             "tr": "Avustralya"
//         },
//         "latitude": "-27.00000000",
//         "longitude": "133.00000000",
//         "emoji": "🇦🇺",
//         "emojiU": "U+1F1E6 U+1F1FA",
//         "states": [
//             {
//                 "id": 3907,
//                 "name": "Australian Capital Territory",
//                 "state_code": "ACT",
//                 "latitude": "-35.47346790",
//                 "longitude": "149.01236790",
//                 "type": "territory"
//             },
//             {
//                 "id": 3909,
//                 "name": "New South Wales",
//                 "state_code": "NSW",
//                 "latitude": "-31.25321830",
//                 "longitude": "146.92109900",
//                 "type": "state"
//             },
//             {
//                 "id": 3910,
//                 "name": "Northern Territory",
//                 "state_code": "NT",
//                 "latitude": "-19.49141080",
//                 "longitude": "132.55096030",
//                 "type": "territory"
//             },
//             {
//                 "id": 3905,
//                 "name": "Queensland",
//                 "state_code": "QLD",
//                 "latitude": "-20.91757380",
//                 "longitude": "142.70279560",
//                 "type": "state"
//             },
//             {
//                 "id": 3904,
//                 "name": "South Australia",
//                 "state_code": "SA",
//                 "latitude": "-30.00023150",
//                 "longitude": "136.20915470",
//                 "type": "state"
//             },
//             {
//                 "id": 3908,
//                 "name": "Tasmania",
//                 "state_code": "TAS",
//                 "latitude": "-41.45451960",
//                 "longitude": "145.97066470",
//                 "type": "state"
//             },
//             {
//                 "id": 3903,
//                 "name": "Victoria",
//                 "state_code": "VIC",
//                 "latitude": "-36.48564230",
//                 "longitude": "140.97794250",
//                 "type": "state"
//             },
//             {
//                 "id": 3906,
//                 "name": "Western Australia",
//                 "state_code": "WA",
//                 "latitude": "-27.67281680",
//                 "longitude": "121.62830980",
//                 "type": "state"
//             }
//         ]
//     },
//     {
//         "name": "New Zealand",
//         "iso3": "NZL",
//         "iso2": "NZ",
//         "numeric_code": "554",
//         "phone_code": "64",
//         "capital": "Wellington",
//         "currency": "NZD",
//         "currency_name": "New Zealand dollar",
//         "currency_symbol": "$",
//         "tld": ".nz",
//         "native": "New Zealand",
//         "region": "Oceania",
//         "subregion": "Australia and New Zealand",
//         "timezones": [
//             {
//                 "zoneName": "Pacific\/Auckland",
//                 "gmtOffset": 46800,
//                 "gmtOffsetName": "UTC+13:00",
//                 "abbreviation": "NZDT",
//                 "tzName": "New Zealand Daylight Time"
//             },
//             {
//                 "zoneName": "Pacific\/Chatham",
//                 "gmtOffset": 49500,
//                 "gmtOffsetName": "UTC+13:45",
//                 "abbreviation": "CHAST",
//                 "tzName": "Chatham Standard Time"
//             }
//         ],
//         "translations": {
//             "kr": "뉴질랜드",
//             "pt-BR": "Nova Zelândia",
//             "pt": "Nova Zelândia",
//             "nl": "Nieuw-Zeeland",
//             "hr": "Novi Zeland",
//             "fa": "نیوزیلند",
//             "de": "Neuseeland",
//             "es": "Nueva Zelanda",
//             "fr": "Nouvelle-Zélande",
//             "ja": "ニュージーランド",
//             "it": "Nuova Zelanda",
//             "cn": "新西兰",
//             "tr": "Yeni Zelanda"
//         },
//         "latitude": "-41.00000000",
//         "longitude": "174.00000000",
//         "emoji": "🇳🇿",
//         "emojiU": "U+1F1F3 U+1F1FF",
//         "states": [
//             {
//                 "id": 4072,
//                 "name": "Auckland Region",
//                 "state_code": "AUK",
//                 "latitude": "-36.66753280",
//                 "longitude": "174.77333250",
//                 "type": null
//             },
//             {
//                 "id": 4074,
//                 "name": "Bay of Plenty Region",
//                 "state_code": "BOP",
//                 "latitude": "-37.42339170",
//                 "longitude": "176.74163740",
//                 "type": null
//             },
//             {
//                 "id": 4066,
//                 "name": "Canterbury Region",
//                 "state_code": "CAN",
//                 "latitude": "-43.75422750",
//                 "longitude": "171.16372450",
//                 "type": null
//             },
//             {
//                 "id": 4067,
//                 "name": "Chatham Islands",
//                 "state_code": "CIT",
//                 "latitude": "-44.00575230",
//                 "longitude": "-176.54006740",
//                 "type": null
//             },
//             {
//                 "id": 4068,
//                 "name": "Gisborne District",
//                 "state_code": "GIS",
//                 "latitude": "-38.13581740",
//                 "longitude": "178.32393090",
//                 "type": null
//             },
//             {
//                 "id": 4075,
//                 "name": "Hawke's Bay Region",
//                 "state_code": "HKB",
//                 "latitude": "-39.60165970",
//                 "longitude": "176.58044730",
//                 "type": null
//             },
//             {
//                 "id": 4060,
//                 "name": "Manawatu-Wanganui Region",
//                 "state_code": "MWT",
//                 "latitude": "-39.72733560",
//                 "longitude": "175.43755740",
//                 "type": null
//             },
//             {
//                 "id": 4063,
//                 "name": "Marlborough Region",
//                 "state_code": "MBH",
//                 "latitude": "-41.59168830",
//                 "longitude": "173.76240530",
//                 "type": null
//             },
//             {
//                 "id": 4070,
//                 "name": "Nelson Region",
//                 "state_code": "NSN",
//                 "latitude": "-41.29853970",
//                 "longitude": "173.24414910",
//                 "type": null
//             },
//             {
//                 "id": 4059,
//                 "name": "Northland Region",
//                 "state_code": "NTL",
//                 "latitude": "-35.41361720",
//                 "longitude": "173.93208060",
//                 "type": null
//             },
//             {
//                 "id": 4062,
//                 "name": "Otago Region",
//                 "state_code": "OTA",
//                 "latitude": "-45.47906710",
//                 "longitude": "170.15475670",
//                 "type": null
//             },
//             {
//                 "id": 4071,
//                 "name": "Southland Region",
//                 "state_code": "STL",
//                 "latitude": "-45.84891590",
//                 "longitude": "167.67553870",
//                 "type": null
//             },
//             {
//                 "id": 4069,
//                 "name": "Taranaki Region",
//                 "state_code": "TKI",
//                 "latitude": "-39.35381490",
//                 "longitude": "174.43827210",
//                 "type": null
//             },
//             {
//                 "id": 4073,
//                 "name": "Tasman District",
//                 "state_code": "TAS",
//                 "latitude": "-41.45711840",
//                 "longitude": "172.82097400",
//                 "type": null
//             },
//             {
//                 "id": 4061,
//                 "name": "Waikato Region",
//                 "state_code": "WKO",
//                 "latitude": "-37.61908620",
//                 "longitude": "175.02334600",
//                 "type": null
//             },
//             {
//                 "id": 4065,
//                 "name": "Wellington Region",
//                 "state_code": "WGN",
//                 "latitude": "-41.02993230",
//                 "longitude": "175.43755740",
//                 "type": null
//             },
//             {
//                 "id": 4064,
//                 "name": "West Coast Region",
//                 "state_code": "WTC",
//                 "latitude": "62.41136340",
//                 "longitude": "-149.07297140",
//                 "type": null
//             }
//         ]
//     },
//     {
//         "name": "United Kingdom",
//         "iso3": "GBR",
//         "iso2": "GB",
//         "numeric_code": "826",
//         "phone_code": "44",
//         "capital": "London",
//         "currency": "GBP",
//         "currency_name": "British pound",
//         "currency_symbol": "£",
//         "tld": ".uk",
//         "native": "United Kingdom",
//         "region": "Europe",
//         "subregion": "Northern Europe",
//         "timezones": [
//             {
//                 "zoneName": "Europe\/London",
//                 "gmtOffset": 0,
//                 "gmtOffsetName": "UTC±00",
//                 "abbreviation": "GMT",
//                 "tzName": "Greenwich Mean Time"
//             }
//         ],
//         "translations": {
//             "kr": "영국",
//             "pt-BR": "Reino Unido",
//             "pt": "Reino Unido",
//             "nl": "Verenigd Koninkrijk",
//             "hr": "Ujedinjeno Kraljevstvo",
//             "fa": "بریتانیای کبیر و ایرلند شمالی",
//             "de": "Vereinigtes Königreich",
//             "es": "Reino Unido",
//             "fr": "Royaume-Uni",
//             "ja": "イギリス",
//             "it": "Regno Unito",
//             "cn": "英国",
//             "tr": "Birleşik Krallik"
//         },
//         "latitude": "54.00000000",
//         "longitude": "-2.00000000",
//         "emoji": "🇬🇧",
//         "emojiU": "U+1F1EC U+1F1E7",
//         "states": [
//             {
//                 "id": 2463,
//                 "name": "Aberdeen",
//                 "state_code": "ABE",
//                 "latitude": "57.14971700",
//                 "longitude": "-2.09427800",
//                 "type": null
//             },
//             {
//                 "id": 2401,
//                 "name": "Aberdeenshire",
//                 "state_code": "ABD",
//                 "latitude": "57.28687230",
//                 "longitude": "-2.38156840",
//                 "type": null
//             },
//             {
//                 "id": 2387,
//                 "name": "Angus",
//                 "state_code": "ANS",
//                 "latitude": "37.27578860",
//                 "longitude": "-95.65010330",
//                 "type": null
//             },
//             {
//                 "id": 2533,
//                 "name": "Antrim",
//                 "state_code": "ANT",
//                 "latitude": "54.71953380",
//                 "longitude": "-6.20724980",
//                 "type": null
//             },
//             {
//                 "id": 2412,
//                 "name": "Antrim and Newtownabbey",
//                 "state_code": "ANN",
//                 "latitude": "54.69568870",
//                 "longitude": "-5.94810690",
//                 "type": null
//             },
//             {
//                 "id": 2498,
//                 "name": "Ards",
//                 "state_code": "ARD",
//                 "latitude": "42.13918510",
//                 "longitude": "-87.86149720",
//                 "type": null
//             },
//             {
//                 "id": 2523,
//                 "name": "Ards and North Down",
//                 "state_code": "AND",
//                 "latitude": "54.58996450",
//                 "longitude": "-5.59849720",
//                 "type": null
//             },
//             {
//                 "id": 2392,
//                 "name": "Argyll and Bute",
//                 "state_code": "AGB",
//                 "latitude": "56.40062140",
//                 "longitude": "-5.48074800",
//                 "type": null
//             },
//             {
//                 "id": 2331,
//                 "name": "Armagh City and District Council",
//                 "state_code": "ARM",
//                 "latitude": "54.39325920",
//                 "longitude": "-6.45634010",
//                 "type": null
//             },
//             {
//                 "id": 2324,
//                 "name": "Armagh, Banbridge and Craigavon",
//                 "state_code": "ABC",
//                 "latitude": "54.39325920",
//                 "longitude": "-6.45634010",
//                 "type": null
//             },
//             {
//                 "id": 2378,
//                 "name": "Ascension Island",
//                 "state_code": "SH-AC",
//                 "latitude": "-7.94671660",
//                 "longitude": "-14.35591580",
//                 "type": null
//             },
//             {
//                 "id": 2363,
//                 "name": "Ballymena Borough",
//                 "state_code": "BLA",
//                 "latitude": "54.86426000",
//                 "longitude": "-6.27910740",
//                 "type": null
//             },
//             {
//                 "id": 2361,
//                 "name": "Ballymoney",
//                 "state_code": "BLY",
//                 "latitude": "55.07048880",
//                 "longitude": "-6.51737080",
//                 "type": null
//             },
//             {
//                 "id": 2315,
//                 "name": "Banbridge",
//                 "state_code": "BNB",
//                 "latitude": "54.34872900",
//                 "longitude": "-6.27048030",
//                 "type": null
//             },
//             {
//                 "id": 2499,
//                 "name": "Barnsley",
//                 "state_code": "BNS",
//                 "latitude": "34.29949560",
//                 "longitude": "-84.98458090",
//                 "type": null
//             },
//             {
//                 "id": 2339,
//                 "name": "Bath and North East Somerset",
//                 "state_code": "BAS",
//                 "latitude": "51.32501020",
//                 "longitude": "-2.47662410",
//                 "type": null
//             },
//             {
//                 "id": 2507,
//                 "name": "Bedford",
//                 "state_code": "BDF",
//                 "latitude": "32.84401700",
//                 "longitude": "-97.14306710",
//                 "type": null
//             },
//             {
//                 "id": 2311,
//                 "name": "Belfast district",
//                 "state_code": "BFS",
//                 "latitude": "54.61703660",
//                 "longitude": "-5.95318610",
//                 "type": null
//             },
//             {
//                 "id": 2425,
//                 "name": "Birmingham",
//                 "state_code": "BIR",
//                 "latitude": "33.51858920",
//                 "longitude": "-86.81035670",
//                 "type": null
//             },
//             {
//                 "id": 2329,
//                 "name": "Blackburn with Darwen",
//                 "state_code": "BBD",
//                 "latitude": "53.69575220",
//                 "longitude": "-2.46829850",
//                 "type": null
//             },
//             {
//                 "id": 2451,
//                 "name": "Blackpool",
//                 "state_code": "BPL",
//                 "latitude": "53.81750530",
//                 "longitude": "-3.03567480",
//                 "type": null
//             },
//             {
//                 "id": 2530,
//                 "name": "Blaenau Gwent County Borough",
//                 "state_code": "BGW",
//                 "latitude": "51.78757790",
//                 "longitude": "-3.20439310",
//                 "type": null
//             },
//             {
//                 "id": 2504,
//                 "name": "Bolton",
//                 "state_code": "BOL",
//                 "latitude": "44.37264760",
//                 "longitude": "-72.87876250",
//                 "type": null
//             },
//             {
//                 "id": 2342,
//                 "name": "Bournemouth",
//                 "state_code": "BMH",
//                 "latitude": "50.71916400",
//                 "longitude": "-1.88076900",
//                 "type": null
//             },
//             {
//                 "id": 2470,
//                 "name": "Bracknell Forest",
//                 "state_code": "BRC",
//                 "latitude": "51.41538280",
//                 "longitude": "-0.75364950",
//                 "type": null
//             },
//             {
//                 "id": 2529,
//                 "name": "Bradford",
//                 "state_code": "BRD",
//                 "latitude": "53.79598400",
//                 "longitude": "-1.75939800",
//                 "type": null
//             },
//             {
//                 "id": 2452,
//                 "name": "Bridgend County Borough",
//                 "state_code": "BGE",
//                 "latitude": "51.50831990",
//                 "longitude": "-3.58120750",
//                 "type": null
//             },
//             {
//                 "id": 2395,
//                 "name": "Brighton and Hove",
//                 "state_code": "BNH",
//                 "latitude": "50.82262880",
//                 "longitude": "-0.13704700",
//                 "type": null
//             },
//             {
//                 "id": 2405,
//                 "name": "Buckinghamshire",
//                 "state_code": "BKM",
//                 "latitude": "51.80722040",
//                 "longitude": "-0.81276640",
//                 "type": null
//             },
//             {
//                 "id": 2459,
//                 "name": "Bury",
//                 "state_code": "BUR",
//                 "latitude": "53.59334980",
//                 "longitude": "-2.29660540",
//                 "type": null
//             },
//             {
//                 "id": 2298,
//                 "name": "Caerphilly County Borough",
//                 "state_code": "CAY",
//                 "latitude": "51.66044650",
//                 "longitude": "-3.21787240",
//                 "type": null
//             },
//             {
//                 "id": 2517,
//                 "name": "Calderdale",
//                 "state_code": "CLD",
//                 "latitude": "53.72478450",
//                 "longitude": "-1.86583570",
//                 "type": null
//             },
//             {
//                 "id": 2423,
//                 "name": "Cambridgeshire",
//                 "state_code": "CAM",
//                 "latitude": "52.20529730",
//                 "longitude": "0.12181950",
//                 "type": null
//             },
//             {
//                 "id": 2484,
//                 "name": "Carmarthenshire",
//                 "state_code": "CMN",
//                 "latitude": "51.85723090",
//                 "longitude": "-4.31159590",
//                 "type": null
//             },
//             {
//                 "id": 2439,
//                 "name": "Carrickfergus Borough Council",
//                 "state_code": "CKF",
//                 "latitude": "54.72568430",
//                 "longitude": "-5.80937190",
//                 "type": null
//             },
//             {
//                 "id": 2525,
//                 "name": "Castlereagh",
//                 "state_code": "CSR",
//                 "latitude": "54.57567900",
//                 "longitude": "-5.88840280",
//                 "type": null
//             },
//             {
//                 "id": 2316,
//                 "name": "Causeway Coast and Glens",
//                 "state_code": "CCG",
//                 "latitude": "55.04318300",
//                 "longitude": "-6.67412880",
//                 "type": null
//             },
//             {
//                 "id": 2303,
//                 "name": "Central Bedfordshire",
//                 "state_code": "CBF",
//                 "latitude": "52.00297440",
//                 "longitude": "-0.46513890",
//                 "type": null
//             },
//             {
//                 "id": 2509,
//                 "name": "Ceredigion",
//                 "state_code": "CGN",
//                 "latitude": "52.21914290",
//                 "longitude": "-3.93212560",
//                 "type": null
//             },
//             {
//                 "id": 2444,
//                 "name": "Cheshire East",
//                 "state_code": "CHE",
//                 "latitude": "53.16104460",
//                 "longitude": "-2.21859320",
//                 "type": null
//             },
//             {
//                 "id": 2442,
//                 "name": "Cheshire West and Chester",
//                 "state_code": "CHW",
//                 "latitude": "53.23029740",
//                 "longitude": "-2.71511170",
//                 "type": null
//             },
//             {
//                 "id": 2528,
//                 "name": "City and County of Cardiff",
//                 "state_code": "CRF",
//                 "latitude": "51.48158100",
//                 "longitude": "-3.17909000",
//                 "type": null
//             },
//             {
//                 "id": 2433,
//                 "name": "City and County of Swansea",
//                 "state_code": "SWA",
//                 "latitude": "51.62144000",
//                 "longitude": "-3.94364600",
//                 "type": null
//             },
//             {
//                 "id": 2413,
//                 "name": "City of Bristol",
//                 "state_code": "BST",
//                 "latitude": "41.67352200",
//                 "longitude": "-72.94653750",
//                 "type": null
//             },
//             {
//                 "id": 2485,
//                 "name": "City of Derby",
//                 "state_code": "DER",
//                 "latitude": "37.54837550",
//                 "longitude": "-97.24851910",
//                 "type": null
//             },
//             {
//                 "id": 2475,
//                 "name": "City of Kingston upon Hull",
//                 "state_code": "KHL",
//                 "latitude": "53.76762360",
//                 "longitude": "-0.32741980",
//                 "type": null
//             },
//             {
//                 "id": 2318,
//                 "name": "City of Leicester",
//                 "state_code": "LCE",
//                 "latitude": "52.63687780",
//                 "longitude": "-1.13975920",
//                 "type": null
//             },
//             {
//                 "id": 2424,
//                 "name": "City of London",
//                 "state_code": "LND",
//                 "latitude": "51.51234430",
//                 "longitude": "-0.09098520",
//                 "type": null
//             },
//             {
//                 "id": 2359,
//                 "name": "City of Nottingham",
//                 "state_code": "NGM",
//                 "latitude": "52.95478320",
//                 "longitude": "-1.15810860",
//                 "type": null
//             },
//             {
//                 "id": 2297,
//                 "name": "City of Peterborough",
//                 "state_code": "PTE",
//                 "latitude": "44.30936360",
//                 "longitude": "-78.32015300",
//                 "type": null
//             },
//             {
//                 "id": 2514,
//                 "name": "City of Plymouth",
//                 "state_code": "PLY",
//                 "latitude": "42.37089410",
//                 "longitude": "-83.46971410",
//                 "type": null
//             },
//             {
//                 "id": 2305,
//                 "name": "City of Portsmouth",
//                 "state_code": "POR",
//                 "latitude": "36.83291500",
//                 "longitude": "-76.29755490",
//                 "type": null
//             },
//             {
//                 "id": 2294,
//                 "name": "City of Southampton",
//                 "state_code": "STH",
//                 "latitude": "50.90970040",
//                 "longitude": "-1.40435090",
//                 "type": null
//             },
//             {
//                 "id": 2506,
//                 "name": "City of Stoke-on-Trent",
//                 "state_code": "STE",
//                 "latitude": "53.00266800",
//                 "longitude": "-2.17940400",
//                 "type": null
//             },
//             {
//                 "id": 2372,
//                 "name": "City of Sunderland",
//                 "state_code": "SND",
//                 "latitude": "54.88614890",
//                 "longitude": "-1.47857970",
//                 "type": null
//             },
//             {
//                 "id": 2357,
//                 "name": "City of Westminster",
//                 "state_code": "WSM",
//                 "latitude": "39.57659770",
//                 "longitude": "-76.99721260",
//                 "type": null
//             },
//             {
//                 "id": 2489,
//                 "name": "City of Wolverhampton",
//                 "state_code": "WLV",
//                 "latitude": "52.58891200",
//                 "longitude": "-2.15646300",
//                 "type": null
//             },
//             {
//                 "id": 2426,
//                 "name": "City of York",
//                 "state_code": "YOR",
//                 "latitude": "53.95996510",
//                 "longitude": "-1.08729790",
//                 "type": null
//             },
//             {
//                 "id": 2450,
//                 "name": "Clackmannanshire",
//                 "state_code": "CLK",
//                 "latitude": "56.10753510",
//                 "longitude": "-3.75294090",
//                 "type": null
//             },
//             {
//                 "id": 2461,
//                 "name": "Coleraine Borough Council",
//                 "state_code": "CLR",
//                 "latitude": "55.14515700",
//                 "longitude": "-6.67598140",
//                 "type": null
//             },
//             {
//                 "id": 2352,
//                 "name": "Conwy County Borough",
//                 "state_code": "CWY",
//                 "latitude": "53.29350130",
//                 "longitude": "-3.72651610",
//                 "type": null
//             },
//             {
//                 "id": 2445,
//                 "name": "Cookstown District Council",
//                 "state_code": "CKT",
//                 "latitude": "54.64181580",
//                 "longitude": "-6.74438950",
//                 "type": null
//             },
//             {
//                 "id": 2312,
//                 "name": "Cornwall",
//                 "state_code": "CON",
//                 "latitude": "50.26604710",
//                 "longitude": "-5.05271250",
//                 "type": null
//             },
//             {
//                 "id": 2406,
//                 "name": "County Durham",
//                 "state_code": "DUR",
//                 "latitude": "54.72940990",
//                 "longitude": "-1.88115980",
//                 "type": null
//             },
//             {
//                 "id": 2438,
//                 "name": "Coventry",
//                 "state_code": "COV",
//                 "latitude": "52.40682200",
//                 "longitude": "-1.51969300",
//                 "type": null
//             },
//             {
//                 "id": 2449,
//                 "name": "Craigavon Borough Council",
//                 "state_code": "CGV",
//                 "latitude": "54.39325920",
//                 "longitude": "-6.45634010",
//                 "type": null
//             },
//             {
//                 "id": 2334,
//                 "name": "Cumbria",
//                 "state_code": "CMA",
//                 "latitude": "54.57723230",
//                 "longitude": "-2.79748350",
//                 "type": null
//             },
//             {
//                 "id": 2389,
//                 "name": "Darlington",
//                 "state_code": "DAL",
//                 "latitude": "34.29987620",
//                 "longitude": "-79.87617410",
//                 "type": null
//             },
//             {
//                 "id": 2497,
//                 "name": "Denbighshire",
//                 "state_code": "DEN",
//                 "latitude": "53.18422880",
//                 "longitude": "-3.42249850",
//                 "type": null
//             },
//             {
//                 "id": 2403,
//                 "name": "Derbyshire",
//                 "state_code": "DBY",
//                 "latitude": "53.10467820",
//                 "longitude": "-1.56238850",
//                 "type": null
//             },
//             {
//                 "id": 2446,
//                 "name": "Derry City and Strabane",
//                 "state_code": "DRS",
//                 "latitude": "55.00474430",
//                 "longitude": "-7.32092220",
//                 "type": null
//             },
//             {
//                 "id": 2417,
//                 "name": "Derry City Council",
//                 "state_code": "DRY",
//                 "latitude": "54.96907780",
//                 "longitude": "-7.19583510",
//                 "type": null
//             },
//             {
//                 "id": 2491,
//                 "name": "Devon",
//                 "state_code": "DEV",
//                 "latitude": "50.71555910",
//                 "longitude": "-3.53087500",
//                 "type": null
//             },
//             {
//                 "id": 2364,
//                 "name": "Doncaster",
//                 "state_code": "DNC",
//                 "latitude": "53.52282000",
//                 "longitude": "-1.12846200",
//                 "type": null
//             },
//             {
//                 "id": 2345,
//                 "name": "Dorset",
//                 "state_code": "DOR",
//                 "latitude": "50.74876350",
//                 "longitude": "-2.34447860",
//                 "type": null
//             },
//             {
//                 "id": 2304,
//                 "name": "Down District Council",
//                 "state_code": "DOW",
//                 "latitude": "54.24342870",
//                 "longitude": "-5.95779590",
//                 "type": null
//             },
//             {
//                 "id": 2457,
//                 "name": "Dudley",
//                 "state_code": "DUD",
//                 "latitude": "42.04336610",
//                 "longitude": "-71.92760330",
//                 "type": null
//             },
//             {
//                 "id": 2415,
//                 "name": "Dumfries and Galloway",
//                 "state_code": "DGY",
//                 "latitude": "55.07010730",
//                 "longitude": "-3.60525810",
//                 "type": null
//             },
//             {
//                 "id": 2511,
//                 "name": "Dundee",
//                 "state_code": "DND",
//                 "latitude": "56.46201800",
//                 "longitude": "-2.97072100",
//                 "type": null
//             },
//             {
//                 "id": 2508,
//                 "name": "Dungannon and South Tyrone Borough Council",
//                 "state_code": "DGN",
//                 "latitude": "54.50826840",
//                 "longitude": "-6.76658910",
//                 "type": null
//             },
//             {
//                 "id": 2374,
//                 "name": "East Ayrshire",
//                 "state_code": "EAY",
//                 "latitude": "55.45184960",
//                 "longitude": "-4.26444780",
//                 "type": null
//             },
//             {
//                 "id": 2454,
//                 "name": "East Dunbartonshire",
//                 "state_code": "EDU",
//                 "latitude": "55.97431620",
//                 "longitude": "-4.20229800",
//                 "type": null
//             },
//             {
//                 "id": 2462,
//                 "name": "East Lothian",
//                 "state_code": "ELN",
//                 "latitude": "55.94933830",
//                 "longitude": "-2.77044640",
//                 "type": null
//             },
//             {
//                 "id": 2333,
//                 "name": "East Renfrewshire",
//                 "state_code": "ERW",
//                 "latitude": "55.77047350",
//                 "longitude": "-4.33598210",
//                 "type": null
//             },
//             {
//                 "id": 2370,
//                 "name": "East Riding of Yorkshire",
//                 "state_code": "ERY",
//                 "latitude": "53.84161680",
//                 "longitude": "-0.43441060",
//                 "type": null
//             },
//             {
//                 "id": 2414,
//                 "name": "East Sussex",
//                 "state_code": "ESX",
//                 "latitude": "50.90859550",
//                 "longitude": "0.24941660",
//                 "type": null
//             },
//             {
//                 "id": 2428,
//                 "name": "Edinburgh",
//                 "state_code": "EDH",
//                 "latitude": "55.95325200",
//                 "longitude": "-3.18826700",
//                 "type": null
//             },
//             {
//                 "id": 2336,
//                 "name": "England",
//                 "state_code": "ENG",
//                 "latitude": "52.35551770",
//                 "longitude": "-1.17431970",
//                 "type": null
//             },
//             {
//                 "id": 2410,
//                 "name": "Essex",
//                 "state_code": "ESS",
//                 "latitude": "51.57424470",
//                 "longitude": "0.48567810",
//                 "type": null
//             },
//             {
//                 "id": 2344,
//                 "name": "Falkirk",
//                 "state_code": "FAL",
//                 "latitude": "56.00187750",
//                 "longitude": "-3.78391310",
//                 "type": null
//             },
//             {
//                 "id": 2366,
//                 "name": "Fermanagh and Omagh",
//                 "state_code": "FMO",
//                 "latitude": "54.45135240",
//                 "longitude": "-7.71250180",
//                 "type": null
//             },
//             {
//                 "id": 2531,
//                 "name": "Fermanagh District Council",
//                 "state_code": "FER",
//                 "latitude": "54.34479780",
//                 "longitude": "-7.63842180",
//                 "type": null
//             },
//             {
//                 "id": 2479,
//                 "name": "Fife",
//                 "state_code": "FIF",
//                 "latitude": "56.20820780",
//                 "longitude": "-3.14951750",
//                 "type": null
//             },
//             {
//                 "id": 2437,
//                 "name": "Flintshire",
//                 "state_code": "FLN",
//                 "latitude": "53.16686580",
//                 "longitude": "-3.14189080",
//                 "type": null
//             },
//             {
//                 "id": 2431,
//                 "name": "Gateshead",
//                 "state_code": "GAT",
//                 "latitude": "54.95268000",
//                 "longitude": "-1.60341100",
//                 "type": null
//             },
//             {
//                 "id": 2404,
//                 "name": "Glasgow",
//                 "state_code": "GLG",
//                 "latitude": "55.86423700",
//                 "longitude": "-4.25180600",
//                 "type": null
//             },
//             {
//                 "id": 2373,
//                 "name": "Gloucestershire",
//                 "state_code": "GLS",
//                 "latitude": "51.86421120",
//                 "longitude": "-2.23803350",
//                 "type": null
//             },
//             {
//                 "id": 2379,
//                 "name": "Gwynedd",
//                 "state_code": "GWN",
//                 "latitude": "52.92772660",
//                 "longitude": "-4.13348360",
//                 "type": null
//             },
//             {
//                 "id": 2466,
//                 "name": "Halton",
//                 "state_code": "HAL",
//                 "latitude": "43.53253720",
//                 "longitude": "-79.87448360",
//                 "type": null
//             },
//             {
//                 "id": 2435,
//                 "name": "Hampshire",
//                 "state_code": "HAM",
//                 "latitude": "51.05769480",
//                 "longitude": "-1.30806290",
//                 "type": null
//             },
//             {
//                 "id": 2309,
//                 "name": "Hartlepool",
//                 "state_code": "HPL",
//                 "latitude": "54.69174500",
//                 "longitude": "-1.21292600",
//                 "type": null
//             },
//             {
//                 "id": 2500,
//                 "name": "Herefordshire",
//                 "state_code": "HEF",
//                 "latitude": "52.07651640",
//                 "longitude": "-2.65441820",
//                 "type": null
//             },
//             {
//                 "id": 2369,
//                 "name": "Hertfordshire",
//                 "state_code": "HRT",
//                 "latitude": "51.80978230",
//                 "longitude": "-0.23767440",
//                 "type": null
//             },
//             {
//                 "id": 2383,
//                 "name": "Highland",
//                 "state_code": "HLD",
//                 "latitude": "36.29675080",
//                 "longitude": "-95.83803660",
//                 "type": null
//             },
//             {
//                 "id": 2388,
//                 "name": "Inverclyde",
//                 "state_code": "IVC",
//                 "latitude": "55.93165690",
//                 "longitude": "-4.68001580",
//                 "type": null
//             },
//             {
//                 "id": 2289,
//                 "name": "Isle of Wight",
//                 "state_code": "IOW",
//                 "latitude": "50.69384790",
//                 "longitude": "-1.30473400",
//                 "type": null
//             },
//             {
//                 "id": 2343,
//                 "name": "Isles of Scilly",
//                 "state_code": "IOS",
//                 "latitude": "49.92772610",
//                 "longitude": "-6.32749660",
//                 "type": null
//             },
//             {
//                 "id": 2464,
//                 "name": "Kent",
//                 "state_code": "KEN",
//                 "latitude": "41.15366740",
//                 "longitude": "-81.35788590",
//                 "type": null
//             },
//             {
//                 "id": 2371,
//                 "name": "Kirklees",
//                 "state_code": "KIR",
//                 "latitude": "53.59334320",
//                 "longitude": "-1.80095090",
//                 "type": null
//             },
//             {
//                 "id": 2330,
//                 "name": "Knowsley",
//                 "state_code": "KWL",
//                 "latitude": "53.45459400",
//                 "longitude": "-2.85290700",
//                 "type": null
//             },
//             {
//                 "id": 2495,
//                 "name": "Lancashire",
//                 "state_code": "LAN",
//                 "latitude": "53.76322540",
//                 "longitude": "-2.70440520",
//                 "type": null
//             },
//             {
//                 "id": 2515,
//                 "name": "Larne Borough Council",
//                 "state_code": "LRN",
//                 "latitude": "54.85780030",
//                 "longitude": "-5.82362240",
//                 "type": null
//             },
//             {
//                 "id": 2503,
//                 "name": "Leeds",
//                 "state_code": "LDS",
//                 "latitude": "53.80075540",
//                 "longitude": "-1.54907740",
//                 "type": null
//             },
//             {
//                 "id": 2516,
//                 "name": "Leicestershire",
//                 "state_code": "LEC",
//                 "latitude": "52.77257100",
//                 "longitude": "-1.20521260",
//                 "type": null
//             },
//             {
//                 "id": 2382,
//                 "name": "Limavady Borough Council",
//                 "state_code": "LMV",
//                 "latitude": "55.05168200",
//                 "longitude": "-6.94919440",
//                 "type": null
//             },
//             {
//                 "id": 2355,
//                 "name": "Lincolnshire",
//                 "state_code": "LIN",
//                 "latitude": "52.94518890",
//                 "longitude": "-0.16012460",
//                 "type": null
//             },
//             {
//                 "id": 2460,
//                 "name": "Lisburn and Castlereagh",
//                 "state_code": "LBC",
//                 "latitude": "54.49815840",
//                 "longitude": "-6.13067910",
//                 "type": null
//             },
//             {
//                 "id": 2494,
//                 "name": "Lisburn City Council",
//                 "state_code": "LSB",
//                 "latitude": "54.49815840",
//                 "longitude": "-6.13067910",
//                 "type": null
//             },
//             {
//                 "id": 2340,
//                 "name": "Liverpool",
//                 "state_code": "LIV",
//                 "latitude": "32.65649810",
//                 "longitude": "-115.47632410",
//                 "type": null
//             },
//             {
//                 "id": 2356,
//                 "name": "London Borough of Barking and Dagenham",
//                 "state_code": "BDG",
//                 "latitude": "51.55406660",
//                 "longitude": "0.13401700",
//                 "type": null
//             },
//             {
//                 "id": 2520,
//                 "name": "London Borough of Barnet",
//                 "state_code": "BNE",
//                 "latitude": "51.60496730",
//                 "longitude": "-0.20762950",
//                 "type": null
//             },
//             {
//                 "id": 2307,
//                 "name": "London Borough of Bexley",
//                 "state_code": "BEX",
//                 "latitude": "51.45190210",
//                 "longitude": "0.11717860",
//                 "type": null
//             },
//             {
//                 "id": 2291,
//                 "name": "London Borough of Brent",
//                 "state_code": "BEN",
//                 "latitude": "51.56728080",
//                 "longitude": "-0.27105680",
//                 "type": null
//             },
//             {
//                 "id": 2490,
//                 "name": "London Borough of Bromley",
//                 "state_code": "BRY",
//                 "latitude": "51.36797050",
//                 "longitude": "0.07006200",
//                 "type": null
//             },
//             {
//                 "id": 2349,
//                 "name": "London Borough of Camden",
//                 "state_code": "CMD",
//                 "latitude": "51.54547360",
//                 "longitude": "-0.16279020",
//                 "type": null
//             },
//             {
//                 "id": 2512,
//                 "name": "London Borough of Croydon",
//                 "state_code": "CRY",
//                 "latitude": "51.38274460",
//                 "longitude": "-0.09851630",
//                 "type": null
//             },
//             {
//                 "id": 2532,
//                 "name": "London Borough of Ealing",
//                 "state_code": "EAL",
//                 "latitude": "51.52503660",
//                 "longitude": "-0.34139650",
//                 "type": null
//             },
//             {
//                 "id": 2476,
//                 "name": "London Borough of Enfield",
//                 "state_code": "ENF",
//                 "latitude": "51.66229090",
//                 "longitude": "-0.11806510",
//                 "type": null
//             },
//             {
//                 "id": 2411,
//                 "name": "London Borough of Hackney",
//                 "state_code": "HCK",
//                 "latitude": "51.57344500",
//                 "longitude": "-0.07243760",
//                 "type": null
//             },
//             {
//                 "id": 2448,
//                 "name": "London Borough of Hammersmith and Fulham",
//                 "state_code": "HMF",
//                 "latitude": "51.49901560",
//                 "longitude": "-0.22915000",
//                 "type": null
//             },
//             {
//                 "id": 2306,
//                 "name": "London Borough of Haringey",
//                 "state_code": "HRY",
//                 "latitude": "51.59061130",
//                 "longitude": "-0.11097090",
//                 "type": null
//             },
//             {
//                 "id": 2385,
//                 "name": "London Borough of Harrow",
//                 "state_code": "HRW",
//                 "latitude": "51.58816270",
//                 "longitude": "-0.34228510",
//                 "type": null
//             },
//             {
//                 "id": 2347,
//                 "name": "London Borough of Havering",
//                 "state_code": "HAV",
//                 "latitude": "51.57792400",
//                 "longitude": "0.21208290",
//                 "type": null
//             },
//             {
//                 "id": 2376,
//                 "name": "London Borough of Hillingdon",
//                 "state_code": "HIL",
//                 "latitude": "51.53518320",
//                 "longitude": "-0.44813780",
//                 "type": null
//             },
//             {
//                 "id": 2380,
//                 "name": "London Borough of Hounslow",
//                 "state_code": "HNS",
//                 "latitude": "51.48283580",
//                 "longitude": "-0.38820620",
//                 "type": null
//             },
//             {
//                 "id": 2319,
//                 "name": "London Borough of Islington",
//                 "state_code": "ISL",
//                 "latitude": "51.54650630",
//                 "longitude": "-0.10580580",
//                 "type": null
//             },
//             {
//                 "id": 2396,
//                 "name": "London Borough of Lambeth",
//                 "state_code": "LBH",
//                 "latitude": "51.45714770",
//                 "longitude": "-0.12306810",
//                 "type": null
//             },
//             {
//                 "id": 2358,
//                 "name": "London Borough of Lewisham",
//                 "state_code": "LEW",
//                 "latitude": "51.44145790",
//                 "longitude": "-0.01170060",
//                 "type": null
//             },
//             {
//                 "id": 2483,
//                 "name": "London Borough of Merton",
//                 "state_code": "MRT",
//                 "latitude": "51.40977420",
//                 "longitude": "-0.21080840",
//                 "type": null
//             },
//             {
//                 "id": 2418,
//                 "name": "London Borough of Newham",
//                 "state_code": "NWM",
//                 "latitude": "51.52551620",
//                 "longitude": "0.03521630",
//                 "type": null
//             },
//             {
//                 "id": 2397,
//                 "name": "London Borough of Redbridge",
//                 "state_code": "RDB",
//                 "latitude": "51.58861210",
//                 "longitude": "0.08239820",
//                 "type": null
//             },
//             {
//                 "id": 2501,
//                 "name": "London Borough of Richmond upon Thames",
//                 "state_code": "RIC",
//                 "latitude": "51.46130540",
//                 "longitude": "-0.30377090",
//                 "type": null
//             },
//             {
//                 "id": 2432,
//                 "name": "London Borough of Southwark",
//                 "state_code": "SWK",
//                 "latitude": "51.48805720",
//                 "longitude": "-0.07628380",
//                 "type": null
//             },
//             {
//                 "id": 2313,
//                 "name": "London Borough of Sutton",
//                 "state_code": "STN",
//                 "latitude": "51.35737620",
//                 "longitude": "-0.17527960",
//                 "type": null
//             },
//             {
//                 "id": 2390,
//                 "name": "London Borough of Tower Hamlets",
//                 "state_code": "TWH",
//                 "latitude": "51.52026070",
//                 "longitude": "-0.02933960",
//                 "type": null
//             },
//             {
//                 "id": 2326,
//                 "name": "London Borough of Waltham Forest",
//                 "state_code": "WFT",
//                 "latitude": "51.58863830",
//                 "longitude": "-0.01176250",
//                 "type": null
//             },
//             {
//                 "id": 2434,
//                 "name": "London Borough of Wandsworth",
//                 "state_code": "WND",
//                 "latitude": "51.45682740",
//                 "longitude": "-0.18966380",
//                 "type": null
//             },
//             {
//                 "id": 2322,
//                 "name": "Magherafelt District Council",
//                 "state_code": "MFT",
//                 "latitude": "54.75532790",
//                 "longitude": "-6.60774870",
//                 "type": null
//             },
//             {
//                 "id": 2398,
//                 "name": "Manchester",
//                 "state_code": "MAN",
//                 "latitude": "53.48075930",
//                 "longitude": "-2.24263050",
//                 "type": null
//             },
//             {
//                 "id": 2381,
//                 "name": "Medway",
//                 "state_code": "MDW",
//                 "latitude": "42.14176410",
//                 "longitude": "-71.39672560",
//                 "type": null
//             },
//             {
//                 "id": 2328,
//                 "name": "Merthyr Tydfil County Borough",
//                 "state_code": "MTY",
//                 "latitude": "51.74674740",
//                 "longitude": "-3.38132750",
//                 "type": null
//             },
//             {
//                 "id": 2320,
//                 "name": "Metropolitan Borough of Wigan",
//                 "state_code": "WGN",
//                 "latitude": "53.51348120",
//                 "longitude": "-2.61069990",
//                 "type": null
//             },
//             {
//                 "id": 2429,
//                 "name": "Mid and East Antrim",
//                 "state_code": "MEA",
//                 "latitude": "54.93993410",
//                 "longitude": "-6.11374230",
//                 "type": null
//             },
//             {
//                 "id": 2399,
//                 "name": "Mid Ulster",
//                 "state_code": "MUL",
//                 "latitude": "54.64113010",
//                 "longitude": "-6.75225490",
//                 "type": null
//             },
//             {
//                 "id": 2332,
//                 "name": "Middlesbrough",
//                 "state_code": "MDB",
//                 "latitude": "54.57422700",
//                 "longitude": "-1.23495600",
//                 "type": null
//             },
//             {
//                 "id": 2519,
//                 "name": "Midlothian",
//                 "state_code": "MLN",
//                 "latitude": "32.47533500",
//                 "longitude": "-97.01031810",
//                 "type": null
//             },
//             {
//                 "id": 2416,
//                 "name": "Milton Keynes",
//                 "state_code": "MIK",
//                 "latitude": "52.08520380",
//                 "longitude": "-0.73331330",
//                 "type": null
//             },
//             {
//                 "id": 2402,
//                 "name": "Monmouthshire",
//                 "state_code": "MON",
//                 "latitude": "51.81161000",
//                 "longitude": "-2.71634170",
//                 "type": null
//             },
//             {
//                 "id": 2360,
//                 "name": "Moray",
//                 "state_code": "MRY",
//                 "latitude": "57.64984760",
//                 "longitude": "-3.31680390",
//                 "type": null
//             },
//             {
//                 "id": 2348,
//                 "name": "Moyle District Council",
//                 "state_code": "MYL",
//                 "latitude": "55.20473270",
//                 "longitude": "-6.25317400",
//                 "type": null
//             },
//             {
//                 "id": 2351,
//                 "name": "Neath Port Talbot County Borough",
//                 "state_code": "NTL",
//                 "latitude": "51.59785190",
//                 "longitude": "-3.78396680",
//                 "type": null
//             },
//             {
//                 "id": 2458,
//                 "name": "Newcastle upon Tyne",
//                 "state_code": "NET",
//                 "latitude": "54.97825200",
//                 "longitude": "-1.61778000",
//                 "type": null
//             },
//             {
//                 "id": 2524,
//                 "name": "Newport",
//                 "state_code": "NWP",
//                 "latitude": "37.52782340",
//                 "longitude": "-94.10438760",
//                 "type": null
//             },
//             {
//                 "id": 2350,
//                 "name": "Newry and Mourne District Council",
//                 "state_code": "NYM",
//                 "latitude": "54.17425050",
//                 "longitude": "-6.33919920",
//                 "type": null
//             },
//             {
//                 "id": 2534,
//                 "name": "Newry, Mourne and Down",
//                 "state_code": "NMD",
//                 "latitude": "54.24342870",
//                 "longitude": "-5.95779590",
//                 "type": null
//             },
//             {
//                 "id": 2317,
//                 "name": "Newtownabbey Borough Council",
//                 "state_code": "NTA",
//                 "latitude": "54.67924220",
//                 "longitude": "-5.95911020",
//                 "type": null
//             },
//             {
//                 "id": 2473,
//                 "name": "Norfolk",
//                 "state_code": "NFK",
//                 "latitude": "36.85076890",
//                 "longitude": "-76.28587260",
//                 "type": null
//             },
//             {
//                 "id": 2535,
//                 "name": "North Ayrshire",
//                 "state_code": "NAY",
//                 "latitude": "55.64167310",
//                 "longitude": "-4.75946000",
//                 "type": null
//             },
//             {
//                 "id": 2513,
//                 "name": "North Down Borough Council",
//                 "state_code": "NDN",
//                 "latitude": "54.65362970",
//                 "longitude": "-5.67249250",
//                 "type": null
//             },
//             {
//                 "id": 2384,
//                 "name": "North East Lincolnshire",
//                 "state_code": "NEL",
//                 "latitude": "53.56682010",
//                 "longitude": "-0.08150660",
//                 "type": null
//             },
//             {
//                 "id": 2487,
//                 "name": "North Lanarkshire",
//                 "state_code": "NLK",
//                 "latitude": "55.86624320",
//                 "longitude": "-3.96131440",
//                 "type": null
//             },
//             {
//                 "id": 2453,
//                 "name": "North Lincolnshire",
//                 "state_code": "NLN",
//                 "latitude": "53.60555920",
//                 "longitude": "-0.55965820",
//                 "type": null
//             },
//             {
//                 "id": 2430,
//                 "name": "North Somerset",
//                 "state_code": "NSM",
//                 "latitude": "51.38790280",
//                 "longitude": "-2.77810910",
//                 "type": null
//             },
//             {
//                 "id": 2521,
//                 "name": "North Tyneside",
//                 "state_code": "NTY",
//                 "latitude": "55.01823990",
//                 "longitude": "-1.48584360",
//                 "type": null
//             },
//             {
//                 "id": 2522,
//                 "name": "North Yorkshire",
//                 "state_code": "NYK",
//                 "latitude": "53.99150280",
//                 "longitude": "-1.54120150",
//                 "type": null
//             },
//             {
//                 "id": 2480,
//                 "name": "Northamptonshire",
//                 "state_code": "NTH",
//                 "latitude": "52.27299440",
//                 "longitude": "-0.87555150",
//                 "type": null
//             },
//             {
//                 "id": 2337,
//                 "name": "Northern Ireland",
//                 "state_code": "NIR",
//                 "latitude": "54.78771490",
//                 "longitude": "-6.49231450",
//                 "type": null
//             },
//             {
//                 "id": 2365,
//                 "name": "Northumberland",
//                 "state_code": "NBL",
//                 "latitude": "55.20825420",
//                 "longitude": "-2.07841380",
//                 "type": null
//             },
//             {
//                 "id": 2456,
//                 "name": "Nottinghamshire",
//                 "state_code": "NTT",
//                 "latitude": "53.10031900",
//                 "longitude": "-0.99363060",
//                 "type": null
//             },
//             {
//                 "id": 2477,
//                 "name": "Oldham",
//                 "state_code": "OLD",
//                 "latitude": "42.20405980",
//                 "longitude": "-71.20481190",
//                 "type": null
//             },
//             {
//                 "id": 2314,
//                 "name": "Omagh District Council",
//                 "state_code": "OMH",
//                 "latitude": "54.45135240",
//                 "longitude": "-7.71250180",
//                 "type": null
//             },
//             {
//                 "id": 2474,
//                 "name": "Orkney Islands",
//                 "state_code": "ORK",
//                 "latitude": "58.98094010",
//                 "longitude": "-2.96052060",
//                 "type": null
//             },
//             {
//                 "id": 2353,
//                 "name": "Outer Hebrides",
//                 "state_code": "ELS",
//                 "latitude": "57.75989180",
//                 "longitude": "-7.01940340",
//                 "type": null
//             },
//             {
//                 "id": 2321,
//                 "name": "Oxfordshire",
//                 "state_code": "OXF",
//                 "latitude": "51.76120560",
//                 "longitude": "-1.24646740",
//                 "type": null
//             },
//             {
//                 "id": 2486,
//                 "name": "Pembrokeshire",
//                 "state_code": "PEM",
//                 "latitude": "51.67407800",
//                 "longitude": "-4.90887850",
//                 "type": null
//             },
//             {
//                 "id": 2325,
//                 "name": "Perth and Kinross",
//                 "state_code": "PKN",
//                 "latitude": "56.39538170",
//                 "longitude": "-3.42835470",
//                 "type": null
//             },
//             {
//                 "id": 2302,
//                 "name": "Poole",
//                 "state_code": "POL",
//                 "latitude": "50.71505000",
//                 "longitude": "-1.98724800",
//                 "type": null
//             },
//             {
//                 "id": 2441,
//                 "name": "Powys",
//                 "state_code": "POW",
//                 "latitude": "52.64642490",
//                 "longitude": "-3.32609040",
//                 "type": null
//             },
//             {
//                 "id": 2455,
//                 "name": "Reading",
//                 "state_code": "RDG",
//                 "latitude": "36.14866590",
//                 "longitude": "-95.98400120",
//                 "type": null
//             },
//             {
//                 "id": 2527,
//                 "name": "Redcar and Cleveland",
//                 "state_code": "RCC",
//                 "latitude": "54.59713440",
//                 "longitude": "-1.07759970",
//                 "type": null
//             },
//             {
//                 "id": 2443,
//                 "name": "Renfrewshire",
//                 "state_code": "RFW",
//                 "latitude": "55.84665400",
//                 "longitude": "-4.53312590",
//                 "type": null
//             },
//             {
//                 "id": 2301,
//                 "name": "Rhondda Cynon Taf",
//                 "state_code": "RCT",
//                 "latitude": "51.64902070",
//                 "longitude": "-3.42886920",
//                 "type": null
//             },
//             {
//                 "id": 2327,
//                 "name": "Rochdale",
//                 "state_code": "RCH",
//                 "latitude": "53.60971360",
//                 "longitude": "-2.15610000",
//                 "type": null
//             },
//             {
//                 "id": 2308,
//                 "name": "Rotherham",
//                 "state_code": "ROT",
//                 "latitude": "53.43260350",
//                 "longitude": "-1.36350090",
//                 "type": null
//             },
//             {
//                 "id": 2492,
//                 "name": "Royal Borough of Greenwich",
//                 "state_code": "GRE",
//                 "latitude": "51.48346270",
//                 "longitude": "0.05862020",
//                 "type": null
//             },
//             {
//                 "id": 2368,
//                 "name": "Royal Borough of Kensington and Chelsea",
//                 "state_code": "KEC",
//                 "latitude": "51.49908050",
//                 "longitude": "-0.19382530",
//                 "type": null
//             },
//             {
//                 "id": 2481,
//                 "name": "Royal Borough of Kingston upon Thames",
//                 "state_code": "KTT",
//                 "latitude": "51.37811700",
//                 "longitude": "-0.29270900",
//                 "type": null
//             },
//             {
//                 "id": 2472,
//                 "name": "Rutland",
//                 "state_code": "RUT",
//                 "latitude": "43.61062370",
//                 "longitude": "-72.97260650",
//                 "type": null
//             },
//             {
//                 "id": 2502,
//                 "name": "Saint Helena",
//                 "state_code": "SH-HL",
//                 "latitude": "-15.96501040",
//                 "longitude": "-5.70892410",
//                 "type": null
//             },
//             {
//                 "id": 2493,
//                 "name": "Salford",
//                 "state_code": "SLF",
//                 "latitude": "53.48752350",
//                 "longitude": "-2.29012640",
//                 "type": null
//             },
//             {
//                 "id": 2341,
//                 "name": "Sandwell",
//                 "state_code": "SAW",
//                 "latitude": "52.53616740",
//                 "longitude": "-2.01079300",
//                 "type": null
//             },
//             {
//                 "id": 2335,
//                 "name": "Scotland",
//                 "state_code": "SCT",
//                 "latitude": "56.49067120",
//                 "longitude": "-4.20264580",
//                 "type": null
//             },
//             {
//                 "id": 2346,
//                 "name": "Scottish Borders",
//                 "state_code": "SCB",
//                 "latitude": "55.54856970",
//                 "longitude": "-2.78613880",
//                 "type": null
//             },
//             {
//                 "id": 2518,
//                 "name": "Sefton",
//                 "state_code": "SFT",
//                 "latitude": "53.50344490",
//                 "longitude": "-2.97035900",
//                 "type": null
//             },
//             {
//                 "id": 2295,
//                 "name": "Sheffield",
//                 "state_code": "SHF",
//                 "latitude": "36.09507430",
//                 "longitude": "-80.27884660",
//                 "type": null
//             },
//             {
//                 "id": 2300,
//                 "name": "Shetland Islands",
//                 "state_code": "ZET",
//                 "latitude": "60.52965070",
//                 "longitude": "-1.26594090",
//                 "type": null
//             },
//             {
//                 "id": 2407,
//                 "name": "Shropshire",
//                 "state_code": "SHR",
//                 "latitude": "52.70636570",
//                 "longitude": "-2.74178490",
//                 "type": null
//             },
//             {
//                 "id": 2427,
//                 "name": "Slough",
//                 "state_code": "SLG",
//                 "latitude": "51.51053840",
//                 "longitude": "-0.59504060",
//                 "type": null
//             },
//             {
//                 "id": 2469,
//                 "name": "Solihull",
//                 "state_code": "SOL",
//                 "latitude": "52.41181100",
//                 "longitude": "-1.77761000",
//                 "type": null
//             },
//             {
//                 "id": 2386,
//                 "name": "Somerset",
//                 "state_code": "SOM",
//                 "latitude": "51.10509700",
//                 "longitude": "-2.92623070",
//                 "type": null
//             },
//             {
//                 "id": 2377,
//                 "name": "South Ayrshire",
//                 "state_code": "SAY",
//                 "latitude": "55.45889880",
//                 "longitude": "-4.62919940",
//                 "type": null
//             },
//             {
//                 "id": 2400,
//                 "name": "South Gloucestershire",
//                 "state_code": "SGC",
//                 "latitude": "51.52643610",
//                 "longitude": "-2.47284870",
//                 "type": null
//             },
//             {
//                 "id": 2362,
//                 "name": "South Lanarkshire",
//                 "state_code": "SLK",
//                 "latitude": "55.67359090",
//                 "longitude": "-3.78196610",
//                 "type": null
//             },
//             {
//                 "id": 2409,
//                 "name": "South Tyneside",
//                 "state_code": "STY",
//                 "latitude": "54.96366930",
//                 "longitude": "-1.44186340",
//                 "type": null
//             },
//             {
//                 "id": 2323,
//                 "name": "Southend-on-Sea",
//                 "state_code": "SOS",
//                 "latitude": "51.54592690",
//                 "longitude": "0.70771230",
//                 "type": null
//             },
//             {
//                 "id": 2290,
//                 "name": "St Helens",
//                 "state_code": "SHN",
//                 "latitude": "45.85896100",
//                 "longitude": "-122.82123560",
//                 "type": null
//             },
//             {
//                 "id": 2447,
//                 "name": "Staffordshire",
//                 "state_code": "STS",
//                 "latitude": "52.87927450",
//                 "longitude": "-2.05718680",
//                 "type": null
//             },
//             {
//                 "id": 2488,
//                 "name": "Stirling",
//                 "state_code": "STG",
//                 "latitude": "56.11652270",
//                 "longitude": "-3.93690290",
//                 "type": null
//             },
//             {
//                 "id": 2394,
//                 "name": "Stockport",
//                 "state_code": "SKP",
//                 "latitude": "53.41063160",
//                 "longitude": "-2.15753320",
//                 "type": null
//             },
//             {
//                 "id": 2421,
//                 "name": "Stockton-on-Tees",
//                 "state_code": "STT",
//                 "latitude": "54.57045510",
//                 "longitude": "-1.32898210",
//                 "type": null
//             },
//             {
//                 "id": 2393,
//                 "name": "Strabane District Council",
//                 "state_code": "STB",
//                 "latitude": "54.82738650",
//                 "longitude": "-7.46331030",
//                 "type": null
//             },
//             {
//                 "id": 2467,
//                 "name": "Suffolk",
//                 "state_code": "SFK",
//                 "latitude": "52.18724720",
//                 "longitude": "0.97078010",
//                 "type": null
//             },
//             {
//                 "id": 2526,
//                 "name": "Surrey",
//                 "state_code": "SRY",
//                 "latitude": "51.31475930",
//                 "longitude": "-0.55995010",
//                 "type": null
//             },
//             {
//                 "id": 2422,
//                 "name": "Swindon",
//                 "state_code": "SWD",
//                 "latitude": "51.55577390",
//                 "longitude": "-1.77971760",
//                 "type": null
//             },
//             {
//                 "id": 2367,
//                 "name": "Tameside",
//                 "state_code": "TAM",
//                 "latitude": "53.48058280",
//                 "longitude": "-2.08098910",
//                 "type": null
//             },
//             {
//                 "id": 2310,
//                 "name": "Telford and Wrekin",
//                 "state_code": "TFW",
//                 "latitude": "52.74099160",
//                 "longitude": "-2.48685860",
//                 "type": null
//             },
//             {
//                 "id": 2468,
//                 "name": "Thurrock",
//                 "state_code": "THR",
//                 "latitude": "51.49345570",
//                 "longitude": "0.35291970",
//                 "type": null
//             },
//             {
//                 "id": 2478,
//                 "name": "Torbay",
//                 "state_code": "TOB",
//                 "latitude": "50.43923290",
//                 "longitude": "-3.53698990",
//                 "type": null
//             },
//             {
//                 "id": 2496,
//                 "name": "Torfaen",
//                 "state_code": "TOF",
//                 "latitude": "51.70022530",
//                 "longitude": "-3.04460150",
//                 "type": null
//             },
//             {
//                 "id": 2293,
//                 "name": "Trafford",
//                 "state_code": "TRF",
//                 "latitude": "40.38562460",
//                 "longitude": "-79.75893470",
//                 "type": null
//             },
//             {
//                 "id": 2375,
//                 "name": "United Kingdom",
//                 "state_code": "UKM",
//                 "latitude": "55.37805100",
//                 "longitude": "-3.43597300",
//                 "type": null
//             },
//             {
//                 "id": 2299,
//                 "name": "Vale of Glamorgan",
//                 "state_code": "VGL",
//                 "latitude": "51.40959580",
//                 "longitude": "-3.48481670",
//                 "type": null
//             },
//             {
//                 "id": 2465,
//                 "name": "Wakefield",
//                 "state_code": "WKF",
//                 "latitude": "42.50393950",
//                 "longitude": "-71.07233910",
//                 "type": null
//             },
//             {
//                 "id": 2338,
//                 "name": "Wales",
//                 "state_code": "WLS",
//                 "latitude": "52.13066070",
//                 "longitude": "-3.78371170",
//                 "type": null
//             },
//             {
//                 "id": 2292,
//                 "name": "Walsall",
//                 "state_code": "WLL",
//                 "latitude": "52.58621400",
//                 "longitude": "-1.98291900",
//                 "type": null
//             },
//             {
//                 "id": 2420,
//                 "name": "Warrington",
//                 "state_code": "WRT",
//                 "latitude": "40.24927410",
//                 "longitude": "-75.13406040",
//                 "type": null
//             },
//             {
//                 "id": 2505,
//                 "name": "Warwickshire",
//                 "state_code": "WAR",
//                 "latitude": "52.26713530",
//                 "longitude": "-1.46752160",
//                 "type": null
//             },
//             {
//                 "id": 2471,
//                 "name": "West Berkshire",
//                 "state_code": "WBK",
//                 "latitude": "51.43082550",
//                 "longitude": "-1.14449270",
//                 "type": null
//             },
//             {
//                 "id": 2440,
//                 "name": "West Dunbartonshire",
//                 "state_code": "WDU",
//                 "latitude": "55.94509250",
//                 "longitude": "-4.56462590",
//                 "type": null
//             },
//             {
//                 "id": 2354,
//                 "name": "West Lothian",
//                 "state_code": "WLN",
//                 "latitude": "55.90701980",
//                 "longitude": "-3.55171670",
//                 "type": null
//             },
//             {
//                 "id": 2296,
//                 "name": "West Sussex",
//                 "state_code": "WSX",
//                 "latitude": "50.92801430",
//                 "longitude": "-0.46170750",
//                 "type": null
//             },
//             {
//                 "id": 2391,
//                 "name": "Wiltshire",
//                 "state_code": "WIL",
//                 "latitude": "51.34919960",
//                 "longitude": "-1.99271050",
//                 "type": null
//             },
//             {
//                 "id": 2482,
//                 "name": "Windsor and Maidenhead",
//                 "state_code": "WNM",
//                 "latitude": "51.47997120",
//                 "longitude": "-0.62425650",
//                 "type": null
//             },
//             {
//                 "id": 2408,
//                 "name": "Wirral",
//                 "state_code": "WRL",
//                 "latitude": "53.37271810",
//                 "longitude": "-3.07375400",
//                 "type": null
//             },
//             {
//                 "id": 2419,
//                 "name": "Wokingham",
//                 "state_code": "WOK",
//                 "latitude": "51.41045700",
//                 "longitude": "-0.83386100",
//                 "type": null
//             },
//             {
//                 "id": 2510,
//                 "name": "Worcestershire",
//                 "state_code": "WOR",
//                 "latitude": "52.25452250",
//                 "longitude": "-2.26683820",
//                 "type": null
//             },
//             {
//                 "id": 2436,
//                 "name": "Wrexham County Borough",
//                 "state_code": "WRX",
//                 "latitude": "53.03013780",
//                 "longitude": "-3.02614870",
//                 "type": null
//             }
//         ]
//     },
//     {
//         "name": "United States",
//         "iso3": "USA",
//         "iso2": "US",
//         "numeric_code": "840",
//         "phone_code": "1",
//         "capital": "Washington",
//         "currency": "USD",
//         "currency_name": "United States dollar",
//         "currency_symbol": "$",
//         "tld": ".us",
//         "native": "United States",
//         "region": "Americas",
//         "subregion": "Northern America",
//         "timezones": [
//             {
//                 "zoneName": "America\/Adak",
//                 "gmtOffset": -36000,
//                 "gmtOffsetName": "UTC-10:00",
//                 "abbreviation": "HST",
//                 "tzName": "Hawaii–Aleutian Standard Time"
//             },
//             {
//                 "zoneName": "America\/Anchorage",
//                 "gmtOffset": -32400,
//                 "gmtOffsetName": "UTC-09:00",
//                 "abbreviation": "AKST",
//                 "tzName": "Alaska Standard Time"
//             },
//             {
//                 "zoneName": "America\/Boise",
//                 "gmtOffset": -25200,
//                 "gmtOffsetName": "UTC-07:00",
//                 "abbreviation": "MST",
//                 "tzName": "Mountain Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Chicago",
//                 "gmtOffset": -21600,
//                 "gmtOffsetName": "UTC-06:00",
//                 "abbreviation": "CST",
//                 "tzName": "Central Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Denver",
//                 "gmtOffset": -25200,
//                 "gmtOffsetName": "UTC-07:00",
//                 "abbreviation": "MST",
//                 "tzName": "Mountain Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Detroit",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Indiana\/Indianapolis",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Indiana\/Knox",
//                 "gmtOffset": -21600,
//                 "gmtOffsetName": "UTC-06:00",
//                 "abbreviation": "CST",
//                 "tzName": "Central Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Indiana\/Marengo",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Indiana\/Petersburg",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Indiana\/Tell_City",
//                 "gmtOffset": -21600,
//                 "gmtOffsetName": "UTC-06:00",
//                 "abbreviation": "CST",
//                 "tzName": "Central Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Indiana\/Vevay",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Indiana\/Vincennes",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Indiana\/Winamac",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Juneau",
//                 "gmtOffset": -32400,
//                 "gmtOffsetName": "UTC-09:00",
//                 "abbreviation": "AKST",
//                 "tzName": "Alaska Standard Time"
//             },
//             {
//                 "zoneName": "America\/Kentucky\/Louisville",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Kentucky\/Monticello",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Los_Angeles",
//                 "gmtOffset": -28800,
//                 "gmtOffsetName": "UTC-08:00",
//                 "abbreviation": "PST",
//                 "tzName": "Pacific Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Menominee",
//                 "gmtOffset": -21600,
//                 "gmtOffsetName": "UTC-06:00",
//                 "abbreviation": "CST",
//                 "tzName": "Central Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Metlakatla",
//                 "gmtOffset": -32400,
//                 "gmtOffsetName": "UTC-09:00",
//                 "abbreviation": "AKST",
//                 "tzName": "Alaska Standard Time"
//             },
//             {
//                 "zoneName": "America\/New_York",
//                 "gmtOffset": -18000,
//                 "gmtOffsetName": "UTC-05:00",
//                 "abbreviation": "EST",
//                 "tzName": "Eastern Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Nome",
//                 "gmtOffset": -32400,
//                 "gmtOffsetName": "UTC-09:00",
//                 "abbreviation": "AKST",
//                 "tzName": "Alaska Standard Time"
//             },
//             {
//                 "zoneName": "America\/North_Dakota\/Beulah",
//                 "gmtOffset": -21600,
//                 "gmtOffsetName": "UTC-06:00",
//                 "abbreviation": "CST",
//                 "tzName": "Central Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/North_Dakota\/Center",
//                 "gmtOffset": -21600,
//                 "gmtOffsetName": "UTC-06:00",
//                 "abbreviation": "CST",
//                 "tzName": "Central Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/North_Dakota\/New_Salem",
//                 "gmtOffset": -21600,
//                 "gmtOffsetName": "UTC-06:00",
//                 "abbreviation": "CST",
//                 "tzName": "Central Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Phoenix",
//                 "gmtOffset": -25200,
//                 "gmtOffsetName": "UTC-07:00",
//                 "abbreviation": "MST",
//                 "tzName": "Mountain Standard Time (North America"
//             },
//             {
//                 "zoneName": "America\/Sitka",
//                 "gmtOffset": -32400,
//                 "gmtOffsetName": "UTC-09:00",
//                 "abbreviation": "AKST",
//                 "tzName": "Alaska Standard Time"
//             },
//             {
//                 "zoneName": "America\/Yakutat",
//                 "gmtOffset": -32400,
//                 "gmtOffsetName": "UTC-09:00",
//                 "abbreviation": "AKST",
//                 "tzName": "Alaska Standard Time"
//             },
//             {
//                 "zoneName": "Pacific\/Honolulu",
//                 "gmtOffset": -36000,
//                 "gmtOffsetName": "UTC-10:00",
//                 "abbreviation": "HST",
//                 "tzName": "Hawaii–Aleutian Standard Time"
//             }
//         ],
//         "translations": {
//             "kr": "미국",
//             "pt-BR": "Estados Unidos",
//             "pt": "Estados Unidos",
//             "nl": "Verenigde Staten",
//             "hr": "Sjedinjene Američke Države",
//             "fa": "ایالات متحده آمریکا",
//             "de": "Vereinigte Staaten von Amerika",
//             "es": "Estados Unidos",
//             "fr": "États-Unis",
//             "ja": "アメリカ合衆国",
//             "it": "Stati Uniti D'America",
//             "cn": "美国",
//             "tr": "Amerika"
//         },
//         "latitude": "38.00000000",
//         "longitude": "-97.00000000",
//         "emoji": "🇺🇸",
//         "emojiU": "U+1F1FA U+1F1F8",
//         "states": [
//             {
//                 "id": 1456,
//                 "name": "Alabama",
//                 "state_code": "AL",
//                 "latitude": "32.31823140",
//                 "longitude": "-86.90229800",
//                 "type": "state"
//             },
//             {
//                 "id": 1400,
//                 "name": "Alaska",
//                 "state_code": "AK",
//                 "latitude": "64.20084130",
//                 "longitude": "-149.49367330",
//                 "type": "state"
//             },
//             {
//                 "id": 1424,
//                 "name": "American Samoa",
//                 "state_code": "AS",
//                 "latitude": "-14.27097200",
//                 "longitude": "-170.13221700",
//                 "type": "outlying area"
//             },
//             {
//                 "id": 1434,
//                 "name": "Arizona",
//                 "state_code": "AZ",
//                 "latitude": "34.04892810",
//                 "longitude": "-111.09373110",
//                 "type": "state"
//             },
//             {
//                 "id": 1444,
//                 "name": "Arkansas",
//                 "state_code": "AR",
//                 "latitude": "35.20105000",
//                 "longitude": "-91.83183340",
//                 "type": "state"
//             },
//             {
//                 "id": 1402,
//                 "name": "Baker Island",
//                 "state_code": "UM-81",
//                 "latitude": "0.19362660",
//                 "longitude": "-176.47690800",
//                 "type": "islands \/ groups of islands"
//             },
//             {
//                 "id": 1416,
//                 "name": "California",
//                 "state_code": "CA",
//                 "latitude": "36.77826100",
//                 "longitude": "-119.41793240",
//                 "type": "state"
//             },
//             {
//                 "id": 1450,
//                 "name": "Colorado",
//                 "state_code": "CO",
//                 "latitude": "39.55005070",
//                 "longitude": "-105.78206740",
//                 "type": "state"
//             },
//             {
//                 "id": 1435,
//                 "name": "Connecticut",
//                 "state_code": "CT",
//                 "latitude": "41.60322070",
//                 "longitude": "-73.08774900",
//                 "type": "state"
//             },
//             {
//                 "id": 1399,
//                 "name": "Delaware",
//                 "state_code": "DE",
//                 "latitude": "38.91083250",
//                 "longitude": "-75.52766990",
//                 "type": "state"
//             },
//             {
//                 "id": 1437,
//                 "name": "District of Columbia",
//                 "state_code": "DC",
//                 "latitude": "38.90719230",
//                 "longitude": "-77.03687070",
//                 "type": "district"
//             },
//             {
//                 "id": 1436,
//                 "name": "Florida",
//                 "state_code": "FL",
//                 "latitude": "27.66482740",
//                 "longitude": "-81.51575350",
//                 "type": "state"
//             },
//             {
//                 "id": 1455,
//                 "name": "Georgia",
//                 "state_code": "GA",
//                 "latitude": "32.16562210",
//                 "longitude": "-82.90007510",
//                 "type": "state"
//             },
//             {
//                 "id": 1412,
//                 "name": "Guam",
//                 "state_code": "GU",
//                 "latitude": "13.44430400",
//                 "longitude": "144.79373100",
//                 "type": "outlying area"
//             },
//             {
//                 "id": 1411,
//                 "name": "Hawaii",
//                 "state_code": "HI",
//                 "latitude": "19.89676620",
//                 "longitude": "-155.58278180",
//                 "type": "state"
//             },
//             {
//                 "id": 1398,
//                 "name": "Howland Island",
//                 "state_code": "UM-84",
//                 "latitude": "0.81132190",
//                 "longitude": "-176.61827360",
//                 "type": "islands \/ groups of islands"
//             },
//             {
//                 "id": 1460,
//                 "name": "Idaho",
//                 "state_code": "Id",
//                 "latitude": "44.06820190",
//                 "longitude": "-114.74204080",
//                 "type": "state"
//             },
//             {
//                 "id": 1425,
//                 "name": "Illinois",
//                 "state_code": "IL",
//                 "latitude": "40.63312490",
//                 "longitude": "-89.39852830",
//                 "type": "state"
//             },
//             {
//                 "id": 1440,
//                 "name": "Indiana",
//                 "state_code": "IN",
//                 "latitude": "40.26719410",
//                 "longitude": "-86.13490190",
//                 "type": "state"
//             },
//             {
//                 "id": 1459,
//                 "name": "Iowa",
//                 "state_code": "IA",
//                 "latitude": "41.87800250",
//                 "longitude": "-93.09770200",
//                 "type": "state"
//             },
//             {
//                 "id": 1410,
//                 "name": "Jarvis Island",
//                 "state_code": "UM-86",
//                 "latitude": "-0.37435030",
//                 "longitude": "-159.99672060",
//                 "type": "islands \/ groups of islands"
//             },
//             {
//                 "id": 1428,
//                 "name": "Johnston Atoll",
//                 "state_code": "UM-67",
//                 "latitude": "16.72950350",
//                 "longitude": "-169.53364770",
//                 "type": "islands \/ groups of islands"
//             },
//             {
//                 "id": 1406,
//                 "name": "Kansas",
//                 "state_code": "KS",
//                 "latitude": "39.01190200",
//                 "longitude": "-98.48424650",
//                 "type": "state"
//             },
//             {
//                 "id": 1419,
//                 "name": "Kentucky",
//                 "state_code": "KY",
//                 "latitude": "37.83933320",
//                 "longitude": "-84.27001790",
//                 "type": "state"
//             },
//             {
//                 "id": 1403,
//                 "name": "Kingman Reef",
//                 "state_code": "UM-89",
//                 "latitude": "6.38333300",
//                 "longitude": "-162.41666700",
//                 "type": "islands \/ groups of islands"
//             },
//             {
//                 "id": 1457,
//                 "name": "Louisiana",
//                 "state_code": "LA",
//                 "latitude": "30.98429770",
//                 "longitude": "-91.96233270",
//                 "type": "state"
//             },
//             {
//                 "id": 1453,
//                 "name": "Maine",
//                 "state_code": "ME",
//                 "latitude": "45.25378300",
//                 "longitude": "-69.44546890",
//                 "type": "state"
//             },
//             {
//                 "id": 1401,
//                 "name": "Maryland",
//                 "state_code": "MD",
//                 "latitude": "39.04575490",
//                 "longitude": "-76.64127120",
//                 "type": "state"
//             },
//             {
//                 "id": 1433,
//                 "name": "Massachusetts",
//                 "state_code": "MA",
//                 "latitude": "42.40721070",
//                 "longitude": "-71.38243740",
//                 "type": "state"
//             },
//             {
//                 "id": 1426,
//                 "name": "Michigan",
//                 "state_code": "MI",
//                 "latitude": "44.31484430",
//                 "longitude": "-85.60236430",
//                 "type": "state"
//             },
//             {
//                 "id": 1438,
//                 "name": "Midway Atoll",
//                 "state_code": "UM-71",
//                 "latitude": "28.20721680",
//                 "longitude": "-177.37349260",
//                 "type": "islands \/ groups of islands"
//             },
//             {
//                 "id": 1420,
//                 "name": "Minnesota",
//                 "state_code": "MN",
//                 "latitude": "46.72955300",
//                 "longitude": "-94.68589980",
//                 "type": "state"
//             },
//             {
//                 "id": 1430,
//                 "name": "Mississippi",
//                 "state_code": "MS",
//                 "latitude": "32.35466790",
//                 "longitude": "-89.39852830",
//                 "type": "state"
//             },
//             {
//                 "id": 1451,
//                 "name": "Missouri",
//                 "state_code": "MO",
//                 "latitude": "37.96425290",
//                 "longitude": "-91.83183340",
//                 "type": "state"
//             },
//             {
//                 "id": 1446,
//                 "name": "Montana",
//                 "state_code": "MT",
//                 "latitude": "46.87968220",
//                 "longitude": "-110.36256580",
//                 "type": "state"
//             },
//             {
//                 "id": 1439,
//                 "name": "Navassa Island",
//                 "state_code": "UM-76",
//                 "latitude": "18.41006890",
//                 "longitude": "-75.01146120",
//                 "type": "islands \/ groups of islands"
//             },
//             {
//                 "id": 1408,
//                 "name": "Nebraska",
//                 "state_code": "NE",
//                 "latitude": "41.49253740",
//                 "longitude": "-99.90181310",
//                 "type": "state"
//             },
//             {
//                 "id": 1458,
//                 "name": "Nevada",
//                 "state_code": "NV",
//                 "latitude": "38.80260970",
//                 "longitude": "-116.41938900",
//                 "type": "state"
//             },
//             {
//                 "id": 1404,
//                 "name": "New Hampshire",
//                 "state_code": "NH",
//                 "latitude": "43.19385160",
//                 "longitude": "-71.57239530",
//                 "type": "state"
//             },
//             {
//                 "id": 1417,
//                 "name": "New Jersey",
//                 "state_code": "NJ",
//                 "latitude": "40.05832380",
//                 "longitude": "-74.40566120",
//                 "type": "state"
//             },
//             {
//                 "id": 1423,
//                 "name": "New Mexico",
//                 "state_code": "NM",
//                 "latitude": "34.51994020",
//                 "longitude": "-105.87009010",
//                 "type": "state"
//             },
//             {
//                 "id": 1452,
//                 "name": "New York",
//                 "state_code": "NY",
//                 "latitude": "40.71277530",
//                 "longitude": "-74.00597280",
//                 "type": "state"
//             },
//             {
//                 "id": 1447,
//                 "name": "North Carolina",
//                 "state_code": "NC",
//                 "latitude": "35.75957310",
//                 "longitude": "-79.01929970",
//                 "type": "state"
//             },
//             {
//                 "id": 1418,
//                 "name": "North Dakota",
//                 "state_code": "ND",
//                 "latitude": "47.55149260",
//                 "longitude": "-101.00201190",
//                 "type": "state"
//             },
//             {
//                 "id": 1431,
//                 "name": "Northern Mariana Islands",
//                 "state_code": "MP",
//                 "latitude": "15.09790000",
//                 "longitude": "145.67390000",
//                 "type": "outlying area"
//             },
//             {
//                 "id": 4851,
//                 "name": "Ohio",
//                 "state_code": "OH",
//                 "latitude": "40.41728710",
//                 "longitude": "-82.90712300",
//                 "type": "state"
//             },
//             {
//                 "id": 1421,
//                 "name": "Oklahoma",
//                 "state_code": "OK",
//                 "latitude": "35.46756020",
//                 "longitude": "-97.51642760",
//                 "type": "state"
//             },
//             {
//                 "id": 1415,
//                 "name": "Oregon",
//                 "state_code": "OR",
//                 "latitude": "43.80413340",
//                 "longitude": "-120.55420120",
//                 "type": "state"
//             },
//             {
//                 "id": 1448,
//                 "name": "Palmyra Atoll",
//                 "state_code": "UM-95",
//                 "latitude": "5.88850260",
//                 "longitude": "-162.07866560",
//                 "type": "islands \/ groups of islands"
//             },
//             {
//                 "id": 1422,
//                 "name": "Pennsylvania",
//                 "state_code": "PA",
//                 "latitude": "41.20332160",
//                 "longitude": "-77.19452470",
//                 "type": "state"
//             },
//             {
//                 "id": 1449,
//                 "name": "Puerto Rico",
//                 "state_code": "PR",
//                 "latitude": "18.22083300",
//                 "longitude": "-66.59014900",
//                 "type": "outlying area"
//             },
//             {
//                 "id": 1461,
//                 "name": "Rhode Island",
//                 "state_code": "RI",
//                 "latitude": "41.58009450",
//                 "longitude": "-71.47742910",
//                 "type": "state"
//             },
//             {
//                 "id": 1443,
//                 "name": "South Carolina",
//                 "state_code": "SC",
//                 "latitude": "33.83608100",
//                 "longitude": "-81.16372450",
//                 "type": "state"
//             },
//             {
//                 "id": 1445,
//                 "name": "South Dakota",
//                 "state_code": "SD",
//                 "latitude": "43.96951480",
//                 "longitude": "-99.90181310",
//                 "type": "state"
//             },
//             {
//                 "id": 1454,
//                 "name": "Tennessee",
//                 "state_code": "TN",
//                 "latitude": "35.51749130",
//                 "longitude": "-86.58044730",
//                 "type": "state"
//             },
//             {
//                 "id": 1407,
//                 "name": "Texas",
//                 "state_code": "TX",
//                 "latitude": "31.96859880",
//                 "longitude": "-99.90181310",
//                 "type": "state"
//             },
//             {
//                 "id": 1432,
//                 "name": "United States Minor Outlying Islands",
//                 "state_code": "UM",
//                 "latitude": "19.28231920",
//                 "longitude": "166.64704700",
//                 "type": "outlying area"
//             },
//             {
//                 "id": 1413,
//                 "name": "United States Virgin Islands",
//                 "state_code": "VI",
//                 "latitude": "18.33576500",
//                 "longitude": "-64.89633500",
//                 "type": "outlying area"
//             },
//             {
//                 "id": 1414,
//                 "name": "Utah",
//                 "state_code": "UT",
//                 "latitude": "39.32098010",
//                 "longitude": "-111.09373110",
//                 "type": "state"
//             },
//             {
//                 "id": 1409,
//                 "name": "Vermont",
//                 "state_code": "VT",
//                 "latitude": "44.55880280",
//                 "longitude": "-72.57784150",
//                 "type": "state"
//             },
//             {
//                 "id": 1427,
//                 "name": "Virginia",
//                 "state_code": "VA",
//                 "latitude": "37.43157340",
//                 "longitude": "-78.65689420",
//                 "type": "state"
//             },
//             {
//                 "id": 1405,
//                 "name": "Wake Island",
//                 "state_code": "UM-79",
//                 "latitude": "19.27961900",
//                 "longitude": "166.64993480",
//                 "type": "islands \/ groups of islands"
//             },
//             {
//                 "id": 1462,
//                 "name": "Washington",
//                 "state_code": "WA",
//                 "latitude": "47.75107410",
//                 "longitude": "-120.74013850",
//                 "type": "state"
//             },
//             {
//                 "id": 1429,
//                 "name": "West Virginia",
//                 "state_code": "WV",
//                 "latitude": "38.59762620",
//                 "longitude": "-80.45490260",
//                 "type": "state"
//             },
//             {
//                 "id": 1441,
//                 "name": "Wisconsin",
//                 "state_code": "WI",
//                 "latitude": "43.78443970",
//                 "longitude": "-88.78786780",
//                 "type": "state"
//             },
//             {
//                 "id": 1442,
//                 "name": "Wyoming",
//                 "state_code": "WY",
//                 "latitude": "43.07596780",
//                 "longitude": "-107.29028390",
//                 "type": "state"
//             }
//         ]
//     }
// ];