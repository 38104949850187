import { CountryStateService } from './modules/app-shared/services/country-state.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor(stateService: CountryStateService) {
    // injecting stateService to force it to load
  }
}
