/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getScoringSystems } from '../fn/scoring-system/get-scoring-systems';
import { GetScoringSystems$Params } from '../fn/scoring-system/get-scoring-systems';
import { getScoringSystems$Plain } from '../fn/scoring-system/get-scoring-systems-plain';
import { GetScoringSystems$Plain$Params } from '../fn/scoring-system/get-scoring-systems-plain';
import { ScoringSystemDto } from '../models/scoring-system-dto';

@Injectable({ providedIn: 'root' })
export class ScoringSystemServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getScoringSystems()` */
  static readonly GetScoringSystemsPath = '/scoring-system';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScoringSystems$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoringSystems$Plain$Response(params?: GetScoringSystems$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ScoringSystemDto>>> {
    return getScoringSystems$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScoringSystems$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoringSystems$Plain(params?: GetScoringSystems$Plain$Params, context?: HttpContext): Observable<Array<ScoringSystemDto>> {
    return this.getScoringSystems$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ScoringSystemDto>>): Array<ScoringSystemDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScoringSystems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoringSystems$Response(params?: GetScoringSystems$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ScoringSystemDto>>> {
    return getScoringSystems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScoringSystems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoringSystems(params?: GetScoringSystems$Params, context?: HttpContext): Observable<Array<ScoringSystemDto>> {
    return this.getScoringSystems$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ScoringSystemDto>>): Array<ScoringSystemDto> => r.body)
    );
  }

}
