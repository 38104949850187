/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createGameType } from '../fn/game-type/create-game-type';
import { CreateGameType$Params } from '../fn/game-type/create-game-type';
import { createGameType$Plain } from '../fn/game-type/create-game-type-plain';
import { CreateGameType$Plain$Params } from '../fn/game-type/create-game-type-plain';
import { GameTypeDto } from '../models/game-type-dto';
import { getGameTypes } from '../fn/game-type/get-game-types';
import { GetGameTypes$Params } from '../fn/game-type/get-game-types';
import { getGameTypes$Plain } from '../fn/game-type/get-game-types-plain';
import { GetGameTypes$Plain$Params } from '../fn/game-type/get-game-types-plain';
import { updateGameType } from '../fn/game-type/update-game-type';
import { UpdateGameType$Params } from '../fn/game-type/update-game-type';
import { updateGameType$Plain } from '../fn/game-type/update-game-type-plain';
import { UpdateGameType$Plain$Params } from '../fn/game-type/update-game-type-plain';

@Injectable({ providedIn: 'root' })
export class GameTypeServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getGameTypes()` */
  static readonly GetGameTypesPath = '/gametype';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGameTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGameTypes$Plain$Response(params?: GetGameTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GameTypeDto>>> {
    return getGameTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGameTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGameTypes$Plain(params?: GetGameTypes$Plain$Params, context?: HttpContext): Observable<Array<GameTypeDto>> {
    return this.getGameTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GameTypeDto>>): Array<GameTypeDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGameTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGameTypes$Response(params?: GetGameTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GameTypeDto>>> {
    return getGameTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGameTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGameTypes(params?: GetGameTypes$Params, context?: HttpContext): Observable<Array<GameTypeDto>> {
    return this.getGameTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GameTypeDto>>): Array<GameTypeDto> => r.body)
    );
  }

  /** Path part for operation `createGameType()` */
  static readonly CreateGameTypePath = '/gametype';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createGameType$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createGameType$Plain$Response(params?: CreateGameType$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GameTypeDto>> {
    return createGameType$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createGameType$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createGameType$Plain(params?: CreateGameType$Plain$Params, context?: HttpContext): Observable<GameTypeDto> {
    return this.createGameType$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GameTypeDto>): GameTypeDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createGameType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createGameType$Response(params?: CreateGameType$Params, context?: HttpContext): Observable<StrictHttpResponse<GameTypeDto>> {
    return createGameType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createGameType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createGameType(params?: CreateGameType$Params, context?: HttpContext): Observable<GameTypeDto> {
    return this.createGameType$Response(params, context).pipe(
      map((r: StrictHttpResponse<GameTypeDto>): GameTypeDto => r.body)
    );
  }

  /** Path part for operation `updateGameType()` */
  static readonly UpdateGameTypePath = '/gametype/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGameType$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateGameType$Plain$Response(params: UpdateGameType$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GameTypeDto>> {
    return updateGameType$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGameType$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateGameType$Plain(params: UpdateGameType$Plain$Params, context?: HttpContext): Observable<GameTypeDto> {
    return this.updateGameType$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GameTypeDto>): GameTypeDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGameType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateGameType$Response(params: UpdateGameType$Params, context?: HttpContext): Observable<StrictHttpResponse<GameTypeDto>> {
    return updateGameType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGameType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateGameType(params: UpdateGameType$Params, context?: HttpContext): Observable<GameTypeDto> {
    return this.updateGameType$Response(params, context).pipe(
      map((r: StrictHttpResponse<GameTypeDto>): GameTypeDto => r.body)
    );
  }

}
