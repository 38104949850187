/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FairplayScoreItemDto } from '../../models/fairplay-score-item-dto';

export interface UpdateFairplayScore$Params {
  scoreId: string;
  id: string;
      body?: FairplayScoreItemDto
}

export function updateFairplayScore(http: HttpClient, rootUrl: string, params: UpdateFairplayScore$Params, context?: HttpContext): Observable<StrictHttpResponse<FairplayScoreItemDto>> {
  const rb = new RequestBuilder(rootUrl, updateFairplayScore.PATH, 'put');
  if (params) {
    rb.path('scoreId', params.scoreId, {});
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FairplayScoreItemDto>;
    })
  );
}

updateFairplayScore.PATH = '/tournament/{id}/fairplay/{scoreId}';
