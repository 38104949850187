/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLeagueInvoice } from '../fn/finance/get-league-invoice';
import { GetLeagueInvoice$Params } from '../fn/finance/get-league-invoice';
import { getLeagueInvoice$Plain } from '../fn/finance/get-league-invoice-plain';
import { GetLeagueInvoice$Plain$Params } from '../fn/finance/get-league-invoice-plain';
import { getSeasonInvoice } from '../fn/finance/get-season-invoice';
import { GetSeasonInvoice$Params } from '../fn/finance/get-season-invoice';
import { getSeasonInvoice$Plain } from '../fn/finance/get-season-invoice-plain';
import { GetSeasonInvoice$Plain$Params } from '../fn/finance/get-season-invoice-plain';
import { getTournamentInvoice } from '../fn/finance/get-tournament-invoice';
import { GetTournamentInvoice$Params } from '../fn/finance/get-tournament-invoice';
import { getTournamentInvoice$Plain } from '../fn/finance/get-tournament-invoice-plain';
import { GetTournamentInvoice$Plain$Params } from '../fn/finance/get-tournament-invoice-plain';
import { InvoiceDto } from '../models/invoice-dto';

@Injectable({ providedIn: 'root' })
export class FinanceServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTournamentInvoice()` */
  static readonly GetTournamentInvoicePath = '/finance/invoice/tournament';

  /**
   * Returns the invoice for a tournament, or creates one if required (eg. first time or additional players added)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentInvoice$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentInvoice$Plain$Response(params?: GetTournamentInvoice$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InvoiceDto>> {
    return getTournamentInvoice$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the invoice for a tournament, or creates one if required (eg. first time or additional players added)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentInvoice$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentInvoice$Plain(params?: GetTournamentInvoice$Plain$Params, context?: HttpContext): Observable<InvoiceDto> {
    return this.getTournamentInvoice$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceDto>): InvoiceDto => r.body)
    );
  }

  /**
   * Returns the invoice for a tournament, or creates one if required (eg. first time or additional players added)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentInvoice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentInvoice$Response(params?: GetTournamentInvoice$Params, context?: HttpContext): Observable<StrictHttpResponse<InvoiceDto>> {
    return getTournamentInvoice(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the invoice for a tournament, or creates one if required (eg. first time or additional players added)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentInvoice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentInvoice(params?: GetTournamentInvoice$Params, context?: HttpContext): Observable<InvoiceDto> {
    return this.getTournamentInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceDto>): InvoiceDto => r.body)
    );
  }

  /** Path part for operation `getLeagueInvoice()` */
  static readonly GetLeagueInvoicePath = '/finance/invoice/league';

  /**
   * Returns the invoice for a limited series league, or creates one if required
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeagueInvoice$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueInvoice$Plain$Response(params?: GetLeagueInvoice$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InvoiceDto>> {
    return getLeagueInvoice$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the invoice for a limited series league, or creates one if required
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeagueInvoice$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueInvoice$Plain(params?: GetLeagueInvoice$Plain$Params, context?: HttpContext): Observable<InvoiceDto> {
    return this.getLeagueInvoice$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceDto>): InvoiceDto => r.body)
    );
  }

  /**
   * Returns the invoice for a limited series league, or creates one if required
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeagueInvoice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueInvoice$Response(params?: GetLeagueInvoice$Params, context?: HttpContext): Observable<StrictHttpResponse<InvoiceDto>> {
    return getLeagueInvoice(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the invoice for a limited series league, or creates one if required
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeagueInvoice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeagueInvoice(params?: GetLeagueInvoice$Params, context?: HttpContext): Observable<InvoiceDto> {
    return this.getLeagueInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceDto>): InvoiceDto => r.body)
    );
  }

  /** Path part for operation `getSeasonInvoice()` */
  static readonly GetSeasonInvoicePath = '/finance/invoice/season';

  /**
   * Returns the invoice for an open league season, or creates one if required
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeasonInvoice$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeasonInvoice$Plain$Response(params?: GetSeasonInvoice$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InvoiceDto>> {
    return getSeasonInvoice$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the invoice for an open league season, or creates one if required
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeasonInvoice$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeasonInvoice$Plain(params?: GetSeasonInvoice$Plain$Params, context?: HttpContext): Observable<InvoiceDto> {
    return this.getSeasonInvoice$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceDto>): InvoiceDto => r.body)
    );
  }

  /**
   * Returns the invoice for an open league season, or creates one if required
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeasonInvoice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeasonInvoice$Response(params?: GetSeasonInvoice$Params, context?: HttpContext): Observable<StrictHttpResponse<InvoiceDto>> {
    return getSeasonInvoice(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the invoice for an open league season, or creates one if required
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeasonInvoice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeasonInvoice(params?: GetSeasonInvoice$Params, context?: HttpContext): Observable<InvoiceDto> {
    return this.getSeasonInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceDto>): InvoiceDto => r.body)
    );
  }

}
