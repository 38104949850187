/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addArmy } from '../fn/army/add-army';
import { AddArmy$Params } from '../fn/army/add-army';
import { ArmyDto } from '../models/army-dto';
import { getAllArmies } from '../fn/army/get-all-armies';
import { GetAllArmies$Params } from '../fn/army/get-all-armies';
import { getAllArmies$Plain } from '../fn/army/get-all-armies-plain';
import { GetAllArmies$Plain$Params } from '../fn/army/get-all-armies-plain';
import { updateArmy } from '../fn/army/update-army';
import { UpdateArmy$Params } from '../fn/army/update-army';

@Injectable({ providedIn: 'root' })
export class ArmyServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllArmies()` */
  static readonly GetAllArmiesPath = '/army';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllArmies$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArmies$Plain$Response(params?: GetAllArmies$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArmyDto>>> {
    return getAllArmies$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllArmies$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArmies$Plain(params?: GetAllArmies$Plain$Params, context?: HttpContext): Observable<Array<ArmyDto>> {
    return this.getAllArmies$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArmyDto>>): Array<ArmyDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllArmies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArmies$Response(params?: GetAllArmies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArmyDto>>> {
    return getAllArmies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllArmies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArmies(params?: GetAllArmies$Params, context?: HttpContext): Observable<Array<ArmyDto>> {
    return this.getAllArmies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArmyDto>>): Array<ArmyDto> => r.body)
    );
  }

  /** Path part for operation `addArmy()` */
  static readonly AddArmyPath = '/army';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addArmy()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addArmy$Response(params?: AddArmy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addArmy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addArmy$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addArmy(params?: AddArmy$Params, context?: HttpContext): Observable<void> {
    return this.addArmy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateArmy()` */
  static readonly UpdateArmyPath = '/army/{armyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArmy()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArmy$Response(params: UpdateArmy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateArmy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateArmy$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArmy(params: UpdateArmy$Params, context?: HttpContext): Observable<void> {
    return this.updateArmy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
