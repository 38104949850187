import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/core/helpers/auth.guard';

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule) },
  { path: 'tournament', canActivate: [AuthGuard], loadChildren: () => import('./modules/tournament/tournament.module').then(m => m.TournamentModule) },
  { path: 'home', canActivate: [AuthGuard], loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'admin', canActivate: [AuthGuard], data: { roles: 'Administrator' }, loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
  { path: 'player', canActivate: [AuthGuard], loadChildren: () => import('./modules/player/player.module').then(m => m.PlayerModule) },
  { path: 'organiser', canActivate: [AuthGuard], loadChildren: () => import('./modules/organiser/organiser.module').then(m => m.OrganiserModule) },
  { path: 'league', canActivate: [AuthGuard], loadChildren: () => import('./modules/leagues/leagues.module').then(m => m.LeaguesModule) },
  { path: 'finance', canActivate: [AuthGuard], loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

