/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createExternalTournament } from '../fn/external-tournament/create-external-tournament';
import { CreateExternalTournament$Params } from '../fn/external-tournament/create-external-tournament';
import { createExternalTournament$Plain } from '../fn/external-tournament/create-external-tournament-plain';
import { CreateExternalTournament$Plain$Params } from '../fn/external-tournament/create-external-tournament-plain';
import { CreateExternalTournamentResponseDto } from '../models/create-external-tournament-response-dto';
import { getExternalTournament } from '../fn/external-tournament/get-external-tournament';
import { GetExternalTournament$Params } from '../fn/external-tournament/get-external-tournament';
import { getExternalTournament$Plain } from '../fn/external-tournament/get-external-tournament-plain';
import { GetExternalTournament$Plain$Params } from '../fn/external-tournament/get-external-tournament-plain';
import { ImportProcessingDto } from '../models/import-processing-dto';
import { LeagueTournamentDto } from '../models/league-tournament-dto';
import { processExternalTournamentResults } from '../fn/external-tournament/process-external-tournament-results';
import { ProcessExternalTournamentResults$Params } from '../fn/external-tournament/process-external-tournament-results';
import { processExternalTournamentResults$Plain } from '../fn/external-tournament/process-external-tournament-results-plain';
import { ProcessExternalTournamentResults$Plain$Params } from '../fn/external-tournament/process-external-tournament-results-plain';
import { saveExternalTournamentDetails } from '../fn/external-tournament/save-external-tournament-details';
import { SaveExternalTournamentDetails$Params } from '../fn/external-tournament/save-external-tournament-details';
import { saveExternalTournamentDetails$Plain } from '../fn/external-tournament/save-external-tournament-details-plain';
import { SaveExternalTournamentDetails$Plain$Params } from '../fn/external-tournament/save-external-tournament-details-plain';

@Injectable({ providedIn: 'root' })
export class ExternalTournamentServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getExternalTournament()` */
  static readonly GetExternalTournamentPath = '/external-tournament/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalTournament$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalTournament$Plain$Response(params: GetExternalTournament$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueTournamentDto>> {
    return getExternalTournament$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExternalTournament$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalTournament$Plain(params: GetExternalTournament$Plain$Params, context?: HttpContext): Observable<LeagueTournamentDto> {
    return this.getExternalTournament$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueTournamentDto>): LeagueTournamentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalTournament()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalTournament$Response(params: GetExternalTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueTournamentDto>> {
    return getExternalTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExternalTournament$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalTournament(params: GetExternalTournament$Params, context?: HttpContext): Observable<LeagueTournamentDto> {
    return this.getExternalTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueTournamentDto>): LeagueTournamentDto => r.body)
    );
  }

  /** Path part for operation `saveExternalTournamentDetails()` */
  static readonly SaveExternalTournamentDetailsPath = '/external-tournament/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveExternalTournamentDetails$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveExternalTournamentDetails$Plain$Response(params: SaveExternalTournamentDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueTournamentDto>> {
    return saveExternalTournamentDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveExternalTournamentDetails$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveExternalTournamentDetails$Plain(params: SaveExternalTournamentDetails$Plain$Params, context?: HttpContext): Observable<LeagueTournamentDto> {
    return this.saveExternalTournamentDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueTournamentDto>): LeagueTournamentDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveExternalTournamentDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveExternalTournamentDetails$Response(params: SaveExternalTournamentDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<LeagueTournamentDto>> {
    return saveExternalTournamentDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveExternalTournamentDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveExternalTournamentDetails(params: SaveExternalTournamentDetails$Params, context?: HttpContext): Observable<LeagueTournamentDto> {
    return this.saveExternalTournamentDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeagueTournamentDto>): LeagueTournamentDto => r.body)
    );
  }

  /** Path part for operation `createExternalTournament()` */
  static readonly CreateExternalTournamentPath = '/external-tournament';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExternalTournament$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExternalTournament$Plain$Response(params?: CreateExternalTournament$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateExternalTournamentResponseDto>> {
    return createExternalTournament$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExternalTournament$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExternalTournament$Plain(params?: CreateExternalTournament$Plain$Params, context?: HttpContext): Observable<CreateExternalTournamentResponseDto> {
    return this.createExternalTournament$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateExternalTournamentResponseDto>): CreateExternalTournamentResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExternalTournament()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExternalTournament$Response(params?: CreateExternalTournament$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateExternalTournamentResponseDto>> {
    return createExternalTournament(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExternalTournament$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExternalTournament(params?: CreateExternalTournament$Params, context?: HttpContext): Observable<CreateExternalTournamentResponseDto> {
    return this.createExternalTournament$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateExternalTournamentResponseDto>): CreateExternalTournamentResponseDto => r.body)
    );
  }

  /** Path part for operation `processExternalTournamentResults()` */
  static readonly ProcessExternalTournamentResultsPath = '/external-tournament/process/{leagueId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processExternalTournamentResults$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processExternalTournamentResults$Plain$Response(params: ProcessExternalTournamentResults$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ImportProcessingDto>>> {
    return processExternalTournamentResults$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processExternalTournamentResults$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processExternalTournamentResults$Plain(params: ProcessExternalTournamentResults$Plain$Params, context?: HttpContext): Observable<Array<ImportProcessingDto>> {
    return this.processExternalTournamentResults$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ImportProcessingDto>>): Array<ImportProcessingDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processExternalTournamentResults()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processExternalTournamentResults$Response(params: ProcessExternalTournamentResults$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ImportProcessingDto>>> {
    return processExternalTournamentResults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processExternalTournamentResults$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processExternalTournamentResults(params: ProcessExternalTournamentResults$Params, context?: HttpContext): Observable<Array<ImportProcessingDto>> {
    return this.processExternalTournamentResults$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ImportProcessingDto>>): Array<ImportProcessingDto> => r.body)
    );
  }

}
