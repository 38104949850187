/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addToAsPlayer } from '../fn/tournament-organisers/add-to-as-player';
import { AddToAsPlayer$Params } from '../fn/tournament-organisers/add-to-as-player';
import { getTournamentOrganisers } from '../fn/tournament-organisers/get-tournament-organisers';
import { GetTournamentOrganisers$Params } from '../fn/tournament-organisers/get-tournament-organisers';
import { getTournamentOrganisers$Plain } from '../fn/tournament-organisers/get-tournament-organisers-plain';
import { GetTournamentOrganisers$Plain$Params } from '../fn/tournament-organisers/get-tournament-organisers-plain';
import { inviteTo } from '../fn/tournament-organisers/invite-to';
import { InviteTo$Params } from '../fn/tournament-organisers/invite-to';
import { inviteTo$Plain } from '../fn/tournament-organisers/invite-to-plain';
import { InviteTo$Plain$Params } from '../fn/tournament-organisers/invite-to-plain';
import { removeTo } from '../fn/tournament-organisers/remove-to';
import { RemoveTo$Params } from '../fn/tournament-organisers/remove-to';
import { ToListItemDto } from '../models/to-list-item-dto';

@Injectable({ providedIn: 'root' })
export class TournamentOrganisersServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTournamentOrganisers()` */
  static readonly GetTournamentOrganisersPath = '/tourament-organisers/{tournamentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentOrganisers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentOrganisers$Plain$Response(params: GetTournamentOrganisers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ToListItemDto>>> {
    return getTournamentOrganisers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentOrganisers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentOrganisers$Plain(params: GetTournamentOrganisers$Plain$Params, context?: HttpContext): Observable<Array<ToListItemDto>> {
    return this.getTournamentOrganisers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ToListItemDto>>): Array<ToListItemDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentOrganisers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentOrganisers$Response(params: GetTournamentOrganisers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ToListItemDto>>> {
    return getTournamentOrganisers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentOrganisers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentOrganisers(params: GetTournamentOrganisers$Params, context?: HttpContext): Observable<Array<ToListItemDto>> {
    return this.getTournamentOrganisers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ToListItemDto>>): Array<ToListItemDto> => r.body)
    );
  }

  /** Path part for operation `inviteTo()` */
  static readonly InviteToPath = '/tourament-organisers/invite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteTo$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inviteTo$Plain$Response(params?: InviteTo$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return inviteTo$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inviteTo$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inviteTo$Plain(params?: InviteTo$Plain$Params, context?: HttpContext): Observable<string> {
    return this.inviteTo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteTo()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inviteTo$Response(params?: InviteTo$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return inviteTo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inviteTo$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inviteTo(params?: InviteTo$Params, context?: HttpContext): Observable<string> {
    return this.inviteTo$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `removeTo()` */
  static readonly RemoveToPath = '/tourament-organisers/{tournamentOrganiserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTo$Response(params: RemoveTo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeTo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTo(params: RemoveTo$Params, context?: HttpContext): Observable<void> {
    return this.removeTo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addToAsPlayer()` */
  static readonly AddToAsPlayerPath = '/tourament-organisers/add-as-player';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addToAsPlayer()` instead.
   *
   * This method doesn't expect any request body.
   */
  addToAsPlayer$Response(params?: AddToAsPlayer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addToAsPlayer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addToAsPlayer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addToAsPlayer(params?: AddToAsPlayer$Params, context?: HttpContext): Observable<void> {
    return this.addToAsPlayer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
