/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';
import { AcceptLeagueEntryDto } from '../../models/accept-league-entry-dto';

export interface AddTournamentToLeague$Params {
  leagueId: string;
  tournamentId: string;
}

export function addTournamentToLeague(http: HttpClient, rootUrl: string, params: AddTournamentToLeague$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, addTournamentToLeague.PATH, 'post');
  if (params) {
    rb.path('leagueId', params.leagueId, {});
    //rb.body(params.body, 'application/*+json');
    rb.query('tournamentId', params.tournamentId, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
    })
  );
}

addTournamentToLeague.PATH = '/league/{leagueId}/entry/addTournament';
