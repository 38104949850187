/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addGrudgeMatch } from '../fn/round/add-grudge-match';
import { AddGrudgeMatch$Params } from '../fn/round/add-grudge-match';
import { generateDummyResults } from '../fn/round/generate-dummy-results';
import { GenerateDummyResults$Params } from '../fn/round/generate-dummy-results';
import { generateRoundPairings } from '../fn/round/generate-round-pairings';
import { GenerateRoundPairings$Params } from '../fn/round/generate-round-pairings';
import { getTournamentRoundInfo } from '../fn/round/get-tournament-round-info';
import { GetTournamentRoundInfo$Params } from '../fn/round/get-tournament-round-info';
import { getTournamentRoundInfo$Plain } from '../fn/round/get-tournament-round-info-plain';
import { GetTournamentRoundInfo$Plain$Params } from '../fn/round/get-tournament-round-info-plain';
import { resetRoundPairings } from '../fn/round/reset-round-pairings';
import { ResetRoundPairings$Params } from '../fn/round/reset-round-pairings';
import { setRoundComplete } from '../fn/round/set-round-complete';
import { SetRoundComplete$Params } from '../fn/round/set-round-complete';
import { TournamentRoundsInfoDto } from '../models/tournament-rounds-info-dto';

@Injectable({ providedIn: 'root' })
export class RoundServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTournamentRoundInfo()` */
  static readonly GetTournamentRoundInfoPath = '/tournament/{tournamentId}/round';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentRoundInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentRoundInfo$Plain$Response(params: GetTournamentRoundInfo$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentRoundsInfoDto>> {
    return getTournamentRoundInfo$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentRoundInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentRoundInfo$Plain(params: GetTournamentRoundInfo$Plain$Params, context?: HttpContext): Observable<TournamentRoundsInfoDto> {
    return this.getTournamentRoundInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentRoundsInfoDto>): TournamentRoundsInfoDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTournamentRoundInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentRoundInfo$Response(params: GetTournamentRoundInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<TournamentRoundsInfoDto>> {
    return getTournamentRoundInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTournamentRoundInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTournamentRoundInfo(params: GetTournamentRoundInfo$Params, context?: HttpContext): Observable<TournamentRoundsInfoDto> {
    return this.getTournamentRoundInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<TournamentRoundsInfoDto>): TournamentRoundsInfoDto => r.body)
    );
  }

  /** Path part for operation `generateRoundPairings()` */
  static readonly GenerateRoundPairingsPath = '/tournament/{tournamentId}/round/{roundNumber}/generate-pairings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateRoundPairings()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateRoundPairings$Response(params: GenerateRoundPairings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return generateRoundPairings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateRoundPairings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateRoundPairings(params: GenerateRoundPairings$Params, context?: HttpContext): Observable<void> {
    return this.generateRoundPairings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resetRoundPairings()` */
  static readonly ResetRoundPairingsPath = '/tournament/{tournamentId}/round/{roundNumber}/reset-pairings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetRoundPairings()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetRoundPairings$Response(params: ResetRoundPairings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetRoundPairings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetRoundPairings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetRoundPairings(params: ResetRoundPairings$Params, context?: HttpContext): Observable<void> {
    return this.resetRoundPairings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `setRoundComplete()` */
  static readonly SetRoundCompletePath = '/tournament/{tournamentId}/round/{roundNumber}/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setRoundComplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  setRoundComplete$Response(params: SetRoundComplete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setRoundComplete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setRoundComplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setRoundComplete(params: SetRoundComplete$Params, context?: HttpContext): Observable<void> {
    return this.setRoundComplete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addGrudgeMatch()` */
  static readonly AddGrudgeMatchPath = '/tournament/{tournamentId}/round/{roundNumber}/add-grudge-match';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addGrudgeMatch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addGrudgeMatch$Response(params: AddGrudgeMatch$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addGrudgeMatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addGrudgeMatch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addGrudgeMatch(params: AddGrudgeMatch$Params, context?: HttpContext): Observable<void> {
    return this.addGrudgeMatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `generateDummyResults()` */
  static readonly GenerateDummyResultsPath = '/tournament/{tournamentId}/round/{roundNumber}/auto-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateDummyResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateDummyResults$Response(params: GenerateDummyResults$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return generateDummyResults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateDummyResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateDummyResults(params: GenerateDummyResults$Params, context?: HttpContext): Observable<void> {
    return this.generateDummyResults$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
