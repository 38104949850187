/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLadder } from '../fn/ladder/get-ladder';
import { GetLadder$Params } from '../fn/ladder/get-ladder';
import { getLadder$Plain } from '../fn/ladder/get-ladder-plain';
import { GetLadder$Plain$Params } from '../fn/ladder/get-ladder-plain';
import { getScoreBreakdown } from '../fn/ladder/get-score-breakdown';
import { GetScoreBreakdown$Params } from '../fn/ladder/get-score-breakdown';
import { getScoreBreakdown$Plain } from '../fn/ladder/get-score-breakdown-plain';
import { GetScoreBreakdown$Plain$Params } from '../fn/ladder/get-score-breakdown-plain';
import { LadderViewDto } from '../models/ladder-view-dto';
import { regenerateLadder } from '../fn/ladder/regenerate-ladder';
import { RegenerateLadder$Params } from '../fn/ladder/regenerate-ladder';
import { ScoreBreakdownDto } from '../models/score-breakdown-dto';

@Injectable({ providedIn: 'root' })
export class LadderServiceAPI extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getLadder()` */
  static readonly GetLadderPath = '/tournament/{tournamentId}/ladder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLadder$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLadder$Plain$Response(params: GetLadder$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LadderViewDto>> {
    return getLadder$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLadder$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLadder$Plain(params: GetLadder$Plain$Params, context?: HttpContext): Observable<LadderViewDto> {
    return this.getLadder$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LadderViewDto>): LadderViewDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLadder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLadder$Response(params: GetLadder$Params, context?: HttpContext): Observable<StrictHttpResponse<LadderViewDto>> {
    return getLadder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLadder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLadder(params: GetLadder$Params, context?: HttpContext): Observable<LadderViewDto> {
    return this.getLadder$Response(params, context).pipe(
      map((r: StrictHttpResponse<LadderViewDto>): LadderViewDto => r.body)
    );
  }

  /** Path part for operation `regenerateLadder()` */
  static readonly RegenerateLadderPath = '/tournament/{tournamentId}/ladder/regenerate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `regenerateLadder()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateLadder$Response(params: RegenerateLadder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return regenerateLadder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `regenerateLadder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateLadder(params: RegenerateLadder$Params, context?: HttpContext): Observable<void> {
    return this.regenerateLadder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getScoreBreakdown()` */
  static readonly GetScoreBreakdownPath = '/tournament/{tournamentId}/ladder/{registrationId}/scores';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScoreBreakdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoreBreakdown$Plain$Response(params: GetScoreBreakdown$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ScoreBreakdownDto>> {
    return getScoreBreakdown$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScoreBreakdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoreBreakdown$Plain(params: GetScoreBreakdown$Plain$Params, context?: HttpContext): Observable<ScoreBreakdownDto> {
    return this.getScoreBreakdown$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScoreBreakdownDto>): ScoreBreakdownDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScoreBreakdown()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoreBreakdown$Response(params: GetScoreBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<ScoreBreakdownDto>> {
    return getScoreBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScoreBreakdown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoreBreakdown(params: GetScoreBreakdown$Params, context?: HttpContext): Observable<ScoreBreakdownDto> {
    return this.getScoreBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScoreBreakdownDto>): ScoreBreakdownDto => r.body)
    );
  }

}
